import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import GlobalContext from 'src/context/GlobalContext';
import InveesContext from 'src/context/InveesContext';
import UserControl from 'src/components/controls/userControl/UserControl';
import Layout from 'src/components/layout/Layout';
import TitleBar from 'src/components/layout/TitleBar';
import Footer from 'src/components/layout/Footer';
import Content from 'src/components/layout/Content';
import InveeBox from 'src/components/controls/invee/InveeBox';
import NewInveeBox from 'src/components/controls/invee/NewInveeBox';
import { getInvees, deleteInvee } from 'src/api/invee';
import { notification, Space } from 'antd';
import LsDb from 'src/misc/LsDb';
import { MAX_INVEE_PROJECTS } from 'src/misc/Config';
import { notEmptyArray } from 'src/misc/Misc';
import DarkModeControl from 'src/components/controls/darkModeControl/DarkModeControl';
// import BetaVersion from 'src/components/controls/betaVersion/BetaVersion';
import * as Ga4 from 'src/misc/Ga4';
import FreeTokensBanner from 'src/components/controls/freeTokensBanner/freeTokensBanner';

const InveeList = () => {
  const breadcrumbRoutes = [
    {
      title: 'Moje projekty',
    },
  ];

  const globalContext = useContext(GlobalContext);
  const inveesContext = useContext(InveesContext);
  const navigate = useNavigate();

  useEffect(() => {
    Ga4.pageView('Project List');
    if (!inveesContext.inveeList) loadInveeItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadInveeItems = async () => {
    await globalContext.executeBlockingProcess(async () => {
      try {
        const resp = await getInvees();
        await inveesContext.setInveeList(resp);
      } catch (error) {
        console.error(error);
      }
    });
  };

  const getInveeItems = () => {
    if (!inveesContext.inveeList) return [];
    const getColumn = (item, key) => <div key={key}>{item}</div>;
    const result = [];
    result.push(inveesContext.inveeList.map((x, i) => getColumn(<InveeBox invee={x} onCreateClick={handleOpenCreate} onInviteClick={handleOpenInvite} onDelete={handleDelete} expiredAt={x.isExpired && x.expiryDate} />, i)));
    if (inveesContext.inveeList?.length < MAX_INVEE_PROJECTS) result.unshift(getColumn(<NewInveeBox onCreate={handleCreate} showAdditionalInfo={inveesContext?.inveeList?.length === 0} />, 'new'));
    return result;
  };

  const handleCreate = async () => {
    navigate(`/projects/create`);
  };

  const handleOpenCreate = (inveeId) => {
    if (inveesContext.currentInvee?.inveeId !== inveeId) inveesContext.setCurrentInvee(null);
    navigate(`/projects/${inveeId}/create`);
  };

  const handleOpenInvite = (inveeId) => {
    if (inveesContext.currentInvee?.inveeId !== inveeId) inveesContext.setCurrentInvee(null);
    navigate(`/projects/${inveeId}/invite`);
  };

  const handleDelete = async (inveeId) => {
    await globalContext.executeBlockingProcess(async () => {
      try {
        await deleteInvee(inveeId);
        await inveesContext.removeInvee(inveeId);
        LsDb.dropInveeStringItems(inveeId);
        notification.success({
          message: 'Usunięto projekt.',
          duration: 5,
        });
      } catch (error) {
        console.error(error);
      }
    });
  };

  return (
    <>
      {inveesContext.inveeList && <FreeTokensBanner />}
      <Layout
        header={
          <TitleBar
            path='/'
            isLoading={globalContext.isBusy}
            rightExtras={
              <Space>
                <DarkModeControl />
                <UserControl />
              </Space>
            }
          />
        }
        footer={<Footer />}
      >
        <Content
          breadcrumbRoutes={breadcrumbRoutes}
          title={
            inveesContext.inveeList ? (
              <>
                Lista
                <span className='font-light ml-2 text-base'>
                  ({true === notEmptyArray(inveesContext.inveeList) ? inveesContext.inveeList.length : 0}/{MAX_INVEE_PROJECTS})
                </span>
              </>
            ) : null
          }
        >
          <div className='mb-8'>{/* <BetaVersion /> */}</div>
          <div className='mb-2 w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6'>{getInveeItems()}</div>
        </Content>
      </Layout>
    </>
  );
};

InveeList.propTypes = {};

export default InveeList;
