export const blogList = [
  {
    id: 'zaproszenia-na-urodziny',
    title: 'Zaproś na Urodziny z Inwitki.net – Nowoczesne E-Zaproszenia!',
    date: '2024-07-25',
    keywords: 'inwitki, zaproszenia na urodziny, e-zaproszenia na urodziny, cyfrowe zaproszenia na urodziny, zaproszenia urodzinowe',
    description: 'Z Inwitki.net tworzenie zaproszeń na urodziny nigdy nie było prostsze! Zamiast martwić się o papierowe zaproszenia, które mogą się zgubić, postaw na nowoczesne, cyfrowe rozwiązanie. Nasze e-zaproszenia są eleganckie, personalizowane i gotowe do wysłania w kilka minut.',
    file: '/blog-data/zaproszenia-na-urodziny.dat',
  },
  {
    id: 'aplikacja-mobilna',
    title: 'Aplikacja Mobilna Inwitki Już Dostępna w Google Play i App Store!',
    date: '2024-07-02',
    keywords: 'inwitki, aplikacja mobilna, cyfrowe zaproszenia, e-zaproszenia, zaproszenia, google play, app store',
    description: 'Jesteśmy podekscytowani, mogąc ogłosić, że nasza innowacyjna aplikacja mobilna Inwitki jest już dostępna do pobrania w Google Play i App Store! To nowoczesne rozwiązanie, które sprawi, że zarządzanie zaproszeniami na Twoje wydarzenia będzie prostsze i bardziej eleganckie niż kiedykolwiek wcześniej.',
    file: '/blog-data/aplikacja-mobilna.dat',
  },
  {
    id: 'atrakcje-na-urodziny-dziecka',
    title: 'Fajne Atrakcje na Urodziny Dziecka – Jak Uczynić Ten Dzień Niezapomnianym',
    date: '2024-06-18',
    keywords: 'urodziny dziecka, atrakcje, animator, klaun, tematyczne party, warsztaty kreatywne, pokaz iluzji, zabawa na świeżym powietrzu, park rozrywki',
    description: 'Organizacja urodzin dziecka to nie lada wyzwanie. Chcemy, aby ten wyjątkowy dzień był pełen radości, uśmiechu i niezapomnianych chwil. Wybór odpowiednich atrakcji może uczynić przyjęcie niezapomnianym dla naszego malucha i jego gości.',
    file: '/blog-data/atrakcje-na-urodziny-dziecka.dat',
  },
  {
    id: 'nowoczesne-cyfrowe-zaproszenia',
    title: 'Inwitki.net – Nowoczesne Cyfrowe Zaproszenia na Wszystkie Okazje',
    date: '2024-06-16',
    keywords: 'cyfrowe zaproszenia, e-zaproszenia, zaproszenia, na ślub, na urodziny, na wieczór panieński, na wieczór kawalerski, na chrzest, na pierwszą komunię świętą.',
    description: 'W dobie cyfryzacji, tradycyjne papierowe zaproszenia zaczynają ustępować miejsca nowoczesnym rozwiązaniom.',
    file: '/blog-data/nowoczesne-cyfrowe-zaproszenia.dat',
  },
  {
    id: 'cyfrowe-inwitki-rewolucja-w-swiecie-zaproszen',
    title: 'Cyfrowe Inwitki: Rewolucja w Świecie Zaproszeń',
    date: '2024-06-12',
    keywords: 'e-zaproszenia, cyfrowe, zaproszenia, inwitki, środowisko, ekologia, rewolucja cyfrowa',
    description: 'Jak zastąpienie papierowych zaproszeń cyfrowymi inwitkami zmienia nasz świat: wpływ na środowisko, ludzi i sposób zapraszania.',
    file: '/blog-data/cyfrowe-inwitki-rewolucja-w-swiecie-zaproszen.dat',
  },
  {
    id: 'tworz-e-zaproszenia-szybko-i-latwo',
    title: 'Inwitki.net: Twórz E-Zaproszenia Szybko i Łatwo',
    date: '2024-06-10',
    keywords: 'e-zaproszenia, cyfrowe, zaproszenia, inwitki, tworzenie, szybko, łatwo, narzędzie',
    description: 'Witamy na inwitki.net – Twoim nowym, nowoczesnym narzędziu do tworzenia wyjątkowych e-zaproszeń na każdą okazję.',
    file: '/blog-data/tworz-e-zaproszenia-szybko-i-latwo.dat',
  },
];
