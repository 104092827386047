import React, { useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import GlobalContext from 'src/context/GlobalContext';
import Layout from 'src/components/layout/Layout';
import TitleBar from 'src/components/layout/TitleBar';
import Footer from 'src/components/layout/Footer';
import Content from 'src/components/layout/Content';
import UserControl from 'src/components/controls/userControl/UserControl';
import { Space, Badge } from 'antd';
import DarkModeControl from 'src/components/controls/darkModeControl/DarkModeControl';
import useTheme from 'src/hooks/useTheme';
//import StyledCarousel from 'src/components/styled/StyledCarousel';
import WhenDesktop from 'src/components/layout/WhenDesktop';
import { useScreen } from 'src/hooks/useScreen';
import { LeftOutlined, LoginOutlined } from '@ant-design/icons';
import StyledButton from 'src/components/styled/StyledButton';
import { ReactComponent as IconQuestion } from 'src/components/svg/how-it-works-question.svg';
// import { ReactComponent as IconProduct } from 'src/components/svg/how-it-works-product.svg';
import { ReactComponent as IconLogin } from 'src/components/svg/how-it-works-login.svg';
import { ReactComponent as IconCreate } from 'src/components/svg/how-it-works-create.svg';
import { ReactComponent as IconPeople } from 'src/components/svg/how-it-works-people.svg';
import { ReactComponent as IconTemplate } from 'src/components/svg/how-it-works-template.svg';
import { ReactComponent as IconParty } from 'src/components/svg/how-it-works-party.svg';
import * as Ga4 from 'src/misc/Ga4';
import { Fade } from 'react-awesome-reveal';
import { login } from '../../../misc/Session';

//import PropTypes from 'prop-types';

const SlideItem = ({ picture, title, children }) => (
  <div className='h-full flex flex-col gap-4 items-center shadow-lg  shadow-neutral-200 dark:shadow-neutral-700 rounded-2xl border border-solid border-neutral-200 dark:border-neutral-700 overflow-hidden'>
    <div className='bg-neutral-100 dark:bg-neutral-600 w-full h-[160px] px-0 py-4 text-center'>{picture}</div>
    <div className='text-2xl text-center px-4'>{title}</div>
    <div className='text-base text-center px-4 mb-4'>{children}</div>
  </div>
);

const HowItWorks = () => {
  const globalContext = useContext(GlobalContext);
  const { theme } = useTheme();
  const { isDesktop } = useScreen();
  const navigate = useNavigate();
  const location = useLocation();

  const breadcrumbRoutes = globalContext.userInfo
    ? [
        {
          title: 'Moje projekty',
          path: '/projects',
        },
        {
          title: 'Jak działają inwitki.net?',
        },
      ]
    : [
        {
          title: 'Jak działają inwitki.net?',
        },
      ];

  useEffect(() => {
    Ga4.pageView('How It Works');
  }, []);

  const handleWeStart = () => {
    if (globalContext.userInfo) {
      // window.gtag('event', 'We_start_was_logged_in', { event_category: 'Landing' });
      Ga4.event('How It Works', 'We_start_was_logged_in', 'We_start / was_logged_in');
      navigate('/projects');
    } else {
      //window.gtag('event', 'We_start_was_logged_out', { event_category: 'Landing' });
      Ga4.event('How It Works', 'We_start_was_logged_in', 'We_start / was_logged_in');
      login();
    }
  };
  return (
    <Layout
      header={
        <TitleBar
          path='/'
          isLoading={globalContext.isBusy}
          leftExtras={
            <>
              {location.key !== 'default' && (
                <StyledButton color={theme.whiteButton} bgColor='transparent' onClick={() => navigate(-1)}>
                  <Space>
                    <LeftOutlined />
                    <WhenDesktop>Cofnij</WhenDesktop>
                  </Space>
                </StyledButton>
              )}
            </>
          }
          rightExtras={
            <Space>
              <DarkModeControl />
              <UserControl />
            </Space>
          }
        />
      }
      footer={
        <WhenDesktop>
          <Footer />
        </WhenDesktop>
      }
    >
      <Content
        breadcrumbRoutes={breadcrumbRoutes}
        title={
          isDesktop ? (
            <>
              Jak działają inwitki<span className='opacity-60'>.net</span>?
            </>
          ) : null
        }
      >
        <div class='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8'>
          <Fade className='h-full' cascade triggerOnce damping={0.2}>
            <SlideItem theme={theme} title={'Co to jest inwitka?'} picture={<IconQuestion className='max-h-full w-auto lg:h-auto lg:max-w-full' />}>
              Inwitka to nowoczesne, cyfrowe zaproszenie na dowolną okazję: ślub, wesele, urodziny, imieniny, przyjęcie rodzinne, impreza dla przyjaciół, wieczór panieński, wieczór kawalerski lub jakiekolwiek inne wydarzenie.
            </SlideItem>
            <SlideItem theme={theme} title={<>Łatwe logowanie</>} picture={<IconLogin className='max-h-full w-auto lg:h-auto lg:max-w-full' />}>
              Nie musisz zakładać nowego konta! Zaloguj się za pomocą swojego konta na Facebooku lub Google. To szybkie i wygodne rozwiązanie, które pozwala natychmiast rozpocząć tworzenie zaproszeń.
            </SlideItem>

            <SlideItem theme={theme} inverted title={<>Wybór szablonu</>} picture={<IconTemplate className='max-h-full w-auto lg:h-auto lg:max-w-full' />}>
              Wybierz jeden z naszych eleganckich i nowoczesnych szablonów zaproszeń. Mamy szeroki wybór wzorów dostosowanych do różnych okazji – od urodzin, przez wesela, aż po spotkania z przyjaciółmi.
            </SlideItem>

            <SlideItem theme={theme} inverted title={<>Personalizacja</>} picture={<IconCreate className='max-h-full w-auto lg:h-auto lg:max-w-full' />}>
              Wprowadź szczegóły dotyczące Twojego wydarzenia – miejsce, data, godzina, a także dodatkowe informacje, które mogą być przydatne dla Twoich gości.
            </SlideItem>

            <SlideItem theme={theme} title={<>Generowanie zaproszeń</>} picture={<IconPeople className='max-h-full w-auto lg:h-auto lg:max-w-full' />}>
              Na podstawie stworzonego projektu możesz wygenerować imienne inwitki dla swoich gości. Każde zaproszenie można wysłać przez komunikator lub email jako link, albo wygenerować kod QR, który można nadrukować na papierowe zaproszenie.
            </SlideItem>

            <SlideItem theme={theme} title={<>Powitaj wspaniałych gości</>} picture={<IconParty className='max-h-full w-auto lg:h-auto lg:max-w-full' />}>
              Twórz inwitki na wszystkie okazje.
              <br />
              Aby każdy mógł dotrzeć na czas.
            </SlideItem>
          </Fade>
        </div>
        <div className='my-8'>
          <Fade triggerOnce>
            <div className='h-full flex flex-col gap-4 items-center shadow-lg  shadow-neutral-200 dark:shadow-neutral-700 rounded-2xl border border-solid border-neutral-200 dark:border-neutral-700 overflow-hidden'>
              <div className='text-center xl:mx-6 my-24 xl:my-32 font-roboto-slab'>
                <div className='flex flex-row justify-center'>
                  <Badge.Ribbon color='red' text='CAŁKOWICIE ZA DARMO' style={{ marginRight: '-8px', marginTop: '-26px' }}>
                    <div className='text-2xl xl:text-5xl'>
                      Chcesz mieć takie <span className='opacity-60'>w 2 minuty</span>?
                    </div>
                  </Badge.Ribbon>
                </div>

                <div className='text-center my-16 md:text-5xl md:mt-12 md:mb-16 font-roboto-slab'>
                  <Space>
                    <StyledButton color={theme.dark4} size='large' type='primary' onClick={handleWeStart} style={{ transform: true === isDesktop ? 'scale(1.5)' : null }}>
                      <Space className='btn'>
                        <LoginOutlined />
                        Zaczynamy
                      </Space>
                    </StyledButton>
                  </Space>
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </Content>
    </Layout>
  );
};

// HowItWorks.propTypes = {};

export default HowItWorks;
