import React, { useContext, useEffect } from 'react';
import GlobalContext from 'src/context/GlobalContext';
import Layout from 'src/components/layout/Layout';
import TitleBar from 'src/components/layout/TitleBar';
import Footer from 'src/components/layout/Footer';
import Content from 'src/components/layout/Content';
import UserControl from 'src/components/controls/userControl/UserControl';
import DarkModeControl from 'src/components/controls/darkModeControl/DarkModeControl';
import { Space } from 'antd';
import StyledButton from 'src/components/styled/StyledButton';
import { useLocation, useNavigate } from 'react-router-dom';
import useTheme from 'src/hooks/useTheme';
import { LeftOutlined } from '@ant-design/icons';
import WhenDesktop from 'src/components/layout/WhenDesktop';
import DataProcessingContent from './DataProcessingContent';
// import BetaVersion from 'src/components/controls/betaVersion/BetaVersion';
import { ReactComponent as Data } from 'src/components/svg/data-processing.svg';
import Hr from 'src/components/layout/Hr';
import * as Ga4 from 'src/misc/Ga4';
//import PropTypes from 'prop-types';

const DataProcessing = () => {
  const globalContext = useContext(GlobalContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { theme } = useTheme();

  const breadcrumbRoutes = globalContext.userInfo
    ? [
        {
          title: 'Moje projekty',
          path: '/projects',
        },
        {
          title: 'Przetwarzanie Danych',
        },
      ]
    : [
        {
          title: 'Przetwarzanie Danych',
        },
      ];

  useEffect(() => {
    Ga4.pageView('Data Processing');
  }, []);

  return (
    <Layout
      header={
        <TitleBar
          path='/'
          isLoading={globalContext.isBusy}
          leftExtras={
            <>
              {location.key !== 'default' && (
                <StyledButton color={theme.whiteButton} bgColor='transparent' onClick={() => navigate(-1)}>
                  <Space>
                    <LeftOutlined />
                    <WhenDesktop>Cofnij</WhenDesktop>
                  </Space>
                </StyledButton>
              )}
            </>
          }
          rightExtras={
            <Space>
              <DarkModeControl />
              <UserControl />
            </Space>
          }
        />
      }
      footer={<Footer />}
    >
      <Content breadcrumbRoutes={breadcrumbRoutes}>
        {/* <BetaVersion /> */}
        <div className='my-4 lg:my-4 flex flex-col lg:flex-row gap-8 justify-center items-center' style={{ color: theme.dark3 }}>
          <div className='flex flex-row justify-center'>
            <Data className='h-48 w-64' />
          </div>
          <div className='flex flex-col gap-2 justify-center w-full lg:w-1/3'>
            <div className='text-xl lg:text-3xl text-center lg:text-left'>Przetwarzanie Danych</div>
            <div className='text-sm opacity-60 text-center lg:text-left'>
              W inwitki<span className='opacity-60'>.net</span> (dalej jako <strong>serwis</strong>) dbamy o Twoją prywatność i chcemy, abyś czuł się bezpiecznie korzystając z naszego serwisu.
            </div>
          </div>
        </div>

        <Hr />

        <DataProcessingContent />
      </Content>
    </Layout>
  );
};

DataProcessing.propTypes = {};

export default DataProcessing;
