import React, { useContext, useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import InveesContext from 'src/context/InveesContext';
import short from 'short-uuid';
import { DeleteOutlined } from '@ant-design/icons';
import { FaRegPaste } from 'react-icons/fa6';
import YesNoDialog from 'src/components/layout/YesNoDialog';
import { Card, Button, DatePicker, TimePicker, Input, Space } from 'antd';
import { EXTRACT_COORDS_REGEX, MAX_DATES_LOCATIONS_COUNT } from 'src/misc/Config';
import { getMapUrl, notEmptyArray } from 'src/misc/Misc';
// import locale from 'antd/es/date-picker/locale/pl_PL';
import useTheme from 'src/hooks/useTheme';

import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import 'dayjs/locale/pl';

dayjs.extend(isoWeek);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
dayjs.locale('pl');

const { TextArea } = Input;

const LOCATION_MAX_LENGTH = 100;

let timeoutRef = null;

const InveeEditorDatesLocations = ({ defaultDatesLocations }) => {
  const inveesContext = useContext(InveesContext);
  const divList = useRef(null);

  const [idToHighlight, setIdToHighlight] = useState(null);
  const [datesLocations, setDatesLocations] = useState(null);

  const { theme } = useTheme();

  useEffect(() => {
    setDatesLocations(JSON.parse(JSON.stringify(defaultDatesLocations)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddDateLocation = () => {
    const dl = JSON.parse(JSON.stringify(datesLocations));
    const newItem = { id: short.generate(), name: 'Nowe Miejsce', date: dayjs().startOf('day').add(14, 'days').add(16, 'hours').format(), location: '', coords: { lat: '', lon: '' } };
    dl.unshift(newItem);
    setDatesLocations(dl);
    inveesContext.setCurrentEditorChanges({ ...inveesContext.setCurrentEditorChanges, datesLocations: dl });

    setIdToHighlight(newItem.id);
    divList.current.scrollTo({ top: 0, behavior: 'smooth' });
    if (!timeoutRef) clearTimeout(timeoutRef);
    timeoutRef = setTimeout(() => {
      if (timeoutRef) {
        clearTimeout(timeoutRef);
        timeoutRef = null;
        setIdToHighlight(null);
      }
    }, 800);
  };

  const handleOnDeleteDateLocation = (id) => {
    const dl = datesLocations.filter((dl) => dl.id !== id);
    setDatesLocations(dl);
    inveesContext.setCurrentEditorChanges({ ...inveesContext.setCurrentEditorChanges, datesLocations: dl });
  };

  const getDatesLocationsComponent = (dateLocation) => {
    return (
      <Card key={dateLocation.id} className={'relative'} style={{ backgroundColor: idToHighlight === dateLocation.id ? theme.primary : null }}>
        <div className='absolute right-3 top-3 z-10'>
          <YesNoDialog
            onYesClick={() => {
              handleOnDeleteDateLocation(dateLocation.id);
            }}
            onNoClick={() => {}}
            title='Usuń datę / miejsce'
            body='Czy na pewno chcesz trwale usunąć ten element?'
            labelYes='Usuń'
            labelNo='Anuluj'
          >
            <DeleteOutlined className='text-xl hover:hover:scale-110 cursor-pointer' />
          </YesNoDialog>
        </div>
        <div>
          <div className='mt-4 font-bold'>Nazwa miejsca:</div>
          <div className='mb-1 font-light text-xs'>np: Kościół Imienia NMP, Restauracja u Jakuba, Sala Zabaw Bajlandia,</div>
          <Input
            status={dateLocation.name.length < 1 ? 'error' : null}
            showCount
            maxLength={50}
            value={dateLocation.name}
            onChange={(e) => {
              const dl = JSON.parse(JSON.stringify(datesLocations));
              let current = dl.find((x) => x.id === dateLocation.id);
              current.name = e.target.value;
              setDatesLocations(dl);
              inveesContext.setCurrentEditorChanges({ ...inveesContext.setCurrentEditorChanges, datesLocations: dl });
            }}
          />
          <div className='mt-4 mb-1 font-bold'>Data:</div>
          <DatePicker
            disabledDate={(current) => {
              if (current && current < dayjs().startOf('day')) return true;
              const { expiryDate } = inveesContext?.currentInvee ?? {};
              if (expiryDate && current > dayjs(expiryDate).endOf('day')) return true;
              return false;
            }}
            value={dayjs(dateLocation.date)}
            onChange={(e) => {
              const dl = JSON.parse(JSON.stringify(datesLocations));
              let current = dl.find((x) => x.id === dateLocation.id);
              current.date = e.format();
              setDatesLocations(dl);
              inveesContext.setCurrentEditorChanges({ ...inveesContext.setCurrentEditorChanges, datesLocations: dl });
            }}
          />
          <div className='mt-4 mb-1 font-bold'>Czas:</div>
          <TimePicker
            value={dayjs(dateLocation.date)}
            format={'HH:mm'}
            minuteStep={5}
            onOk={(e) => {
              const dl = JSON.parse(JSON.stringify(datesLocations));
              let current = dl.find((x) => x.id === dateLocation.id);
              current.date = e.format();
              setDatesLocations(dl);
              inveesContext.setCurrentEditorChanges({ ...inveesContext.setCurrentEditorChanges, datesLocations: dl });
            }}
          />
          <div className='mt-4 font-bold'>Miejsce:</div>
          <div className='mb-1 font-light text-xs'>adres wydarzenia wyświetlany na inwitce</div>
          <TextArea
            showCount
            maxLength={LOCATION_MAX_LENGTH}
            autoSize={{ minRows: 2, maxRows: 5 }}
            value={dateLocation.location}
            onChange={(e) => {
              const value = e.target.value;
              const dl = JSON.parse(JSON.stringify(datesLocations));
              let current = dl.find((x) => x.id === dateLocation.id);
              current.location = value.lenght <= LOCATION_MAX_LENGTH ? value : value.substring(0, LOCATION_MAX_LENGTH);
              setDatesLocations(dl);
              inveesContext.setCurrentEditorChanges({ ...inveesContext.setCurrentEditorChanges, datesLocations: dl });
            }}
          />

          <div className='mt-4 font-bold'>Współrzędne GPS:</div>
          <div className='mb-1 font-light text-xs'>jeśli zostaną podane, inwitka będzie miała możliwość otworzenia mapy</div>
          <div className='flex flex-row gap-4'>
            <Input
              placeholder='52.254829'
              maxLength={10}
              value={dateLocation.coords?.lat}
              onChange={(e) => {
                const dl = JSON.parse(JSON.stringify(datesLocations));
                let current = dl.find((x) => x.id === dateLocation.id);
                current.coords.lat = e.target.value.replace(/[^\d.-]+/g, '');
                setDatesLocations(dl);
                inveesContext.setCurrentEditorChanges({ ...inveesContext.setCurrentEditorChanges, datesLocations: dl });
              }}
              onBlur={(e) => {
                if (e.target.value.length < 1) return;
                const dl = JSON.parse(JSON.stringify(datesLocations));
                let current = dl.find((x) => x.id === dateLocation.id);
                let value = Number(e.target.value.replace(/[^\d.-]+/g, ''));
                if (value > 55) value = current.coords.lat = '55.000000';
                if (value < 49) value = current.coords.lat = '49.000000';
                current.coords.lat = value.toString();
                setDatesLocations(dl);
                inveesContext.setCurrentEditorChanges({ ...inveesContext.setCurrentEditorChanges, datesLocations: dl });
              }}
            />
            <Input
              placeholder='21.015389'
              maxLength={10}
              value={dateLocation.coords?.lon}
              onChange={(e) => {
                const dl = JSON.parse(JSON.stringify(datesLocations));
                let current = dl.find((x) => x.id === dateLocation.id);
                current.coords.lon = e.target.value.replace(/[^\d.-]+/g, '');
                setDatesLocations(dl);
                inveesContext.setCurrentEditorChanges({ ...inveesContext.setCurrentEditorChanges, datesLocations: dl });
              }}
              onBlur={(e) => {
                if (e.target.value.length < 1) return;
                const dl = JSON.parse(JSON.stringify(datesLocations));
                let current = dl.find((x) => x.id === dateLocation.id);
                let value = Number(e.target.value.replace(/[^\d.-]+/g, ''));
                if (value > 25) value = current.coords.lon = '25.000000';
                if (value < 14) value = current.coords.lon = '14.000000';
                current.coords.lon = value.toString();
                setDatesLocations(dl);
                inveesContext.setCurrentEditorChanges({ ...inveesContext.setCurrentEditorChanges, datesLocations: dl });
              }}
            />
            <Button
              onClick={async () => {
                const textFromClipboard = await navigator.clipboard.readText();
                if (!textFromClipboard) return;
                const coords = textFromClipboard.match(EXTRACT_COORDS_REGEX);
                if (true === notEmptyArray(coords) && coords.length === 2) {
                  const dl = JSON.parse(JSON.stringify(datesLocations));
                  let current = dl.find((x) => x.id === dateLocation.id);
                  current.coords.lat = coords[0];
                  current.coords.lon = coords[1];
                  setDatesLocations(dl);
                  inveesContext.setCurrentEditorChanges({ ...inveesContext.setCurrentEditorChanges, datesLocations: dl });
                }
                // console.log(coords);
              }}
            >
              <Space className='btn'>
                <FaRegPaste />
              </Space>
            </Button>
          </div>
          <div className='flex flex-col '>
            <Button
              type='default'
              className='mt-4'
              onClick={() => {
                window.open('/pages/video/gps-coordinates', '_blank');
              }}
            >
              Jak znaleźć współrzędne GPS?
            </Button>
            <Button
              disabled={(function () {
                const dl = JSON.parse(JSON.stringify(datesLocations));
                let current = dl.find((x) => x.id === dateLocation.id);
                return !current.coords?.lat || !current.coords?.lon;
              })()}
              type='default'
              className='mt-4'
              onClick={() => {
                let current = datesLocations.find((x) => x.id === dateLocation.id);
                const mapUrl = getMapUrl(current.coords);
                window.open(mapUrl, '_blank');
              }}
            >
              Testuj współrzędne GPS
            </Button>
          </div>
        </div>
      </Card>
    );
  };

  if (!datesLocations) return null;

  return (
    <div className='w-full h-full flex flex-col'>
      <div className='flex-initial pb-4'>
        <Button type='primary' onClick={handleAddDateLocation} disabled={datesLocations?.length >= MAX_DATES_LOCATIONS_COUNT}>
          Dodaj
        </Button>
      </div>

      <div ref={divList} className='flex-auto overflow-y-scroll py-4 flex flex-col gap-4'>
        {datesLocations.map((dl) => getDatesLocationsComponent(dl))}
      </div>
    </div>
  );
};

InveeEditorDatesLocations.propTypes = {
  defaultDatesLocations: PropTypes.array.isRequired,
};

InveeEditorDatesLocations.defaultProps = {
  defaultDatesLocations: [],
};

export default InveeEditorDatesLocations;
