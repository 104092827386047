import React from 'react';
import { useNavigate } from 'react-router-dom';
import useConsents from 'src/hooks/useConsents';
import PropTypes from 'prop-types';
import { themeLight } from '../../misc/Theme';
import { LiaUserSecretSolid } from 'react-icons/lia';
import { IoChatboxEllipsesOutline } from 'react-icons/io5';
import { GoLaw } from 'react-icons/go';
import { FaFacebook, FaYoutube } from 'react-icons/fa';
import { MdOutlineCookie, MdArticle } from 'react-icons/md';
import * as Ga4 from '../../misc/Ga4';

import WhenDesktop from './WhenDesktop';

const Footer = ({ centredTo, padding, backgroundColor, foregroundColor }) => {
  const navigate = useNavigate();
  const { setCookieConsentsOpen } = useConsents();

  return (
    <div className='flex flex-row' style={{ justifyContent: 'center', backgroundColor: backgroundColor, color: foregroundColor }}>
      <div
        style={{
          width: centredTo ?? '100%',
          padding,
        }}
      >
        <div className='flex flex-row flex-wrap justify-center gap-2 lg:text-base lg:gap-4'>
          <div
            className='flex flex-row gap-1 items-center cursor-pointer text-sm hover:opacity-80 '
            onClick={() => {
              navigate('/pages/regulations');
            }}
          >
            <WhenDesktop>
              <GoLaw />
            </WhenDesktop>
            Regulamin
          </div>
          <div
            className='flex flex-row gap-1 items-center cursor-pointer text-sm hover:opacity-80 '
            onClick={() => {
              navigate('/pages/privacy-policy');
            }}
          >
            <WhenDesktop>
              <LiaUserSecretSolid />
            </WhenDesktop>
            Polityka Prywatności
          </div>
          <div
            className='flex flex-row gap-1 items-center cursor-pointer text-sm hover:opacity-80 '
            onClick={() => {
              setCookieConsentsOpen(true);
            }}
          >
            <WhenDesktop>
              <MdOutlineCookie />
            </WhenDesktop>
            Cookies
          </div>
          <div
            className='flex flex-row gap-1 items-center cursor-pointer text-sm hover:opacity-80 '
            onClick={() => {
              navigate('/pages/contact');
            }}
          >
            <WhenDesktop>
              <IoChatboxEllipsesOutline />
            </WhenDesktop>
            Kontakt
          </div>

          <div
            className='flex flex-row gap-1 items-center cursor-pointer text-sm hover:opacity-80 '
            onClick={() => {
              Ga4.event('Footer', 'Go_to_facebook', 'Go_to facebook');
              window.open('https://www.facebook.com/inwitki.net/', '_blank');
            }}
          >
            <WhenDesktop>
              <FaFacebook />
            </WhenDesktop>
            Facebook
          </div>
          <div
            className='flex flex-row gap-1 items-center cursor-pointer text-sm hover:opacity-80 '
            onClick={() => {
              Ga4.event('Footer', 'Go_to_youtube', 'Go_to youtube');
              window.open('https://www.youtube.com/@inwitki', '_blank');
            }}
          >
            <WhenDesktop>
              <FaYoutube />
            </WhenDesktop>
            Youtube
          </div>
          <div
            className='flex flex-row gap-1 items-center cursor-pointer text-sm hover:opacity-80'
            onClick={() => {
              navigate('/blog');
            }}
          >
            <WhenDesktop>
              <MdArticle />
            </WhenDesktop>
            Blog
          </div>
        </div>
      </div>
    </div>
  );
};
Footer.propTypes = {
  centredTo: PropTypes.string,
  padding: PropTypes.string,
  backgroundColor: PropTypes.string,
  foregroundColor: PropTypes.string,
};

Footer.defaultProps = {
  centredTo: themeLight.centredTo,
  padding: '12px',
  backgroundColor: themeLight.dark3,
  foregroundColor: themeLight.light2,
};

export default Footer;
