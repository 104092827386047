import { FaPaintbrush, FaFont, FaBookmark, FaBookOpen, FaLocationDot } from 'react-icons/fa6';

export const DEFAULT_CALLBACK_URL_LOGIN = '/projects';
export const MOBILE_TRESHOLD = 1023;
export const DESKTOP_TRESHOLD = 1024;
export const MIN_GUEST_LENGTH = 2;
export const MAX_INVEE_PROJECTS = 16;
export const MAX_STORED_INVEE_ITEMS = 16;
export const MAX_CONTENT_LENGTH = 500;
export const MAX_DATES_LOCATIONS_COUNT = 5;
export const GUEST_REGEX = /[^a-zA-Z0-9żźćńółęąśŻŹĆĄŚĘŁÓŃ -]/g;
export const SCRUB_HTML_REGEX = /(<([^>]+)>)/gi;
export const COORDS_REGEX = /^-?\d{1,3}\.\d{1,6}$/;
export const EXTRACT_COORDS_REGEX = /-?\d{1,3}\.\d{1,6}/g;
export const LOCAL_STORAGE_KEYS = {
  cognitoAccessToken: 'auth.access_token',
  cognitoRefreshTokenHash: 'auth.refresh_token_hash',
  inveeStringsDatabase: 'inveeStrings.database',
  isDarkMode: 'theme.isDarkMode',
  cookieConsents: 'consents.cookie',
};
export const SCREEN_MODES = {
  mobile: 'mobile',
  desktop: 'desktop',
};
export const SCREEN_RATIOS = {
  portrait: 'portrait',
  landscape: 'landscape',
};
export const CURRENT_EDITORS = {
  style: { id: 'style', label: 'Styl', icon: <FaPaintbrush /> },
  font: { id: 'font', label: 'Czcionka', icon: <FaFont /> },
  title: { id: 'title', label: 'Tytuł', icon: <FaBookmark /> },
  contents: { id: 'contents', label: 'Treść', icon: <FaBookOpen /> },
  datesLocations: { id: 'datesLocations', label: 'Daty i miejsca', icon: <FaLocationDot /> },
};
export let ENV = null;
export const determineEnv = () => {
  // <localhost>
  if (window.location.hostname === 'localhost' || window.location.hostname === '192.168.1.224') {
    ENV = {
      cognitoClientId: '64q02312fsn6obos8d4l4vqb4u',
      cognitoDomain: 'login.inwitki.net',
      inveeApi: 'http://localhost:4000',
    };
  }
  // </localhost>

  // <AWS DEV>
  if (window.location.hostname === 'd17to4godznek4.cloudfront.net') {
    ENV = {
      cognitoClientId: '64q02312fsn6obos8d4l4vqb4u',
      cognitoDomain: 'login.inwitki.net',
      inveeApi: 'https://b1oxwkri48.execute-api.eu-central-1.amazonaws.com/dev',
    };
  }
  // </AWS DEV>

  // <AWS PROD>
  if (window.location.hostname === 'd1kxedhmspxvla.cloudfront.net' || window.location.hostname === 'inwitki.net') {
    ENV = {
      cognitoClientId: '64q02312fsn6obos8d4l4vqb4u',
      cognitoDomain: 'login.inwitki.net',
      inveeApi: 'https://zkkowub0ej.execute-api.eu-central-1.amazonaws.com/prod',
    };
  }
  // </AWS PROD>
};
export const FONTS = [
  {
    name: 'Internetowa',
    fontName: 'Roboto',
    class: 'font-roboto',
  },
  {
    name: 'Drukarska',
    fontName: 'Roboto Slab',
    class: 'font-roboto-slab',
  },
  // {
  //   name: 'Changa',
  //   class: 'font-changa',
  // },
  // {
  //   name: 'Bruno Ace',
  //   class: 'font-bruno-ace',
  // },
  // {
  //   name: 'Glory',
  //   class: 'font-glory',
  // },
  {
    name: 'Odręczna',
    fontName: 'Fuzzy Bubbles',
    class: 'font-fuzzy-bubbles',
  },
  // {
  //   name: 'Arima',
  //   class: 'font-arima',
  // },
  // {
  //   name: 'Gabriela',
  //   class: 'font-gabriela',
  // },
  // {
  //   name: 'Kalam',
  //   class: 'font-kalam',
  // },
  // {
  //   name: 'Caveat',
  //   class: 'font-caveat',
  // },
  // {
  //   name: 'Courgette',
  //   class: 'font-courgette',
  // },
  // {
  //   name: 'Ephesis',
  //   class: 'font-ephesis',
  // },
];
export const PAYMENT_STATUSES = { new: 'W trakcie', completed: 'Zakończona' };
