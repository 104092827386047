import React, { useContext, useEffect, useState } from 'react';
import GlobalContext from 'src/context/GlobalContext';
import Layout from 'src/components/layout/Layout';
import TitleBar from 'src/components/layout/TitleBar';
import Footer from 'src/components/layout/Footer';
import Content from 'src/components/layout/Content';
import UserControl from 'src/components/controls/userControl/UserControl';
import DarkModeControl from 'src/components/controls/darkModeControl/DarkModeControl';
import { Space, Form, Input, Button, Select, notification, Result } from 'antd';
import StyledButton from 'src/components/styled/StyledButton';
import { useLocation, useNavigate } from 'react-router-dom';
import useTheme from 'src/hooks/useTheme';
import { LeftOutlined, SendOutlined } from '@ant-design/icons';
import WhenDesktop from 'src/components/layout/WhenDesktop';
import * as Ga4 from 'src/misc/Ga4';
import { ReactComponent as Mailbox } from 'src/components/svg/contact-mailbox.svg';
import { sleepMs } from 'src/misc/Misc';
import { postContact } from 'src/api/invee';

const ContactForm = () => {
  const globalContext = useContext(GlobalContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { theme } = useTheme();

  const breadcrumbRoutes = globalContext.userInfo
    ? [
        {
          title: 'Moje projekty',
          path: '/projects',
        },
        {
          title: 'Kontakt',
          path: '/pages/contact',
        },
        {
          title: 'Formularz kontaktowy',
        },
      ]
    : [
        {
          title: 'Kontakt',
          path: '/pages/contact',
        },
        {
          title: 'Formularz kontaktowy',
        },
      ];

  const subjectOptions = [
    { label: 'RODO / przetwarzenie danych osobowych', value: 'RODO' },
    { label: 'Reklamacja', value: 'Reklamacja' },
    { label: 'Problem z działaniem serwisu', value: 'Problem' },
    { label: 'Propozycja współpracy', value: 'Współpraca' },
    { label: 'Inne', value: 'Ine' },
  ];

  const [messageSent, setMessageSent] = useState(false);

  useEffect(() => {
    Ga4.pageView('Contact Form');
    loadingData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadingData = async () => {
    await globalContext.executeBlockingProcess(async () => {
      await sleepMs(10000);
    });
  };

  const onFinishFailed = (errorInfo) => {
    notification.error({
      message: 'Ups...',
      description: (
        <>
          <div>
            Aby wysłać do nas wiadomość,
            <br />
            proszę poprawnie wypełnić formularz.
          </div>
        </>
      ),
      duration: 5,
    });
  };

  const onFinish = (values) => {
    console.log(values);
    globalContext.executeBlockingProcess(async () => {
      try {
        await postContact(values.name, values.email, values.subject, values.TextArea);
        notification.success({
          message: 'Dziękujemy za kontakt!',
          description: (
            <>
              <div>
                Twoja wiadomość została wysłana.
                <br />
                Postaramy się odpowiedzieć jak najszybciej.
              </div>
            </>
          ),
          duration: 5,
        });
        await sleepMs(5000);
        setMessageSent(true);
      } catch (error) {
        notification.error({
          message: 'Ups...',
          description: (
            <>
              <div>coś poszło nie po naszej myśli :-(</div>
              <div className='text-xs text-gray-300'>spróbuj ponownie później</div>
            </>
          ),
          duration: 5,
        });
      }
    });
  };

  return (
    <Layout
      header={
        <TitleBar
          path='/'
          isLoading={globalContext.isBusy}
          leftExtras={
            <>
              {location.key !== 'default' && (
                <StyledButton color={theme.whiteButton} bgColor='transparent' onClick={() => navigate(-1)}>
                  <Space>
                    <LeftOutlined />
                    <WhenDesktop>Cofnij</WhenDesktop>
                  </Space>
                </StyledButton>
              )}
            </>
          }
          rightExtras={
            <Space>
              <DarkModeControl />
              <UserControl />
            </Space>
          }
        />
      }
      footer={<Footer />}
    >
      <Content breadcrumbRoutes={breadcrumbRoutes}>
        <div className='mt-4 lg:mt-24 flex flex-col lg:flex-row gap-8 justify-center' style={{ color: theme.dark3 }}>
          <div className='flex flex-row justify-center'>
            <Mailbox className='h-48 w-64' />
          </div>
          <div className='flex flex-col gap-4 justify-center lg:w-[500px]'>
            {!messageSent ? (
              <>
                <div className='text-2xl text-center lg:text-left'>Formularz kontaktowy</div>
                <div className='flex flex-row justify-center lg:justify-start'>
                  <Form layout='vertical' name='nest-messages' onFinish={onFinish} onFinishFailed={onFinishFailed} className='w-full sm:w-2/3 lg:w-full' initialValues={{ email: globalContext?.userInfo?.email ?? '' }}>
                    <Form.Item name={'name'} label='Twoje imię' rules={[{ required: true }]}>
                      <Input showCount maxLength={50} autoComplete='off' />
                    </Form.Item>
                    <Form.Item name='email' label='Adres email, na który mamy odpowiedzieć' rules={[{ required: true, type: 'email' }]}>
                      <Input showCount maxLength={50} autoComplete='off' />
                    </Form.Item>
                    <Form.Item name='subject' label='Temat wiadomości' rules={[{ required: true }]}>
                      <Select options={subjectOptions} />
                    </Form.Item>
                    <Form.Item name='TextArea' label='Treść wiadomości' rules={[{ required: true, min: 24 }]}>
                      <Input.TextArea showCount maxLength={250} autoSize={{ minRows: 3, maxRows: 8 }} autoComplete='off' />
                    </Form.Item>
                    <Form.Item className='text-center lg:text-left mt-8'>
                      <Button type='primary' htmlType='submit'>
                        <Space>
                          <SendOutlined />
                          Wyślij wiadomość
                        </Space>
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </>
            ) : (
              <>
                <Result
                  status='success'
                  title='Dziękujemy za kontakt!'
                  subTitle={
                    <>
                      Twoja wiadomość została wysłana.
                      <br />
                      Postaramy się odpowiedzieć jak najszybciej.
                    </>
                  }
                  extra={[
                    <Button
                      key='home'
                      type='primary'
                      onClick={() => {
                        if (globalContext.userInfo) {
                          navigate('/projects');
                          return;
                        }
                        navigate('/');
                      }}
                    >
                      Wróć do strony głównej
                    </Button>,
                  ]}
                />
              </>
            )}
          </div>
        </div>
      </Content>
    </Layout>
  );
};

// ContactForm.propTypes = {};

export default ContactForm;
