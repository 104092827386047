import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import StyledButton from 'src/components/styled/StyledButton';
import { Space } from 'antd';
import { MdOutlineDarkMode, MdOutlineLightMode } from 'react-icons/md';
import useTheme from 'src/hooks/useTheme';

const StylesWrapper = styled.div``;

const DarkModeControl = ({ color }) => {
  const { theme, isDarkMode, toggleDarkMode } = useTheme();
  return (
    <StylesWrapper>
      <StyledButton color={color ?? theme.whiteButton} bgColor='transparent' onClick={toggleDarkMode}>
        <Space className='btn'>{isDarkMode ? <MdOutlineLightMode /> : <MdOutlineDarkMode />}</Space>
      </StyledButton>
    </StylesWrapper>
  );
};

DarkModeControl.propTypes = {
  color: PropTypes.string,
};

export default DarkModeControl;
