import React, { useState } from 'react';
import { useScreen } from 'src/hooks/useScreen';
// import useTheme from 'src/hooks/useTheme';
import queryString from 'query-string';
import { SCREEN_RATIOS } from 'src/misc/Config';
import { ReactComponent as AppStoreBadge } from 'src/components/svg/badge-app-store.svg';
import { ReactComponent as PlayStoreBadge } from 'src/components/svg/badge-play-store.svg';
import StyledButton from 'src/components/styled/StyledButton';
import { Space } from 'antd';
import { handleDownloadAppStore, handleDownloadPlayStore } from 'src/misc/Misc';
import PropTypes from 'prop-types';
import { ReactComponent as InveeLogo } from 'src/components/svg/invee-logo.svg';
import { TbDeviceMobileHeart } from 'react-icons/tb';

var showStoresTimeout = null;

const MobileAppPrompt = ({ inveeString, style }) => {
  const { screenSize, screenRatio, mobileOs } = useScreen();
  // const { theme } = useTheme();

  const [showStores, setShowStores] = useState(false);
  const { debug } = queryString.parse(window.location.search);

  if (screenSize.width >= 500) return null;
  if (screenRatio === SCREEN_RATIOS.landscape) return null;

  const handleShowMore = () => {
    setShowStores(true);
    if (showStoresTimeout) clearTimeout(showStoresTimeout);
    showStoresTimeout = setTimeout(() => setShowStores(false), 12000);
  };

  const handleOpenInApp = async () => {
    try {
      const customURLScheme = debug === '1' ? `exp://192.168.1.164:8081/?i=${inveeString}` : `com.bullapp.inwitki://?i=${inveeString}`;
      window.location.replace(customURLScheme);
      //   const userAgent = navigator.userAgent.toLowerCase();
      //   const isChrome = userAgent.indexOf('chrome') > -1 && !userAgent.includes('edg'); // Edge also contains 'chrome'

      //   if (isChrome) {
      //     openAppChrome(customURLScheme);
      //   } else {
      //     openAppChrome(customURLScheme);
      //   }

      // window.location.replace(customURLScheme);
      // alert(customURLScheme);
    } catch (error) {
      console.error(error);
    }
  };

  //   const openApp = (customURLScheme) => {
  //     let iframe = document.createElement('iframe');
  //     iframe.style.display = 'none';
  //     iframe.src = customURLScheme;
  //     document.body.appendChild(iframe);
  //   };

  //   const openAppChrome = (customURLScheme) => {
  //     window.location.replace(customURLScheme);
  //   };

  const ShowMoreBtn = () => (
    <StyledButton className='scailing-1' size='large' color={style.color} bgColor={style.bgColor} style={{ width: '100%' }} onClick={handleShowMore}>
      <Space className='btn font-semibold'>
        <TbDeviceMobileHeart className='text-2xl' />
        Apka mobilna Inwitki - kliknij!
      </Space>
    </StyledButton>
  );
  const OpenInAppBtn = () => (
    <StyledButton size='large' color={style.color} bgColor={style.bgColor} style={{ width: '100%' }} onClick={handleOpenInApp}>
      <Space className='btn font-semibold'>
        <InveeLogo style={{ height: '24px', width: '15px', color: style.color }} />
        Otwórz inwitkę w aplikacji
      </Space>
    </StyledButton>
  );
  const AppStoreBtn = () => (
    <StyledButton size='large' color={style.color} bgColor={style.bgColor} style={{ width: '100%' }} onClick={handleDownloadAppStore}>
      <AppStoreBadge />
    </StyledButton>
  );
  const PlayStoreBtn = () => (
    <StyledButton size='large' color={style.color} bgColor={style.bgColor} style={{ width: '100%' }} onClick={handleDownloadPlayStore}>
      <PlayStoreBadge />
    </StyledButton>
  );

  return (
    <div className='p-4' style={{ backgroundColor: style.color }}>
      <div>
        {showStores ? (
          <div className='flex flex-col gap-4'>
            {'iOS' === mobileOs ? (
              <>
                <div className={`text-center`} style={{ color: style.bgColor }}>
                  Masz już apkę?
                </div>
                <OpenInAppBtn />
                <div className={`text-center`} style={{ color: style.bgColor }}>
                  lub pobierz apkę ze sklepu:
                </div>
                <AppStoreBtn />
              </>
            ) : 'Android' === mobileOs ? (
              <>
                <div className={`text-center`} style={{ color: style.bgColor }}>
                  Masz już apkę?
                </div>
                <OpenInAppBtn />
                <div className={`text-center`} style={{ color: style.bgColor }}>
                  lub pobierz apkę ze sklepu:
                </div>
                <PlayStoreBtn />
              </>
            ) : (
              <>
                <div className={`text-center`} style={{ color: style.bgColor }}>
                  Masz już apkę?
                </div>
                <OpenInAppBtn />
                <div className={`text-center`} style={{ color: style.bgColor }}>
                  lub pobierz apkę ze sklepu:
                </div>
                <AppStoreBtn />
                <PlayStoreBtn />
              </>
            )}
          </div>
        ) : (
          <>
            <ShowMoreBtn />
          </>
          //   <StyledButton className='scailing-1' size='large' color={style.color} bgColor={style.bgColor} style={{ width: '100%' }} onClick={handleOpenInApp}>
          //     <Space className='btn font-semibold'>
          //       <InveeLogo style={{ height: '24px', width: '15px', color: theme.dark3 }} />
          //       Otwórz w aplikacji Inwitki
          //     </Space>
          //   </StyledButton>
        )}
      </div>
      <div></div>
    </div>
  );
};

MobileAppPrompt.propTypes = {
  inveeString: PropTypes.string,
  style: PropTypes.string,
};

export default MobileAppPrompt;
