import React from 'react';
import PropTypes from 'prop-types';
import useTheme from 'src/hooks/useTheme';

const SelectItem = ({ label, value, className, onSelect }) => {
  const { theme } = useTheme();
  return (
    <div
      style={{ color: theme.dark4 }}
      className={`w-full flex justify-center items-center cursor-pointer border border-solid rounded-md py-2 font-bold shadow-md hover:scale-105`}
      onClick={() => {
        if (!onSelect) return;
        onSelect(value);
      }}
    >
      <span className={className}>{label}</span>
    </div>
  );
};

SelectItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  className: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
};

export default SelectItem;
