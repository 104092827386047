import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getDecodeInveeString } from 'src/api/invee';
import InveeFullView from 'src/components/controls/invee/InveeFullView';
// import ConsentsDrawer from 'src/components/controls/drawers/ConsentsDrawer';
import MobileAppsDrawer from 'src/components/controls/drawers/MobileAppsDrawer';
//import PropTypes from 'prop-types';
import * as Ga4 from 'src/misc/Ga4';
import DisplayConsentsDrawer from 'src/components/controls/drawers/DisplayConsentsDrawer';
import { Helmet } from 'react-helmet';

const InveeReader = () => {
  const { inveeString } = useParams();
  const [decoded, setDecoded] = useState(null);

  useEffect(() => {
    Ga4.pageView('Invee Reader');
    loadInvee();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadInvee = async () => {
    try {
      const resp = await getDecodeInveeString(inveeString);
      setDecoded(resp);
      // window.document.title = `Inwitka - ${resp.guest}`;
      window.document.title = 'Inwitka - Cyfrowe zaproszenie';
    } catch (error) {
      console.error(error);
      window.location.href = '/404';
    }
  };

  if (!decoded) return null;

  return (
    <>
      <Helmet>
        <title>Inwitka - cyfrowe zaproszenie na smartfony</title>
        <meta name='keywords' content={'Inwitka, cyfrowe, e-zaproszenie'} />
        <meta name='description' content='Inwitka - cyfrowe zaproszenie na smartfony' />
      </Helmet>
      <div className='fixed top-0 right-0 bottom-0 left-0 ' style={{ backgroundColor: decoded.invee?.style?.bgColor }}></div>
      <InveeFullView invee={decoded.invee} guest={decoded.guest} viewInvee={true} inveeString={inveeString} />
      <MobileAppsDrawer inveeString={inveeString} style={decoded?.invee?.style} />
      <DisplayConsentsDrawer />
      {/* <ConsentsDrawer /> */}
    </>
  );
};

InveeReader.propTypes = {};

export default InveeReader;
