import React from 'react';
import PropTypes from 'prop-types';
import { FONTS } from 'src/misc/Config';
import SelectItem from 'src/components/controls/misc/SelectItem';

const InveeEditorFont = ({ onSelect }) => {
  return (
    <div className='flex flex-col gap-2 text-xl m-4'>
      {FONTS.map((font) => {
        return <SelectItem label={font.name} value={font} className={font.class} onSelect={onSelect} />;
      })}
    </div>
  );
};

InveeEditorFont.propTypes = {
  defaultTitle: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
};

InveeEditorFont.defaultProps = {};

export default InveeEditorFont;
