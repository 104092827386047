import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { Modal, Button, Switch } from 'antd';

const YesNoDialog = ({ children, title, body, labelNo, labelYes, onNoClick, onYesClick, showConfirmation, lockWidth, lockColor, labelConfirmed, labelUnconfirmed }) => {
  const [showModal, setShowModal] = useState(false);
  const [confirmed, setConfirmed] = useState(false);

  useEffect(() => {
    if (showModal) setConfirmed(!showConfirmation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  const handleChildClick = (e) => {
    if (e.preventDefault) e.preventDefault();
    if (e.stopPropagation) e.stopPropagation();
    setShowModal(true);
  };

  const handleCancel = async () => {
    setShowModal(false);
  };

  const handleYesClick = async () => {
    setShowModal(false);
    if (onYesClick) onYesClick();
  };

  const handleNoClick = async () => {
    setShowModal(false);
    if (onNoClick) onNoClick();
  };

  return (
    <>
      {React.cloneElement(children, { onClick: handleChildClick })}
      {showModal && (
        <Modal
          open={true}
          title={title}
          onCancel={handleCancel}
          footer={[
            <Button key='btnYes' type='primary' onClick={handleYesClick} disabled={!confirmed}>
              {labelYes}
            </Button>,
            <Button key='btnNo' onClick={handleNoClick}>
              {labelNo}
            </Button>,
          ]}
        >
          <div>{body}</div>
          {showConfirmation && (
            <div>
              <Switch
                key='confirm'
                style={{ marginRight: '20px', backgroundColor: lockColor, width: lockWidth }}
                checked={confirmed}
                checkedChildren={labelConfirmed}
                unCheckedChildren={labelUnconfirmed}
                onChange={() => {
                  setConfirmed(!confirmed);
                }}
              />
            </div>
          )}
        </Modal>
      )}
    </>
  );
};

YesNoDialog.propTypes = {
  children: PropTypes.any,
  title: PropTypes.any,
  body: PropTypes.any,
  labelYes: PropTypes.string,
  labelNo: PropTypes.string,
  labelConfirmed: PropTypes.string,
  labelUnconfirmed: PropTypes.string,
  showConfirmation: PropTypes.bool,
  lockWidth: PropTypes.string,
  lockColor: PropTypes.string,
  onYesClick: PropTypes.func,
  onNoClick: PropTypes.func,
  chidren: PropTypes.any,
};

YesNoDialog.defaultProps = {
  title: 'Confirmation',
  body: 'Do you want to continue?',
  labelYes: 'Yes',
  labelNo: 'No',
  labelConfirmed: 'confirmed',
  labelUnconfirmed: 'unconfirmed',
  showConfirmation: false,
  iconYes: 'ion-checkmark-round',
  iconNo: 'ion-close-round',
};

export default YesNoDialog;
