import React, { useEffect, useContext, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import GlobalContext from '../../../context/GlobalContext';
import InveesContext from 'src/context/InveesContext';
import UserControl from 'src/components/controls/userControl/UserControl';
import Layout from 'src/components/layout/Layout';
import TitleBar from 'src/components/layout/TitleBar';
import Content from 'src/components/layout/Content';
import Footer from 'src/components/layout/Footer';
import PropTypes from 'prop-types';
import InveeMobileView from 'src/components/controls/invee/InveeMobileView';
import InveeFullView from 'src/components/controls/invee/InveeFullView';
import { Badge, Button, Drawer, Space } from 'antd';
import { CheckOutlined, CloseOutlined, ZoomInOutlined, LeftOutlined, RightOutlined, WarningOutlined } from '@ant-design/icons';
import WhenDesktop from 'src/components/layout/WhenDesktop';
import { CURRENT_EDITORS, MAX_CONTENT_LENGTH, SCREEN_MODES, SCRUB_HTML_REGEX } from 'src/misc/Config';
import { dateTimeSorter, notEmptyArray } from 'src/misc/Misc';
import { patchInvee } from 'src/api/invee';
import InveeEditorStyle from 'src/components/controls/editors/InveeEditorStyle';
import InveeEditorFont from 'src/components/controls/editors/InveeEditorFont';
import InveeEditorTitle from 'src/components/controls/editors/InveeEditorTitle';
import InveeEditorContent from 'src/components/controls/editors/InveeEditorContents';
import InveeEditorDatesLocations from 'src/components/controls/editors/InveeEditorDatesLocations';
import WhenMobile from 'src/components/layout/WhenMobile';
import LsDb from 'src/misc/LsDb';
import { getInvee } from 'src/api/invee';
// import { ReactComponent as InveeLogo } from 'src/components/svg/invee-logo.svg';
import DarkModeControl from 'src/components/controls/darkModeControl/DarkModeControl';
import StyledButton from 'src/components/styled/StyledButton';
import useTheme from 'src/hooks/useTheme';
// import InveeThumbnail from 'src/components/controls/invee/InveeThumbnail';
import * as Ga4 from 'src/misc/Ga4';
import { useScreen } from 'src/hooks/useScreen';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

const InveeCreate = () => {
  const { inveeId } = useParams();
  const navigate = useNavigate();
  const globalContext = useContext(GlobalContext);
  const inveesContext = useContext(InveesContext);
  const { theme } = useTheme();
  const { isDesktop } = useScreen();

  const breadcrumbRoutes = !inveesContext.currentInvee
    ? [
        {
          title: 'Moje projekty',
          path: '/projects',
        },
        {
          title: <span className='blinking'>...</span>,
        },
      ]
    : [
        {
          title: 'Moje projekty',
          path: '/projects',
        },
        {
          title: (
            <div style={{ maxWidth: '120px' }} className='overflow-hidden truncate text-ellipsis'>
              {inveesContext.currentInvee?.title}
            </div>
          ),
        },
      ];

  const [isZoomed, setIsZoomed] = useState(false);

  useEffect(() => {
    Ga4.pageView('Project / Editing');
    if (inveesContext.currentInvee?.inveeId !== inveeId) {
      inveesContext.setCurrentInvee(null);
      loadCurrentInvee();
    }
    return () => {
      inveesContext.setCurrentEditor(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (inveesContext.currentEditor) {
      Ga4.event('Project Edit', `Edit_${inveesContext?.currentEditor?.id ?? 'unknown'}`, `Edit / ${inveesContext?.currentEditor?.id ?? 'unknown'}`);
    }
  }, [inveesContext.currentEditor]);

  const loadCurrentInvee = async () => {
    await globalContext.executeBlockingProcess(async () => {
      try {
        const resp = await getInvee(inveeId);
        inveesContext.setCurrentInvee(resp);
      } catch (error) {
        console.error(error);
        if (error?.toString()?.includes(404)) {
          navigate('/404');
        }
      }
    });
  };

  //#region "event handlers"

  const handleSelectStyle = () => inveesContext.setCurrentEditor(CURRENT_EDITORS.style);
  const handleSelectFont = () => inveesContext.setCurrentEditor(CURRENT_EDITORS.font);
  const handleUpdateTitle = () => inveesContext.setCurrentEditor(CURRENT_EDITORS.title);
  const handleUpdateContents = () => inveesContext.setCurrentEditor(CURRENT_EDITORS.contents);
  const handleEditDatesLocations = () => inveesContext.setCurrentEditor(CURRENT_EDITORS.datesLocations);

  const handleOnStyleSelect = async (style, tag) => {
    inveesContext.setCurrentEditor(null);
    if (!style) return;
    await globalContext.executeBlockingProcess(async () => {
      try {
        const resp = await patchInvee(inveeId, { style: { ...style, contents: undefined }, tag });
        inveesContext.setCurrentInvee(resp);
        inveesContext.updateInveeList(inveeId, { style }); // invee list doesnt contain the tag
      } catch (error) {
        console.error(error);
      }
    });
  };

  const handleOnFontSelect = async (font) => {
    inveesContext.setCurrentEditor(null);
    if (!font) return;
    await globalContext.executeBlockingProcess(async () => {
      try {
        const resp = await patchInvee(inveeId, { font });
        inveesContext.setCurrentInvee(resp);
        inveesContext.updateInveeList(inveeId, { font });
      } catch (error) {
        console.error(error);
      }
    });
  };

  const handleDrawerOnSave = async () => {
    inveesContext.setCurrentEditor(null);
    if (inveesContext.currentEditorChanges && true === areChangesValidated()) {
      await globalContext.executeBlockingProcess(async () => {
        try {
          if (notEmptyArray(inveesContext.currentEditorChanges.datesLocations)) inveesContext.currentEditorChanges.datesLocations = inveesContext.currentEditorChanges.datesLocations.sort((a, b) => dateTimeSorter(a, b, 'date'));
          const resp = await patchInvee(inveeId, inveesContext.currentEditorChanges);
          inveesContext.setCurrentInvee(resp);
          inveesContext.updateInveeList(inveeId, inveesContext.currentEditorChanges);
          const { title } = inveesContext.currentEditorChanges;
          if (title) LsDb.updateInveeStringList(inveeId, title);
        } catch (error) {
          console.error(error);
        }
      });
    }
    inveesContext.setCurrentEditorChanges(null);
  };

  const handleDrawerOnCancel = () => {
    inveesContext.setCurrentEditorChanges(null);
    inveesContext.setCurrentEditor(null);
  };

  const handleZoomIn = () => {
    Ga4.event('Project Edit', 'Zoom_in', 'Zoom_in');
    setIsZoomed(true);
  };

  const handleInvite = () => {
    navigate(`/projects/${inveeId}/invite`);
  };

  //#endregion

  //#region "misc"

  const areChangesValidated = () => {
    if (inveesContext.currentEditorChanges?.title?.trim() === '') return false;
    if (inveesContext.currentEditorChanges?.contents?.content1.replace(SCRUB_HTML_REGEX, '').length > MAX_CONTENT_LENGTH) return false;
    if (inveesContext.currentEditorChanges?.contents?.content2.replace(SCRUB_HTML_REGEX, '').length > MAX_CONTENT_LENGTH) return false;
    if (inveesContext.currentEditorChanges?.contents?.content1?.trim().length > 1.3 * MAX_CONTENT_LENGTH) return false;
    if (inveesContext.currentEditorChanges?.contents?.content2?.trim().length > 1.3 * MAX_CONTENT_LENGTH) return false;
    return true;
  };

  const addLocAndDatesRibbon = (button) => {
    if (inveesContext.currentInvee?.datesLocations?.length) return button;
    return (
      <Badge.Ribbon
        text={
          <Space>
            <WarningOutlined />
            Nie dodano
          </Space>
        }
        style={{ marginTop: '-20px' }}
        color='magenta'
      >
        {button}
      </Badge.Ribbon>
    );
  };

  //#endregion

  return (
    <>
      <Drawer
        extra={
          <Space>
            {[CURRENT_EDITORS.title.id, CURRENT_EDITORS.contents.id, CURRENT_EDITORS.datesLocations.id].includes(inveesContext.currentEditor?.id) && (
              <Button type='primary' onClick={handleDrawerOnSave} disabled={!inveesContext.currentEditorChanges || false === areChangesValidated()}>
                <Space>
                  <CheckOutlined />
                  <WhenDesktop>Zapisz</WhenDesktop>
                </Space>
              </Button>
            )}

            <Button onClick={handleDrawerOnCancel}>
              <Space>
                <CloseOutlined />
                <WhenDesktop>Anuluj</WhenDesktop>
              </Space>
            </Button>
          </Space>
        }
        closeIcon={false}
        maskClosable={false}
        open={inveesContext.currentEditor !== null}
        title={
          <div className='flex flex-row items-center gap-2'>
            {inveesContext.currentEditor?.icon}
            {inveesContext.currentEditor?.label}
          </div>
        }
        width={globalContext.screenMode === SCREEN_MODES.mobile ? '100%' : '600px'}
        styles={{ body: { padding: '12px' } }}
      >
        {inveesContext.currentEditor?.id === CURRENT_EDITORS.style.id && <InveeEditorStyle onSelect={handleOnStyleSelect} />}
        {inveesContext.currentEditor?.id === CURRENT_EDITORS.font.id && <InveeEditorFont onSelect={handleOnFontSelect} />}
        {inveesContext.currentEditor?.id === CURRENT_EDITORS.title.id && <InveeEditorTitle defaultTitle={inveesContext.currentInvee?.title ?? ''} />}
        {inveesContext.currentEditor?.id === CURRENT_EDITORS.contents.id && <InveeEditorContent defaultContent1={inveesContext.currentInvee?.contents?.content1 ?? ''} defaultContent2={inveesContext.currentInvee?.contents?.content2 ?? ''} />}
        {inveesContext.currentEditor?.id === CURRENT_EDITORS.datesLocations.id && <InveeEditorDatesLocations defaultDatesLocations={inveesContext.currentInvee?.datesLocations ?? []} />}
      </Drawer>

      {isZoomed && <InveeFullView invee={inveesContext.currentInvee} onClose={() => setIsZoomed(false)} />}

      <Layout
        header={
          <TitleBar
            path='/projects'
            isLoading={globalContext.isBusy}
            leftExtras={
              <>
                <StyledButton color={theme.whiteButton} onClick={() => navigate('/projects')}>
                  <Space>
                    <LeftOutlined />
                    <WhenDesktop>Moje projekty</WhenDesktop>
                  </Space>
                </StyledButton>
                <StyledButton color={theme.whiteButton} onClick={() => navigate(`/projects/${inveeId}/invite`)}>
                  <Space>
                    <WhenDesktop>Zapraszaj gości</WhenDesktop>
                    <RightOutlined />
                  </Space>
                </StyledButton>
              </>
            }
            rightExtras={
              <Space>
                <DarkModeControl />
                <UserControl />
              </Space>
            }
          ></TitleBar>
        }
        footer={<Footer />}
      >
        <Content breadcrumbRoutes={breadcrumbRoutes}>
          {inveesContext.currentInvee && (
            <>
              <div className='w-full flex flex-row justify-center'>
                <div className='flex flex-col'>
                  <div className='flex flex-row'>
                    <div className='overflow-hidden'>
                      <div className='px-0 py-0 h-16 lg:h-8'>
                        <div className={`flex ${isDesktop ? 'flex-row' : 'flex-col'} flex-wrap justify-center gap-2`}>
                          <StyledButton color={theme.dark4} size='small' onClick={handleZoomIn}>
                            <Space>
                              <ZoomInOutlined />
                              <WhenMobile>Powiększ</WhenMobile>
                              <WhenDesktop>Podgląd</WhenDesktop>
                            </Space>
                          </StyledButton>
                          <StyledButton color={theme.dark4} size='small' onClick={handleInvite}>
                            <Space className='btn'>
                              {/* <InveeLogo style={{ height: '16px', width: '10px' }} /> */}
                              Zapraszaj gości
                              <RightOutlined />
                            </Space>
                          </StyledButton>
                        </div>
                      </div>
                      <InveeMobileView invee={inveesContext.currentInvee} />
                      <div className={`px-0 py-2 h-16 lg:h-8 flex flex-col text-xs ${isDesktop && 'items-center'}`} style={{ color: theme.dark4 }}>
                        <div className='flex flex-col gap-0 lg:flex-row lg:gap-1 opacity-60'>
                          <div className='font-extrabold'>Stworzono {dayjs(inveesContext.currentInvee?.createdAt).fromNow()}:</div>
                          <div className='font-extralight'>{dayjs(inveesContext.currentInvee?.createdAt).format('LL')}</div>
                        </div>
                        <div className='flex flex-col gap-0 lg:flex-row lg:gap-1 opacity-60'>
                          <div className='font-extrabold'>Wygaśnie {dayjs(inveesContext.currentInvee?.expiryDate).fromNow()}:</div>
                          <div className='font-extralight'>{dayjs(inveesContext.currentInvee?.expiryDate).format('LL')}</div>
                        </div>
                      </div>
                    </div>
                    <div className='flex-auto'>
                      <div className='h-full w-full flex flex-col justify-center'>
                        <div className='h-[308px] flex flex-col gap-2 justify-center'>
                          <div className='flex-1 flex flex-row'>
                            <div className='p-2 lg:p-4 flex flex-col gap-2 justify-center'>
                              <StyledButton color={theme.dark4} size='large' className='w-[180px]' onClick={handleSelectStyle}>
                                <Space className='btn'>
                                  {CURRENT_EDITORS.style.icon}
                                  {CURRENT_EDITORS.style.label}
                                </Space>
                              </StyledButton>
                            </div>
                          </div>

                          <div className='flex-1 flex flex-row'>
                            <div className='p-2 lg:p-4 flex flex-col gap-2 justify-center'>
                              <StyledButton color={theme.dark4} size='large' className='w-[180px] ' onClick={handleSelectFont}>
                                <Space className='btn'>
                                  {CURRENT_EDITORS.font.icon}
                                  {CURRENT_EDITORS.font.label}
                                </Space>
                              </StyledButton>
                            </div>
                          </div>

                          <div className='flex-1 flex flex-row'>
                            <div className='p-2 lg:p-4 flex flex-col gap-2 justify-center'>
                              <StyledButton color={theme.dark4} size='large' className='w-[180px] ' onClick={handleUpdateTitle}>
                                <Space className='btn'>
                                  {CURRENT_EDITORS.title.icon}
                                  {CURRENT_EDITORS.title.label}
                                </Space>
                              </StyledButton>
                            </div>
                          </div>

                          <div className='flex-1 flex flex-row'>
                            <div className='p-2 lg:p-4 flex flex-col gap-2 justify-center'>
                              <StyledButton color={theme.dark4} size='large' className='w-[180px] ' onClick={handleUpdateContents}>
                                <Space className='btn'>
                                  {CURRENT_EDITORS.contents.icon}
                                  {CURRENT_EDITORS.contents.label}
                                </Space>
                              </StyledButton>
                            </div>
                          </div>

                          <div className='flex-1 flex flex-row'>
                            <div className='p-2 lg:p-4 flex flex-col gap-2 justify-center'>
                              {addLocAndDatesRibbon(
                                <StyledButton color={theme.dark4} size='large' className='w-[180px] ' onClick={handleEditDatesLocations}>
                                  <Space className='btn'>
                                    {CURRENT_EDITORS.datesLocations.icon}
                                    {CURRENT_EDITORS.datesLocations.label}
                                  </Space>
                                </StyledButton>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* {window.location.hostname === 'localhost' && <InveeThumbnail invee={inveesContext.currentInvee} />} */}
            </>
          )}
        </Content>
      </Layout>
    </>
  );
};

InveeCreate.propTypes = {
  content: PropTypes.any,
};

export default InveeCreate;
