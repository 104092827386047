import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import GlobalContext from 'src/context/GlobalContext';
import { Result, Button } from 'antd';
import Layout from 'src/components/layout/Layout';
import TitleBar from 'src/components/layout/TitleBar';
import Content from 'src/components/layout/Content';
import Footer from 'src/components/layout/Footer';
import * as Ga4 from 'src/misc/Ga4';

const Page404 = () => {
  const navigate = useNavigate();
  const globalContext = useContext(GlobalContext);

  useEffect(() => {
    Ga4.pageView('404');
  }, []);

  return (
    <Layout header={<TitleBar path={globalContext.userInfo ? '/projects' : '/'}></TitleBar>} footer={<Footer />}>
      <Content>
        <Result
          status='404'
          title='Ups'
          subTitle='wygląda na to, że tu nic nie ma :-)'
          extra={
            <Button
              type='primary'
              onClick={() => {
                if (globalContext.userInfo) {
                  navigate('/projects');
                  return;
                }
                navigate('/');
              }}
            >
              Wróć do strony głównej
            </Button>
          }
        />
        ;
      </Content>
    </Layout>
  );
};

Page404.propTypes = {};

export default Page404;
