import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import GlobalContext from 'src/context/GlobalContext';
import InveesContext from 'src/context/InveesContext';
import Layout from 'src/components/layout/Layout';
import TitleBar from 'src/components/layout/TitleBar';
import Footer from 'src/components/layout/Footer';
import Content from 'src/components/layout/Content';
import UserControl from 'src/components/controls/userControl/UserControl';
import { Space, Select, notification } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import WhenDesktop from 'src/components/layout/WhenDesktop';
import InveeStyles, { getAllTags, inveeContents } from 'src/misc/InveeStyles';
import { postInvee, getInvees } from 'src/api/invee';
import SelectStyleItem from './SelectStyleItem';
import SelectStyleTagItem from './SelectStyleTagItem';
import { FONTS } from 'src/misc/Config';
import StyledButton from 'src/components/styled/StyledButton';
import DarkModeControl from 'src/components/controls/darkModeControl/DarkModeControl';
import useTheme from 'src/hooks/useTheme';
import * as Ga4 from 'src/misc/Ga4';
//import PropTypes from 'prop-types';

const StyledSelect = styled(Select)`
  .ant-select-selector {
    box-shadow: none !important;
    height: 36px !important;
    text-align: center !important;
  }

  .ant-select-selection-item {
    padding-left: 18px !important;
    font-weight: 600 !important;
    font-size: 1rem !important;
    font-family: 'Roboto', sans-serif;
  }
`;

const SelectStyle = () => {
  const globalContext = useContext(GlobalContext);
  const inveesContext = useContext(InveesContext);
  const navigate = useNavigate();
  const { theme } = useTheme();

  const breadcrumbRoutes = [
    {
      title: 'Moje projekty',
      path: '/projects',
    },
    {
      title: 'Stwórz nowy projekt',
    },
  ];

  const [selectedTag, setSelectedTag] = useState(null);

  useEffect(() => {
    Ga4.pageView('New Project / Select Style');
  }, []);

  const handleTagsOnChange = (value) => {
    //console.log(`selected ${value}`);
    setSelectedTag(value);
  };

  const createInveeItem = async (style) => {
    let resp = null;
    await globalContext.executeBlockingProcess(async () => {
      try {
        inveesContext.setCurrentInvee(null);
        const { title, contents } = inveeContents[style.contents];
        resp = await postInvee({ title, style: { ...style, contents: undefined }, tag: selectedTag, font: FONTS[0], contents, datesLocations: [] });
        if (!resp?.inveeId) return;
        const resp1 = await getInvees();
        await inveesContext.setInveeList(resp1);
      } catch (error) {
        console.error(error);
        if (error?.toString()?.includes(507)) {
          notification.warning({
            message: 'Co za dużo, to nie zdrowo :-)',
            description: <>Usuń niepotrzebne projekty i spróbuj ponownie.</>,
            duration: 5,
          });
        }
      }
    });
    return resp;
  };

  const handleCreateProject = async (style) => {
    Ga4.event('New Project / Select Style', 'Create_new_project', 'Create_new_project');
    const newInvee = await createInveeItem(style);
    if (!newInvee?.inveeId) return;
    notification.success({
      message: 'Stworzono nowy projekt.',
      duration: 5,
    });
    navigate(`/projects/${newInvee?.inveeId}/create`);
  };

  return (
    <Layout
      header={
        <TitleBar
          path='/'
          isLoading={globalContext.isBusy}
          leftExtras={
            <>
              <StyledButton color={theme.whiteButton} onClick={() => navigate('/projects')}>
                <Space>
                  <LeftOutlined />
                  <WhenDesktop>Moje projekty</WhenDesktop>
                </Space>
              </StyledButton>
            </>
          }
          rightExtras={
            <Space>
              <DarkModeControl />
              <UserControl />
            </Space>
          }
        />
      }
      footer={<Footer />}
    >
      <Content breadcrumbRoutes={breadcrumbRoutes}>
        <div className='mt-4 w-full flex flex-col items-center'>
          {!selectedTag && (
            <div className='max-w-[500px] w-full'>
              <div>
                {/* <div className='mb-2 font-bold text-center'>Na jaką okazję?</div> */}
                <div className='flex flex-col gap-2'>
                  {getAllTags().map((x) => (
                    <SelectStyleTagItem tag={x} onSelect={setSelectedTag} />
                  ))}
                </div>
              </div>
            </div>
          )}

          {selectedTag && (
            <div className='w-full'>
              <div className='w-full flex flex-col items-center'>
                <div className='max-w-[500px] w-full r'>
                  {/* <div className='mb-2 font-bold text-center'>Na jaką okazję?</div> */}
                  <StyledSelect dropdownStyle={{ textAlign: 'center', fontFamily: "'Roboto', sans-serif" }} className='shadow-md' mode='single' showSearch={false} style={{ width: '100%' }} placeholder='wybierz okazję...' defaultValue={selectedTag} onChange={handleTagsOnChange} options={getAllTags()} />
                </div>
              </div>
              {/* <div className='mt-8 mb-2 font-bold text-center'>Wybierz styl</div> */}
              <div className='mt-8 flex flex-row flex-wrap gap-4 justify-center'>
                {InveeStyles.filter((x) => x.depricated !== true && x.tags?.includes(selectedTag)).map((x) => (
                  <SelectStyleItem style={x} onSelect={handleCreateProject} />
                ))}
              </div>
            </div>
          )}
        </div>
      </Content>
    </Layout>
  );
};

export default SelectStyle;
