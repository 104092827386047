export const GA_TRACKING_ID = 'G-CM2ZSL2XT8';

// export const initializeGA = () => {
//   try {
//     window.dataLayer = window.dataLayer || [];
//     function gtag() {
//       window.dataLayer.push(arguments);
//     }

//     gtag('consent', 'default', {
//       ad_storage: 'denied',
//       ad_user_data: 'denied',
//       ad_personalization: 'denied',
//       analytics_storage: 'denied',
//       wait_for_update: 2000,
//     });

//     gtag('js', new Date());
//     gtag('config', 'G-CM2ZSL2XT8', {
//       send_page_view: false,
//       debug_mode: window.location.hostname === 'localhost',
//     });
//   } catch (error) {
//     console.error(error);
//   }
// };

export const updateConsents = (analytics_storage = 'denied') => {
  try {
    if (analytics_storage !== 'granted') clearAllCookies();
    if (window.gtag) {
      window.gtag('consent', 'update', {
        ad_storage: 'denied',
        ad_user_data: 'denied',
        ad_personalization: 'denied',
        analytics_storage,
      });
    }
  } catch (error) {
    console.error(error);
  }
};

export const clearAllCookies = () => {
  try {
    var cookies = document.cookie.split('; ');
    for (var c = 0; c < cookies.length; c++) {
      var d = window.location.hostname.split('.');
      while (d.length > 0) {
        var cookieBase = encodeURIComponent(cookies[c].split(';')[0].split('=')[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path=';
        var p = window.location.pathname.split('/');
        document.cookie = cookieBase + '/';
        while (p.length > 0) {
          document.cookie = cookieBase + p.join('/');
          p.pop();
        }
        d.shift();
      }
    }
  } catch (error) {
    console.error(error);
  }
};

export const pageView = (page_title) => {
  try {
    if (window.gtag) {
      window.gtag('event', 'page_view', {
        page_title,
        page_location: window.location.pathname,
      });
    }
  } catch (error) {
    console.error(error);
  }
};

export const event = (category, action, label) => {
  try {
    if (window.gtag) {
      window.gtag('event', action, {
        event_category: category,
        event_label: label,
      });
    }
  } catch (error) {
    console.error(error);
  }
};

export const exception = (description, fatal = false) => {
  try {
    if (window.gtag) {
      window.gtag('event', 'exception', {
        description,
        fatal,
      });
    }
  } catch (error) {
    console.error(error);
  }
};
