import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import LsDb from 'src/misc/LsDb';
import { notArrayOrEmpty, notEmptyArray } from 'src/misc/Misc';
import InveeStringItem from './InveeStringItem';
import { Card, Input, notification, Alert, Space, Button } from 'antd';
import WhenDesktop from 'src/components/layout/WhenDesktop';
import { MAX_STORED_INVEE_ITEMS } from 'src/misc/Config';
import { DeleteOutlined, WarningOutlined } from '@ant-design/icons';
import YesNoDialog from 'src/components/layout/YesNoDialog';

const InveeStringList = ({ forceRerender, scrollToInveeListRef, hideWhenEmpty }) => {
  const [inveeStrings, setInveeStrings] = useState(null);
  const [inveeStringsFiltered, setInveeStringsFiltered] = useState(null);
  const [searchedPhrase, setSearchedPhrase] = useState(null);

  useEffect(() => {
    const db = LsDb.getInveeStringItems();
    setInveeStrings(db);
  }, [forceRerender]);

  useEffect(() => {
    if (null === searchedPhrase) {
      setInveeStringsFiltered(inveeStrings);
      return;
    }
    const filtered = inveeStrings.filter((x) => x.title?.toLowerCase().includes(searchedPhrase?.toLowerCase()) || x.guest?.toLowerCase().includes(searchedPhrase?.toLowerCase()));
    setInveeStringsFiltered(filtered);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedPhrase]);

  // if (true === notArrayOrEmpty(inveeStrings)) return null;

  const handleOnDelete = (id) => {
    const db = LsDb.dropInveeStringItem(id);
    setInveeStrings(db);
    notification.success({
      message: 'Usunięto inwitkę.',
      duration: 5,
    });
  };

  const handleDeleteAll = () => {
    const db = LsDb.dropInveeStringAllItems();
    setInveeStrings(db);
    notification.success({
      message: 'Usunięto wszystkie inwitki.',
      duration: 5,
    });
  };

  return (
    <Card
      className={hideWhenEmpty && true === notArrayOrEmpty(inveeStrings) && 'hidden'}
      title={
        <>
          <div>
            Ostatnie inwitki
            <span className='font-light ml-2 text-sm'>
              ({true === notArrayOrEmpty(inveeStrings) ? 0 : inveeStrings.length} z {MAX_STORED_INVEE_ITEMS})
            </span>
          </div>
          <div className='text-xs font-light'>wygenerowane na tym urządzeniu</div>
        </>
      }
      extra={
        <Space>
          <YesNoDialog
            // showConfirmation
            // labelUnconfirmed='kliknij aby potwierdzić'
            // labelConfirmed='potwierdzam'
            onYesClick={handleDeleteAll}
            title='Usuń wszystkie inwitki'
            body={
              <>
                <div className='mb-4'>Czy na pewno chcesz trwale usunąć wszystkie inwitki z listy?</div>
                <div className=''>
                  <WarningOutlined className='mr-2 text-red-600' />
                  Jeśli nie pobrałeś jeszcze linków lub kodów QR, nie będzie można ich odzyskać.
                </div>
                <div className=''>
                  <WarningOutlined className='mr-2 text-red-600' />
                  Jeśli pobrałeś już linki lub kody QR, będą one dalej działać.
                </div>
                <div className='mb-4'>
                  <WarningOutlined className='mr-2 text-red-600' />
                  Tej operacji nie można cofnąć.
                </div>
              </>
            }
            labelYes='Rozumiem, usuń'
            labelNo='Anuluj'
          >
            <Button>
              <Space className='btn'>
                <DeleteOutlined />
                <WhenDesktop>Usuń wszystkie</WhenDesktop>
              </Space>
            </Button>
          </YesNoDialog>
          <WhenDesktop>
            <Input
              autoComplete='off'
              style={{ maxWidth: '360px' }}
              placeholder='szukaj...'
              allowClear
              value={searchedPhrase}
              onChange={(x) => {
                setSearchedPhrase(x.target.value === '' ? null : x.target.value);
              }}
            />
          </WhenDesktop>
        </Space>
      }
    >
      <Alert
        closable
        message={
          <>
            Pamiętaj, że serwis inwitki<span className='opacity-60'>.net</span> <strong>nie przechowuje imion Twoich gości.</strong>
          </>
        }
        description={
          <>
            Imiona są zaszyfrowane w linkach i w kodach QR wygenerowanych inwitek. Przeglądarka internetowa pamięta tylko kilka ostatnich inwitek, ale mogą one zniknąć np. poprzez wygenerowanie kolejnych inwitek lub wyczyszczenie danych przeglądania. Po wygenerowaniu inwitki, żeby jej nie stracić, <strong>od razu pobierz link lub kod QR na swój dysk.</strong>
          </>
        }
        type='info'
        showIcon
      />
      <div ref={scrollToInveeListRef} id='scroll-to-invee-list' />
      <div className='mt-4 w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8'>{true === notEmptyArray(inveeStrings) && (inveeStringsFiltered ?? inveeStrings).map((item, index) => <InveeStringItem key={index} item={item} onDelete={handleOnDelete} searchedPhrase={searchedPhrase} />)}</div>
    </Card>
  );
};

InveeStringList.propTypes = {
  forceRerender: PropTypes.bool,
  scrollToInveeListRef: PropTypes.any,
  hideWhenEmpty: PropTypes.bool,
};

export default InveeStringList;
