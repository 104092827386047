import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useTheme from 'src/hooks/useTheme';
import GlobalContext from 'src/context/GlobalContext';
import InveesContext from 'src/context/InveesContext';
import UserControl from 'src/components/controls/userControl/UserControl';
import Layout from 'src/components/layout/Layout';
import TitleBar from 'src/components/layout/TitleBar';
import Content from 'src/components/layout/Content';
import Footer from 'src/components/layout/Footer';
import { Button, Card, Statistic, Space, Checkbox, Alert, notification } from 'antd';
import { GiTwoCoins } from 'react-icons/gi';
import { getTokens, postTokensGetForFree } from 'src/api/invee';
import DarkModeControl from 'src/components/controls/darkModeControl/DarkModeControl';
import StyledLink from 'src/components/styled/StyledLink';
import { dateTimeSorter } from 'src/misc/Misc';
import * as Ga4 from 'src/misc/Ga4';

import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import 'dayjs/locale/pl';

dayjs.extend(isoWeek);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
dayjs.locale('pl');

const TokensGetForFree = () => {
  const globalContext = useContext(GlobalContext);
  const inveesContext = useContext(InveesContext);
  const { theme } = useTheme();

  const navigate = useNavigate();

  const breadcrumbRoutes = [
    {
      title: 'Moje projekty',
      path: '/projects',
    },
    {
      title: 'Kredyty',
      path: '/account/tokens',
    },
    {
      title: 'Otrzymaj za darmo',
    },
  ];

  const [consent, setConsent] = useState(false);

  useEffect(() => {
    Ga4.pageView('Tokens / Get For Free');
    if (inveesContext.tokens === null || inveesContext.tokensDetails === null) {
      loadTokensDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadTokensDetails = async () => {
    await globalContext.executeBlockingProcess(async () => {
      try {
        const response = await getTokens(true);
        inveesContext.setTokens(response?.tokens ?? 0);
        inveesContext.setTokensDetails(response?.results?.sort((a, b) => dateTimeSorter(b, a, 'createdAt')) ?? []);
      } catch (error) {
        console.error(error);
      }
    });
  };

  const handleGetFreeTokens = async () => {
    await globalContext.executeBlockingProcess(async () => {
      try {
        await postTokensGetForFree(true);
        inveesContext.setTokens(null);
        inveesContext.setTokensDetails(null);
        Ga4.event('Tokens', 'Get_free_tokens', 'Get_free_tokens');
        notification.success({
          message: 'Sukces',
          description: 'Dodano darmowe kredyty.',
          duration: 5,
        });
        navigate('/account/tokens');
      } catch (error) {
        console.error(error);
      }
    });
  };

  return (
    <>
      <Layout
        header={
          <TitleBar
            path='/projects'
            isLoading={globalContext.isBusy}
            rightExtras={
              <Space>
                <DarkModeControl />
                <UserControl />
              </Space>
            }
          ></TitleBar>
        }
        footer={<Footer />}
      >
        <Content breadcrumbRoutes={breadcrumbRoutes} title={'Otrzymaj za darmo'}>
          {inveesContext.tokens !== null && (
            <>
              <Card>
                <div>
                  <Alert message='Informacja' description={<>Dzięki kredytom możesz generować inwitki. Jeden kredyt wymieniasz na jedną imienną inwitkę. Niewykorzystane kredyty przepadają bezpowrotnie po 3 miesiącach od daty zakupu.</>} type='info' showIcon closable />

                  <div className='mt-8 text-center'>
                    Jeśli suma dostępnych kredytów jest równa zero,
                    <br />
                    możesz całkowicie <span style={{ color: theme.primary, fontWeight: 800 }}>ZA DARMO</span> otrzymać 20 nowych kredytów.
                    <br />
                    Korzystaj z okresu promocji. Zapraszaj gości. Wysyłaj inwitki.
                  </div>

                  <div className='mt-4 flex flex-row justify-center'>
                    <div className='w-64 text-center'>
                      <Statistic title='Suma dostępnych kredytów' value={inveesContext.tokens} prefix={<GiTwoCoins style={{ color: '#ff8d23' }} />} />
                    </div>
                  </div>

                  {inveesContext.tokens === 0 && (
                    <>
                      <div className='mt-8 text-center'>
                        <div className='my-4 flex flex-row justify-center items-start gap-2 cursor-pointer' onClick={() => setConsent(!consent)}>
                          <Checkbox
                            // style={{ flexDirection: 'column', gap: 4 }}
                            checked={consent}
                            size={'large'}
                            onChange={() => {
                              setConsent(!consent);
                            }}
                          ></Checkbox>
                          <div>
                            Zapoznałem się z{' '}
                            <StyledLink href='/pages/regulations' target='_blank' theme={theme}>
                              regulaminem
                            </StyledLink>{' '}
                            oraz{' '}
                            <StyledLink href='/pages/privacy-policy' target='_blank' theme={theme}>
                              polityką prywatności
                            </StyledLink>
                            <br />i akceptuję ich postanowienia.
                          </div>
                        </div>
                      </div>
                      <div className='mt-4 text-center'>
                        <Button disabled={!consent} loading={globalContext.isBusy} type='primary' onClick={handleGetFreeTokens}>
                          Otrzymaj kredyty za darmo
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </Card>
            </>
          )}
        </Content>
      </Layout>
    </>
  );
};

TokensGetForFree.propTypes = {};

export default TokensGetForFree;
