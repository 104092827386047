import React, { useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import { ConfigProvider, theme as themeAntd } from 'antd';
import plPL from 'antd/locale/pl_PL';
import useTheme from 'src/hooks/useTheme';

const StylesWrapper = styled.div`
  .font-weight-logo {
    font-weight: 600;
  }

  color: ${(props) => props.theme.dark4};
`;

const PageThemeProvider = ({ children }) => {
  const { isDarkMode, theme } = useTheme();

  useEffect(() => {
    // console.log('PageThemeProvider()', { isDarkMode });
    // let body = document.getElementsByTagName('body')[0];
    // body.style.backgroundColor = theme.backgroundBase;

    if (isDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDarkMode]);

  return (
    <ConfigProvider
      locale={plPL}
      theme={{
        algorithm: isDarkMode ? themeAntd.darkAlgorithm : themeAntd.defaultAlgorithm,
        token: {
          colorPrimary: theme.primary,
          colorTextBase: theme.dark4,
          controlItemBgActive: theme.light2,
          fontFamily: 'Ubuntu, sans-serif',
        },
        components: {
          Card: {
            headerBg: theme.light2,
          },

          Carousel: {
            dotHeight: 8,
          },
        },
      }}
    >
      <StylesWrapper theme={theme}>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </StylesWrapper>
    </ConfigProvider>
  );
};

PageThemeProvider.propTypes = {
  children: PropTypes.any,
};

export default PageThemeProvider;
