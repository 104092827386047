import { LOCAL_STORAGE_KEYS, MAX_STORED_INVEE_ITEMS } from './Config';
import { notArrayOrEmpty } from './Misc';

export const setInveeStringItem = (item) => {
  let db = [];
  let dbString = localStorage.getItem(LOCAL_STORAGE_KEYS.inveeStringsDatabase);
  if (dbString) db = JSON.parse(dbString);
  db.unshift(item);
  db = db.slice(0, MAX_STORED_INVEE_ITEMS);
  dbString = JSON.stringify(db);
  localStorage.setItem(LOCAL_STORAGE_KEYS.inveeStringsDatabase, dbString);
  return db;
};

export const dropInveeStringItem = (id) => {
  let db = [];
  let dbString = localStorage.getItem(LOCAL_STORAGE_KEYS.inveeStringsDatabase);
  if (dbString) db = JSON.parse(dbString);
  db = db.filter((item) => item.id !== id);
  dbString = JSON.stringify(db);
  localStorage.setItem(LOCAL_STORAGE_KEYS.inveeStringsDatabase, dbString);
  return db;
};

export const dropInveeStringItems = (inveeId) => {
  let db = [];
  let dbString = localStorage.getItem(LOCAL_STORAGE_KEYS.inveeStringsDatabase);
  if (dbString) db = JSON.parse(dbString);
  db = db.filter((item) => item.inveeId !== inveeId);
  dbString = JSON.stringify(db);
  localStorage.setItem(LOCAL_STORAGE_KEYS.inveeStringsDatabase, dbString);
  return db;
};

export const dropInveeStringAllItems = () => {
  localStorage.removeItem(LOCAL_STORAGE_KEYS.inveeStringsDatabase);
  return null;
};

export const getInveeStringItems = (inveeId) => {
  let db = [];
  let dbString = localStorage.getItem(LOCAL_STORAGE_KEYS.inveeStringsDatabase);
  if (dbString) db = JSON.parse(dbString);
  if (inveeId) db = db.filter((item) => item.inveeId === inveeId);
  return db;
};

export const updateInveeStringList = (inveeId, title) => {
  let dbString = localStorage.getItem(LOCAL_STORAGE_KEYS.inveeStringsDatabase);
  if (!dbString) return;
  let db = JSON.parse(dbString);
  if (notArrayOrEmpty(db)) return;
  db = db.map((item) => {
    if (item.inveeId === inveeId) return { ...item, title };
    return item;
  });
  dbString = JSON.stringify(db);
  localStorage.setItem(LOCAL_STORAGE_KEYS.inveeStringsDatabase, dbString);
};

const LsDb = {
  setInveeStringItem,
  dropInveeStringItem,
  dropInveeStringItems,
  dropInveeStringAllItems,
  getInveeStringItems,
  updateInveeStringList,
};

export default LsDb;
