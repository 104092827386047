import React, { useContext, useEffect } from 'react';
import GlobalContext from 'src/context/GlobalContext';
import Layout from 'src/components/layout/Layout';
import TitleBar from 'src/components/layout/TitleBar';
import Footer from 'src/components/layout/Footer';
import Content from 'src/components/layout/Content';
import UserControl from 'src/components/controls/userControl/UserControl';
import DarkModeControl from 'src/components/controls/darkModeControl/DarkModeControl';
import { Space } from 'antd';
import StyledButton from 'src/components/styled/StyledButton';
import { useLocation, useNavigate } from 'react-router-dom';
import useTheme from 'src/hooks/useTheme';
import { LeftOutlined } from '@ant-design/icons';
import WhenDesktop from 'src/components/layout/WhenDesktop';
import { ReactComponent as SocialNetwork } from 'src/components/svg/contact-social-network.svg';
import { FaFacebookMessenger, FaFacebook, FaYoutube } from 'react-icons/fa';
import * as Ga4 from 'src/misc/Ga4';
import { IoChatboxEllipsesOutline } from 'react-icons/io5';

const Contact = () => {
  const globalContext = useContext(GlobalContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { theme } = useTheme();

  const breadcrumbRoutes = globalContext.userInfo
    ? [
        {
          title: 'Moje projekty',
          path: '/projects',
        },
        {
          title: 'Kontakt',
        },
      ]
    : [
        {
          title: 'Kontakt',
        },
      ];

  useEffect(() => {
    Ga4.pageView('Contact');
  }, []);

  return (
    <Layout
      header={
        <TitleBar
          path='/'
          isLoading={globalContext.isBusy}
          leftExtras={
            <>
              {location.key !== 'default' && (
                <StyledButton color={theme.whiteButton} bgColor='transparent' onClick={() => navigate(-1)}>
                  <Space>
                    <LeftOutlined />
                    <WhenDesktop>Cofnij</WhenDesktop>
                  </Space>
                </StyledButton>
              )}
            </>
          }
          rightExtras={
            <Space>
              <DarkModeControl />
              <UserControl />
            </Space>
          }
        />
      }
      footer={<Footer />}
    >
      <Content breadcrumbRoutes={breadcrumbRoutes}>
        <div className='mt-4 lg:mt-24 flex flex-col lg:flex-row gap-8 justify-center' style={{ color: theme.dark3 }}>
          <div className='flex flex-row justify-center'>
            <SocialNetwork className='h-48 w-64' />
          </div>
          <div className='flex flex-col gap-4 justify-center lg:w-[500px]'>
            <div className='text-2xl text-center lg:text-left'>Skontaktuj się z nami przez social media</div>
            <div className='text-4xl text-center lg:text-left'>
              <Space className='gap-4'>
                <FaFacebookMessenger
                  className='hover:scale-110 cursor-pointer text-purple-600'
                  onClick={() => {
                    Ga4.event('Contact', 'Go_to_messenger', 'Go_to messenger');
                    window.open('https://www.facebook.com/inwitki.net/', '_blank');
                  }}
                />
                <FaFacebook
                  className='hover:scale-110 cursor-pointer text-blue-600'
                  onClick={() => {
                    Ga4.event('Contact', 'Go_to_facebook', 'Go_to facebook');
                    window.open('https://www.facebook.com/inwitki.net/', '_blank');
                  }}
                />
                <FaYoutube
                  className=' hover:scale-110 cursor-pointer text-red-600'
                  onClick={() => {
                    Ga4.event('Contact', 'Go_to_youtube', 'Go_to youtube');
                    window.open('https://www.youtube.com/@inwitki', '_blank');
                  }}
                />
              </Space>
            </div>
            <div className='mt-8 text-2xl text-center lg:text-left'>Skorzystaj z formularza kontaktowego</div>
            <div className='flex flex-row justify-center lg:justify-start'>
              <StyledButton color={theme.dark3} onClick={() => navigate('/pages/contact/form')}>
                <Space className='btn'>
                  <IoChatboxEllipsesOutline />
                  Formularz kontaktowy
                </Space>
              </StyledButton>
            </div>
          </div>
        </div>
      </Content>
    </Layout>
  );
};

// Contact.propTypes = {};

export default Contact;
