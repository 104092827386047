import { useContext } from 'react';
import PropTypes from 'prop-types';
import GlobalContext from '../context/GlobalContext';
import { logout } from '../misc/Session';

const PrivateRoute = ({ children }) => {
  const globalContext = useContext(GlobalContext);
  if (globalContext.userInfo) return children;
  logout();
};

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PrivateRoute;
