import React, { useContext, useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import GlobalContext from 'src/context/GlobalContext';
import UserControl from 'src/components/controls/userControl/UserControl';
import Layout from 'src/components/layout/Layout';
import TitleBar from 'src/components/layout/TitleBar';
import Content from 'src/components/layout/Content';
import Footer from 'src/components/layout/Footer';
import { Card, Space, Button, Result } from 'antd';
import DarkModeControl from 'src/components/controls/darkModeControl/DarkModeControl';
import { getPayment } from 'src/api/invee';
import * as Ga4 from 'src/misc/Ga4';

// import dayjs from 'dayjs';
// import isoWeek from 'dayjs/plugin/isoWeek';
// import advancedFormat from 'dayjs/plugin/advancedFormat';
// import customParseFormat from 'dayjs/plugin/customParseFormat';
// import localeData from 'dayjs/plugin/localeData';
// import weekday from 'dayjs/plugin/weekday';
// import weekOfYear from 'dayjs/plugin/weekOfYear';
// import weekYear from 'dayjs/plugin/weekYear';
// import 'dayjs/locale/pl';

// dayjs.extend(isoWeek);
// dayjs.extend(customParseFormat);
// dayjs.extend(advancedFormat);
// dayjs.extend(weekday);
// dayjs.extend(localeData);
// dayjs.extend(weekOfYear);
// dayjs.extend(weekYear);
// dayjs.locale('pl');

const TokensBuyStatus = () => {
  const globalContext = useContext(GlobalContext);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const paymentId = searchParams.get('paymentId');

  const breadcrumbRoutes = [
    {
      title: 'Moje projekty',
      path: '/projects',
    },
    {
      title: 'Kredyty',
      path: '/account/tokens',
    },
    {
      title: 'Status transakcji',
    },
  ];

  const [payment, setPayment] = useState(null);
  const [error, setError] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    Ga4.pageView('Tokens / Buy / Status');
    loadPayment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadPayment = async () => {
    await globalContext.executeBlockingProcess(async () => {
      try {
        const response = await getPayment(paymentId);
        setPayment(response);
      } catch (error) {
        console.error(error);
        setError(true);
      }
      setLoaded(true);
    });
  };

  return (
    <>
      <Layout
        header={
          <TitleBar
            path='/projects'
            isLoading={globalContext.isBusy}
            rightExtras={
              <Space>
                <DarkModeControl />
                <UserControl />
              </Space>
            }
          ></TitleBar>
        }
        footer={<Footer />}
      >
        <Content breadcrumbRoutes={breadcrumbRoutes} title={'Status transakcji'}>
          {loaded ? (
            <>
              {error ? (
                <Card>
                  <Result
                    status='error'
                    title='Wystąpił błąd'
                    subTitle='Transakcja nie istnieje lub wystąpił problem po stronie serwera'
                    extra={[
                      <Button
                        type='primary'
                        key='GoToTokens'
                        onClick={() => {
                          navigate('/account/tokens');
                        }}
                      >
                        <div>
                          Wróć do strony <strong>Kredyty</strong>
                        </div>
                      </Button>,
                    ]}
                  ></Result>
                </Card>
              ) : (
                <Card>
                  {payment && payment.status === 'completed' ? (
                    <Result
                      status='success'
                      title='Sukces'
                      subTitle='Transakcja przebiegła pomyślnie'
                      extra={[
                        <Button
                          type='primary'
                          key='GoToTokens'
                          onClick={() => {
                            navigate('/projects');
                          }}
                        >
                          <div>
                            Wróć do strony <strong>Moje projekty</strong>
                          </div>
                        </Button>,
                      ]}
                    ></Result>
                  ) : (
                    <Result
                      status='warning'
                      title='Transakcja nie została zakończona pomyślnie'
                      subTitle={
                        <>
                          Identyfikator: {payment.paymentId}
                          <br />
                          Status: {payment?.status}
                        </>
                      }
                      extra={[
                        <Button
                          type='primary'
                          key='GoToTokens'
                          onClick={() => {
                            navigate('/account/tokens');
                          }}
                        >
                          <div>
                            Wróć do strony <strong>Kredyty</strong>
                          </div>
                        </Button>,
                      ]}
                    ></Result>
                  )}
                </Card>
              )}
            </>
          ) : (
            <></>
          )}
        </Content>
      </Layout>
    </>
  );
};

TokensBuyStatus.propTypes = {};

export default TokensBuyStatus;
