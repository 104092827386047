import React, { useContext, useEffect, useState } from 'react';
// import styled from 'styled-components';
//import PropTypes from 'prop-types';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import GlobalContext from 'src/context/GlobalContext';
import Layout from 'src/components/layout/Layout';
import TitleBar from 'src/components/layout/TitleBar';
import Footer from 'src/components/layout/Footer';
import Content from 'src/components/layout/Content';
import UserControl from 'src/components/controls/userControl/UserControl';
import { Space } from 'antd';
import DarkModeControl from 'src/components/controls/darkModeControl/DarkModeControl';
import useTheme from 'src/hooks/useTheme';
import { LeftOutlined } from '@ant-design/icons';
import WhenDesktop from 'src/components/layout/WhenDesktop';
import { useScreen } from 'src/hooks/useScreen';
import * as Ga4 from 'src/misc/Ga4';
import StyledButton from 'src/components/styled/StyledButton';
import { Helmet } from 'react-helmet';

//const StylesWrapper = styled.div``;

const Video = () => {
  const { videoId } = useParams();
  const globalContext = useContext(GlobalContext);
  const { theme } = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { screenSize } = useScreen();

  const [video, setVideo] = useState(null);
  const [ytStyle, setYtStyle] = useState(null);

  const videoList = [
    {
      id: 'gps-coordinates',
      title: 'Jak znaleźć współrzędne GPS?',
      videoUrl: 'https://www.youtube.com/embed/x8wuFeaxibE',
      description: 'W tym filmie pokażemy jak znaleźć współrzędne GPS i zastosować je w projekcie inwitki.',
      keywords: 'e-zaproszenia, cyfrowe, zaproszenia, inwitki, gps, współrzędne, koordynaty, współrzędne gps, koordynaty gps',
    },
    {
      id: 'invee-create',
      title: 'Jak stworzyć inwitkę w 2 minuty?',
      videoUrl: 'https://www.youtube.com/embed/SJLg76ra4LI',
      description: 'W tym filmie pokażemy jak stworzyć inwitkę - cyfrowe e-zaproszenie - w 2 minuty.',
      keywords: 'e-zaproszenia, cyfrowe, zaproszenia, inwitki, tworzenie, szybko, łatwo, narzędzie, stworzyć, inwitkę',
    },
    {
      id: 'pawel-is-creating-invee',
      title: 'Paweł tworzy inwitkę z kodem QR',
      videoUrl: 'https://www.youtube.com/embed/i9j0Lr0-HsQ',
      description: 'Zobacz jak Paweł tworzy inwitkę - cyfrowe e-zaproszenie.',
      keywords: 'e-zaproszenia, cyfrowe, zaproszenia, inwitki, tworzenie, szybko, łatwo, narzędzie, stworzyć, inwitkę',
    },
    {
      id: 'marek-received-invee',
      title: 'Marek otrzymał inwitkę z kodem QR',
      videoUrl: 'https://www.youtube.com/embed/09BRgH4xHCs',
      description: 'Zobacz co Marek zrobi z otrzymaną inwitką - cyfrowym e-zaproszeniem.',
      keywords: 'e-zaproszenia, cyfrowe, zaproszenia, inwitki, tworzenie, szybko, łatwo, narzędzie, stworzyć, inwitkę',
    },
  ];

  const breadcrumbRoutes = globalContext.userInfo
    ? [
        {
          title: 'Moje projekty',
          path: '/projects',
        },
        {
          title: `${video?.title}`,
        },
      ]
    : [
        {
          title: `${video?.title}`,
        },
      ];

  useEffect(() => {
    const v = videoList.find((v) => v.id === videoId);
    if (!v) {
      navigate('/404');
      return;
    }
    setVideo(v);
    Ga4.pageView(`Video: ${v.title}`);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (1600 < screenSize.width) {
      setYtStyle({ border: '1px solid #999999', width: '1400px', height: '788px', borderRadius: '1rem', overflow: 'hidden' });
    } else if (1024 < screenSize.width) {
      setYtStyle({ border: '1px solid #999999', width: '800px', height: '450px', borderRadius: '1rem', overflow: 'hidden' });
    } else if (800 < screenSize.width) {
      setYtStyle({ border: '1px solid #999999', width: '600px', height: '338px', borderRadius: '1rem', overflow: 'hidden' });
    } else if (600 < screenSize.width) {
      setYtStyle({ border: '1px solid #999999', width: '400px', height: '225px', borderRadius: '1rem', overflow: 'hidden' });
    } else {
      setYtStyle({ border: '1px solid #999999', width: '300px', height: '169px', borderRadius: '1rem', overflow: 'hidden' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenSize.width]);

  if (!video) return null;

  return (
    <Layout
      header={
        <TitleBar
          path='/'
          isLoading={globalContext.isBusy}
          leftExtras={
            <>
              {location.key !== 'default' && (
                <StyledButton color={theme.whiteButton} bgColor='transparent' onClick={() => navigate(-1)}>
                  <Space>
                    <LeftOutlined />
                    <WhenDesktop>Cofnij</WhenDesktop>
                  </Space>
                </StyledButton>
              )}
            </>
          }
          rightExtras={
            <Space>
              <DarkModeControl />
              <UserControl />
            </Space>
          }
        />
      }
      footer={
        <WhenDesktop>
          <Footer />
        </WhenDesktop>
      }
    >
      <Helmet>
        <title>{video?.title}</title>
        <meta name='keywords' content={video?.keywords} />
        <meta name='description' content={video?.description} />
      </Helmet>
      <Content breadcrumbRoutes={breadcrumbRoutes}>
        <div className='flex flex-col justify-center items-center'>
          <iframe src={video?.videoUrl} allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowFullScreen title={video?.title} style={ytStyle} />
        </div>
      </Content>
    </Layout>
  );
};

Video.propTypes = {};

export default Video;
