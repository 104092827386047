import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import PrivateRoute from './PrivateRoute';
import Index from 'src/components/pages/Index/Index';
import Page404 from 'src/components/pages/page404/Page404';
import InveeList from 'src/components/pages/inveeList/InveeList';
// import Invee from 'src/components/pages/invee/Invee';
import HowItWorks from 'src/components/pages/howItWorks/HowItWorks';
import InveeReader from 'src/components/pages/inveeReader/InveeReader';
import PrivacyPolicy from 'src/components/pages/privacyPolicy/PrivacyPolicy';
import Regulations from 'src/components/pages/regulations/Regulations';
import Contact from 'src/components/pages/contact/Contact';
import ContactForm from 'src/components/pages/contact/ContactForm';
import Tokens from 'src/components/pages/tokens/Tokens';
import TokensCode from 'src/components/pages/tokensCoupon/TokensCoupon';
import TokensBuy from 'src/components/pages/tokensBuy/TokensBuy';
import TokensGetForFree from 'src/components/pages/tokensGetForFree/TokensGetForFree';
import TokensBuyStatus from 'src/components/pages/tokensBuyStatus/TokensBuyStatus';
import SelectStyle from 'src/components/pages/selectStyle/SelectStyle';
import InveeCreate from 'src/components/pages/invee/InveeCreate';
import InveeStringCreator from 'src/components/controls/invee/InveeStringCreator';
import UserProfile from 'src/components/pages/userProfile/UserProfile';
import InveeStringListPage from 'src/components/pages/InveeStringListPage/InveeStringListPage';
import DataProcessing from 'src/components/pages/DataProcessing/DataProcessing';
import Video from 'src/components/pages/video/Video';
import BlogHome from 'src/components/pages/blog/BlogHome';
import BlogArticle from 'src/components/pages/blog/BlogArticle';

const history = createBrowserHistory();

const Router = () => {
  const getPrivateRoute = (path, element) => {
    return <Route path={path} element={<PrivateRoute>{element}</PrivateRoute>} />;
  };

  return (
    <BrowserRouter history={history}>
      <Routes>
        <Route index element={<Index />} />
        <Route path='/i/:inveeString' element={<InveeReader />} />
        <Route path='/pages/how-it-works' element={<HowItWorks />} />
        <Route path='/pages/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/pages/regulations' element={<Regulations />} />
        <Route path='/pages/contact' element={<Contact />} />
        <Route path='/pages/contact/form' element={<ContactForm />} />
        <Route path='/pages/data-processing' element={<DataProcessing />} />
        <Route path='/pages/video/:videoId' element={<Video />} />
        <Route path='/blog/' element={<BlogHome />} />
        <Route path='/blog/:articleId' element={<BlogArticle />} />

        {getPrivateRoute('/projects/create', <SelectStyle />)}
        {getPrivateRoute('/projects/:inveeId/create', <InveeCreate />)}
        {getPrivateRoute('/projects/:inveeId/invite', <InveeStringCreator />)}
        {getPrivateRoute('/projects', <InveeList />)}
        {getPrivateRoute('/account/tokens', <Tokens />)}
        {getPrivateRoute('/account/tokens/get-for-free', <TokensGetForFree />)}
        {getPrivateRoute('/account/tokens/code', <TokensCode />)}
        {getPrivateRoute('/account/tokens/buy', <TokensBuy />)}
        {getPrivateRoute('/account/tokens/buy/status', <TokensBuyStatus />)}
        {getPrivateRoute('/account/user-profile', <UserProfile />)}
        {getPrivateRoute('/account/tokens', <Tokens />)}

        {getPrivateRoute('/invees', <InveeStringListPage />)}
        <Route path='*' element={<Page404 />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
