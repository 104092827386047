import React, { useContext, useState, useEffect } from 'react';
import useTheme from 'src/hooks/useTheme';
import { useScreen } from 'src/hooks/useScreen';
import GlobalContext from 'src/context/GlobalContext';
import UserControl from 'src/components/controls/userControl/UserControl';
import Layout from 'src/components/layout/Layout';
import TitleBar from 'src/components/layout/TitleBar';
import Content from 'src/components/layout/Content';
import Footer from 'src/components/layout/Footer';
import { Alert, Card, Space, notification } from 'antd';
import DarkModeControl from 'src/components/controls/darkModeControl/DarkModeControl';
import { postPaymentTransactionRegister } from 'src/api/invee';
import { getTokenExpirationDuration, forceRefreshToken } from 'src/api/helpers';
import { InputNumber, Button, Checkbox } from 'antd';
import WhenDesktop from 'src/components/layout/WhenDesktop';
import StyledLink from 'src/components/styled/StyledLink';
import * as Ga4 from 'src/misc/Ga4';

// import dayjs from 'dayjs';
// import isoWeek from 'dayjs/plugin/isoWeek';
// import advancedFormat from 'dayjs/plugin/advancedFormat';
// import customParseFormat from 'dayjs/plugin/customParseFormat';
// import localeData from 'dayjs/plugin/localeData';
// import weekday from 'dayjs/plugin/weekday';
// import weekOfYear from 'dayjs/plugin/weekOfYear';
// import weekYear from 'dayjs/plugin/weekYear';
// import 'dayjs/locale/pl';

// dayjs.extend(isoWeek);
// dayjs.extend(customParseFormat);
// dayjs.extend(advancedFormat);
// dayjs.extend(weekday);
// dayjs.extend(localeData);
// dayjs.extend(weekOfYear);
// dayjs.extend(weekYear);
// dayjs.locale('pl');

const TokensBuy = () => {
  const globalContext = useContext(GlobalContext);
  const { isDesktop } = useScreen();
  const { theme } = useTheme();

  const breadcrumbRoutes = [
    {
      title: 'Moje projekty',
      path: '/projects',
    },
    {
      title: 'Kredyty',
      path: '/account/tokens',
    },
    {
      title: 'Kup kredyty',
    },
  ];

  const [tokenCount, setTokenCount] = useState(10);
  const [amount, setAmount] = useState(null);
  const [consent, setConsent] = useState(false);

  useEffect(() => {
    Ga4.pageView('Tokens / Buy');
    calculateAmount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenCount]);

  const calculateAmount = () => {
    if (tokenCount < 5) {
      setAmount(tokenCount + 1);
    } else {
      setAmount(tokenCount);
    }
  };

  const validateTokenExpiration = async () => {
    await globalContext.executeBlockingProcess(async () => {
      try {
        const tokenExpiration = await getTokenExpirationDuration();
        console.log('tokenExpiration', tokenExpiration);
        if (tokenExpiration < 1200) {
          await forceRefreshToken();
          await getTokenExpirationDuration();
        }
      } catch (error) {
        console.error(error);
      }
    });
  };

  const registerTransaction = async () => {
    let result = null;
    await globalContext.executeBlockingProcess(async () => {
      try {
        result = await postPaymentTransactionRegister(tokenCount, amount * 100);
      } catch (error) {
        console.error(error);
      }
    });
    return result;
  };

  const handleBuyTokensOnClick = async () => {
    await validateTokenExpiration();
    const { p24redirectToPayment } = await registerTransaction();
    if (!p24redirectToPayment) {
      notification.error({
        message: 'Ups...',
        description: (
          <>
            <div>coś poszło nie po naszej myśli :-(</div>
            <div className='text-xs text-gray-300'>Nie udało się poprawnie zarejestrować transakcji.</div>
          </>
        ),
        duration: 5,
      });
      return;
    }
    window.location.href = p24redirectToPayment;
  };

  return (
    <>
      <Layout
        header={
          <TitleBar
            path='/projects'
            isLoading={globalContext.isBusy}
            rightExtras={
              <Space>
                <DarkModeControl />
                <UserControl />
              </Space>
            }
          ></TitleBar>
        }
        footer={<Footer />}
      >
        <Content breadcrumbRoutes={breadcrumbRoutes} title={'Kup kredyty'}>
          <Card>
            <Alert message='Informacja' description={<>Dzięki kredytom możesz generować inwitki. Jeden kredyt wymieniasz na jedną imienną inwitkę. Niewykorzystane kredyty przepadają bezpowrotnie po 3 miesiącach od daty zakupu.</>} type='info' showIcon closable />
            <div className='mt-8 text-center'>Podaj liczbę kredytów</div>
            <div className='mt-2 text-center'>
              <InputNumber
                size='large'
                min={1}
                max={50}
                value={tokenCount}
                onChange={(v) => {
                  setTokenCount(v);
                }}
              />
            </div>
            <div className='mt-8 text-center'>
              <Checkbox
                style={{ flexDirection: 'column', gap: 4 }}
                checked={consent}
                size={'large'}
                onChange={() => {
                  setConsent(!consent);
                }}
              >
                Zapoznałem się z{' '}
                <StyledLink href='/pages/regulations' target='_blank' theme={theme}>
                  regulaminem
                </StyledLink>{' '}
                oraz{' '}
                <StyledLink href='/pages/privacy-policy' target='_blank' theme={theme}>
                  polityką prywatności
                </StyledLink>
                <br />i akceptuję ich postanowienia.
              </Checkbox>
            </div>
            <div className='mt-8 text-center'>
              <Button type='primary' onClick={handleBuyTokensOnClick} size={isDesktop ? 'large' : null} disabled={!consent}>
                <div>
                  Kupuję <span className='font-semibold'>{tokenCount} kredytów</span> za kwotę <span className='font-semibold'>PLN {amount},-</span>{' '}
                  <WhenDesktop>
                    <span className='opacity-60'>z VAT</span>
                  </WhenDesktop>
                </div>
              </Button>
            </div>
            <div className='mt-2 text-center opacity-60'>
              Po kliknięciu na guzik zostaniesz przekierowany
              <br />
              do serwisu <strong>przelewy24.pl</strong> w celu realizacji płatności.
            </div>
          </Card>
        </Content>
      </Layout>
    </>
  );
};

TokensBuy.propTypes = {};

export default TokensBuy;
