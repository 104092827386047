import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import InveesContext from 'src/context/InveesContext';
import styled from 'styled-components';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { MAX_CONTENT_LENGTH, SCRUB_HTML_REGEX } from 'src/misc/Config';
import { Card } from 'antd';

const StylesWrapper = styled.div`
  .ql-editor {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    text-align: center;
  }
`;

const InveeEditorContents = ({ defaultContent1, defaultContent2 }) => {
  const inveesContext = useContext(InveesContext);

  const [content1, setContent1] = useState(null);
  const [content2, setContent2] = useState(null);

  const [content1Length, setContent1Length] = useState(0);
  const [content2Length, setContent2Length] = useState(0);

  useEffect(() => {
    setContent1(defaultContent1);
    setContent2(defaultContent2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (content1) setContent1Length(content1.replace(SCRUB_HTML_REGEX, '').length);
  }, [content1]);

  useEffect(() => {
    if (content2) setContent2Length(content2.replace(SCRUB_HTML_REGEX, '').length);
  }, [content2]);

  const handleOnKeyDown = (e) => {
    if (e.key === 'Backspace') return;
    if (e.key === 'ArrowUp') return;
    if (e.key === 'ArrowRight') return;
    if (e.key === 'ArrowDown') return;
    if (e.key === 'ArrowLeft') return;
    const textLength = e.target.innerHTML?.replace(SCRUB_HTML_REGEX, '').length ?? MAX_CONTENT_LENGTH;
    if (textLength >= MAX_CONTENT_LENGTH) e.preventDefault();
  };

  return (
    <StylesWrapper className='flex flex-col gap-4'>
      <Card>
        <div className='my-1 font-bold'>Treść zaproszenia przed imieniem gościa :</div>
        <ReactQuill
          value={content1}
          onKeyDown={handleOnKeyDown}
          onChange={(v) => {
            setContent1(v);
            const currentEditorChanges = { ...inveesContext.currentEditorChanges };
            if (!currentEditorChanges.contents) currentEditorChanges.contents = { content1: inveesContext.currentInvee.contents?.content1, content2: inveesContext.currentInvee.contents?.content2 };
            currentEditorChanges.contents.content1 = v;
            inveesContext.setCurrentEditorChanges(currentEditorChanges);
          }}
          modules={{
            // toolbar: [['bold', 'italic', 'underline'], ['clean']],
            toolbar: [['underline'], ['clean']],
          }}
          // formats={['bold', 'italic', 'underline']}
          formats={['underline']}
          autoComplete='off'
          style={{ height: '160px', paddingBottom: '50px' }}
        />
        <div className='text-right'>
          {content1Length}/{MAX_CONTENT_LENGTH}
        </div>
      </Card>
      <Card>
        <div className='my-1 font-bold'>Treść zaproszenia za imieniem gościa:</div>
        <ReactQuill
          value={content2}
          onKeyDown={handleOnKeyDown}
          onChange={(v) => {
            setContent2(v);
            const currentEditorChanges = { ...inveesContext.currentEditorChanges };
            if (!currentEditorChanges.contents) currentEditorChanges.contents = { content1: inveesContext.currentInvee.contents?.content1, content2: inveesContext.currentInvee.contents?.content2 };
            currentEditorChanges.contents.content2 = v;
            inveesContext.setCurrentEditorChanges(currentEditorChanges);
          }}
          modules={{
            toolbar: [['underline'], ['clean']],
          }}
          formats={['underline']}
          autoComplete='off'
          style={{ height: '160px', paddingBottom: '50px' }}
        />
        <div className='text-right'>
          {content2Length}/{MAX_CONTENT_LENGTH}
        </div>
      </Card>
    </StylesWrapper>
  );
};

InveeEditorContents.propTypes = {
  defaultContent1: PropTypes.string.isRequired,
  defaultContent2: PropTypes.string.isRequired,
};

InveeEditorContents.defaultProps = {
  defaultContent1: '',
  defaultContent2: '',
};

export default InveeEditorContents;
