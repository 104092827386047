import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import InveeView from 'src/components/controls/invee/InveeView';
import { CloseOutlined } from '@ant-design/icons';
import { useScreen } from 'src/hooks/useScreen';
import useTheme from 'src/hooks/useTheme';
//import { SCREEN_RATIOS } from 'src/misc/Config';

//const StylesWrapper = styled.div``;

const InveeMobileView = ({ invee, guest, onClose, inveeString }) => {
  const { screenSize } = useScreen();
  const { isDarkMode } = useTheme();

  const [inveeWidth] = useState('328px');
  const [zoom, setZoom] = useState('1.0');

  useEffect(() => {
    if (1400 < screenSize.width) {
      setZoom('2.0');
    } else if (1200 < screenSize.width) {
      setZoom('1.8');
    } else if (1000 < screenSize.width) {
      setZoom('1.6');
    } else if (800 < screenSize.width) {
      setZoom('1.4');
      // } else if (600 < screenSize.width) {
      //   setZoom('1.2');
    } else {
      setZoom('1.2');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenSize.width]);

  return (
    <>
      <div className={`fixed top-0 right-0 bottom-0 left-0 flex flex-row justify-center z-40`} style={{ backgroundColor: isDarkMode ? '#000000dd' : '#000000dd', zoom }}>
        {onClose && (
          <div className='absolute flex flex-row items-center justify-center cursor-pointer border-solid border-3 border-white bg-black text-white top-6 right-6 z-50 rounded-full w-8 h-8' onClick={() => onClose()}>
            <CloseOutlined />
          </div>
        )}
        {500 > screenSize.width ? (
          <div className='relative w-full flex flex-row justify-center overflow-y-auto overflow-x-hidden'>
            <div>{<InveeView invee={invee} guest={guest} inveeString={inveeString} />}</div>
          </div>
        ) : (
          <div className='relative w-full flex flex-row justify-center overflow-y-auto overflow-x-hidden pt-10 pb-10'>
            <div className={'mt-auto mb-auto'} style={{ width: inveeWidth }}>
              <InveeView invee={invee} guest={guest} borderRadius='3rem' inveeString={inveeString} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

InveeMobileView.propTypes = {
  invee: PropTypes.object,
  inveeString: PropTypes.string,
  guest: PropTypes.string,
  onClose: PropTypes.func,
};

export default InveeMobileView;
