import { useContext } from 'react';
import GlobalContext from '../../context/GlobalContext';
import PropTypes from 'prop-types';
import { SCREEN_RATIOS } from 'src/misc/Config';

const WhenPortrait = ({ children }) => {
  const globalContext = useContext(GlobalContext);
  if (globalContext.screenRatio === SCREEN_RATIOS.portrait) return children;
  return null;
};

WhenPortrait.propTypes = {
  children: PropTypes.any,
};

export default WhenPortrait;
