import React, { Component } from 'react';
import GlobalContext from './GlobalContext';
import { MOBILE_TRESHOLD, SCREEN_MODES, SCREEN_RATIOS, determineEnv } from '../misc/Config';
// import * as dayjs from 'dayjs';
import { LOCAL_STORAGE_KEYS } from 'src/misc/Config';
import * as dayjs from 'dayjs';
import { updateConsents } from 'src/misc/Ga4';

//import { sleepMs } from '../../../misc/Misc';

var runningProcesses1 = 0;

export default class GlobalProvider extends Component {
  determineMobileOs = () => {
    // https://stackoverflow.com/questions/21741841/detecting-ios-android-operating-systemrAgent || window.opera;

    const userAgent = navigator.userAgent;

    console.log('userAgent', userAgent);

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone';
    }

    if (/android/i.test(userAgent)) {
      return 'Android';
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'iOS';
    }

    return 'unknown';
  };

  determineIsDarkMode = () => {
    // <user preference>
    const isDarkMode = localStorage.getItem(LOCAL_STORAGE_KEYS.isDarkMode);
    if (isDarkMode === 'true') return true;
    if (isDarkMode === 'false') return false;
    // </user preference>

    // <system preference>
    if (window.matchMedia) {
      if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        return true;
      } else {
        return false;
      }
    }
    // </system preference>

    return false; // default if not determined
  };

  getScreenMode = () => {
    const { innerWidth: width } = window;
    return MOBILE_TRESHOLD < width ? SCREEN_MODES.desktop : SCREEN_MODES.mobile;
  };

  getScreenSize = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return { width, height };
  };

  getScreenRatio = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return width > height ? SCREEN_RATIOS.landscape : SCREEN_RATIOS.portrait;
  };

  handleResize = () => {
    const screenMode = this.getScreenMode();
    if (screenMode !== this.state.screenMode) this.setState({ screenMode });
    const screenSize = this.getScreenSize();
    if (screenSize !== this.state.screenSize) this.setState({ screenSize });
    const screenRatio = this.getScreenRatio();
    if (screenRatio !== this.state.screenRatio) this.setState({ screenRatio });
  };

  determineIfCookieConsentsOpen = async () => {
    const cookieConsentsStr = localStorage.getItem(LOCAL_STORAGE_KEYS.cookieConsents);
    if (!cookieConsentsStr) {
      // consents not found - aaplying default ones from the tamplate
      this.setState({ cookieConsents: { ...this.cookieConsentsTemplate }, cookieConsentsOpen: true });
      return;
    }

    const cookieConsents = JSON.parse(cookieConsentsStr);
    if (!cookieConsents?.at) {
      // consents found but without timestamp - applying default ones from the tamplate
      this.setState({ cookieConsents: { ...this.cookieConsentsTemplate }, cookieConsentsOpen: true });
      return;
    }
    this.setState({ cookieConsents });

    updateConsents(cookieConsents.stats ? 'granted' : 'denied');

    if (cookieConsents.stats) {
      return;
    }

    if (dayjs().diff(dayjs(cookieConsents.at), 'day') > 7) {
      this.setState({ cookieConsentsOpen: true });
    }
  };

  componentDidMount = () => {
    determineEnv();
    // initializeGA();
    this.determineIfCookieConsentsOpen();
    window.addEventListener('resize', this.handleResize);
  };

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.handleResize);
  };

  cookieConsentsTemplate = {
    needed: true,
    stats: false,
    at: null,
  };

  state = {
    userInfo: null,
    menuActiveKey: null,
    screenMode: this.getScreenMode(),
    screenSize: this.getScreenSize(),
    screenRatio: this.getScreenRatio(),
    layoutScrollTop: 0,
    runningProcesses: 0,
    isDarkMode: this.determineIsDarkMode(),
    cookieConsentsOpen: false,
    cookieConsents: null,
    mobileOs: this.determineMobileOs(),
  };

  render() {
    return (
      <GlobalContext.Provider
        value={{
          userInfo: this.state.userInfo,
          screenMode: this.state.screenMode,
          setUserInfo: (userInfo) => this.setState({ userInfo }),
          menuActiveKey: this.state.menuActiveKey,
          setMenuActiveKey: (menuActiveKey) => this.setState({ menuActiveKey }),
          isBusy: this.state.runningProcesses > 0,
          runningProcesses: this.state.runningProcesses,
          executeBlockingProcess: async (func) => {
            runningProcesses1++;
            this.setState({ runningProcesses: runningProcesses1 });
            await func();
            runningProcesses1--;
            this.setState({ runningProcesses: runningProcesses1 });
          },
          layoutScrollTop: this.state.layoutScrollTop,
          setLayoutScrollTop: (layoutScrollTop) => this.setState({ layoutScrollTop }),
          screenSize: this.state.screenSize,
          screenRatio: this.state.screenRatio,
          isDarkMode: this.state.isDarkMode,
          toggleDarkMode: () => {
            const isDarkMode = !this.state.isDarkMode;
            this.setState({ isDarkMode });
            localStorage.setItem(LOCAL_STORAGE_KEYS.isDarkMode, isDarkMode ? 'true' : 'false');
          },
          cookieConsentsOpen: this.state.cookieConsentsOpen,
          setCookieConsentsOpen: (cookieConsentsOpen) => this.setState({ cookieConsentsOpen }),
          cookieConsents: this.state.cookieConsents,
          setCookieConsents: (cookieConsents) => {
            cookieConsents.needed = true;
            this.setState({ cookieConsents });
          },
          mobileOs: this.state.mobileOs,
        }}
      >
        {this.props.children}
      </GlobalContext.Provider>
    );
  }
}
