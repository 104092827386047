import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import GlobalContext from 'src/context/GlobalContext';
import { Space, Dropdown } from 'antd';
import { LoginOutlined, LogoutOutlined, MenuOutlined, FolderOutlined, UserOutlined, HomeOutlined } from '@ant-design/icons';
import { GiTwoCoins } from 'react-icons/gi';
import { RiHistoryLine } from 'react-icons/ri';
import { IoChatboxEllipsesOutline } from 'react-icons/io5';
import { login, logout } from 'src/misc/Session';
import WhenDesktop from 'src/components/layout/WhenDesktop';
import StyledButton from 'src/components/styled/StyledButton';
import useTheme from 'src/hooks/useTheme';
import * as Ga4 from 'src/misc/Ga4';

const StylesWrapper = styled.div``;

const UserControl = () => {
  const navigate = useNavigate();
  const globalContext = useContext(GlobalContext);
  const { theme } = useTheme();

  const handleOnMenuClick = (e) => {
    Ga4.event('User menu', 'Menu_item_clicked_' + e.key, 'Menu_item_clicked / ' + e.key);
    if (e.key === 'home') navigate('/');
    if (e.key === 'projects') navigate('/projects');
    if (e.key === 'tokens') navigate('/account/tokens');
    if (e.key === 'invees') navigate('/invees');
    if (e.key === 'contact') navigate('/pages/contact');
    if (e.key === 'user-profile') navigate('/account/user-profile');
    if (e.key === 'logout') logout();
  };

  return (
    <StylesWrapper>
      <Space>
        {globalContext.userInfo ? (
          <Dropdown
            trigger={['click']}
            size='small'
            placement='bottomRight'
            menu={{
              onClick: handleOnMenuClick,
              items: [
                {
                  icon: <HomeOutlined />,
                  label: 'Strona domowa',
                  key: 'home',
                },
                {
                  icon: <FolderOutlined />,
                  label: 'Moje projekty',
                  key: 'projects',
                },
                {
                  icon: <GiTwoCoins style={{ fontSize: '1.1rem' }} />,
                  label: 'Kredyty',
                  key: 'tokens',
                },
                {
                  icon: <RiHistoryLine />,
                  label: 'Ostatnie inwitki',
                  key: 'invees',
                },
                {
                  icon: <IoChatboxEllipsesOutline />,
                  label: 'Kontakt',
                  key: 'contact',
                },
                {
                  icon: <UserOutlined />,
                  label: 'Profil użytkownika',
                  key: 'user-profile',
                },
                {
                  icon: <LogoutOutlined />,
                  label: (
                    <>
                      <div>
                        <div>Wyloguj</div>
                        <div style={{ maxWidth: '280px' }} className='overflow-hidden truncate text-ellipsis font-light text-xs'>
                          {globalContext.userInfo.email}
                        </div>
                      </div>
                    </>
                  ),
                  key: 'logout',
                },
              ],
            }}
          >
            <StyledButton color={theme.whiteButton}>
              <Space>
                <MenuOutlined />
                <WhenDesktop>
                  <div className='overflow-hidden truncate text-ellipsis' style={{ maxWidth: '240px' }}>
                    {/* {globalContext.userInfo.email} */}
                    Menu
                  </div>
                </WhenDesktop>
              </Space>
            </StyledButton>
          </Dropdown>
        ) : (
          <StyledButton
            color={theme.whiteButton}
            onClick={() => {
              Ga4.event('User menu', 'We_start_user_control', 'We_start / user_control');
              login();
            }}
          >
            <Space>
              <LoginOutlined />
              <WhenDesktop>Stwórz zaproszenie</WhenDesktop>
            </Space>
          </StyledButton>
        )}
      </Space>
    </StylesWrapper>
  );
};

UserControl.propTypes = {};

UserControl.defaultProps = {};

export default UserControl;
