import React, { Component } from 'react';
import InveesContext from './InveesContext';
import { notArrayOrEmpty } from 'src/misc/Misc';

export default class InveesProvider extends Component {
  state = {
    inveeList: null,
    currentInvee: null,
    currentEditor: null,
    currentEditorChanges: null,
    tokens: null,
    tokensDetails: null,
    paymentsDetails: null,
  };

  render() {
    return (
      <InveesContext.Provider
        value={{
          inveeList: this.state.inveeList,
          setInveeList: (inveeList) => this.setState({ inveeList }),
          updateInveeList: (inveeId, changes) => {
            if (notArrayOrEmpty(this.state.inveeList)) return;
            const inveeList = this.state.inveeList.map((invee) => {
              if (invee.inveeId === inveeId) return { ...invee, ...changes };
              return invee;
            });
            this.setState({ inveeList });
          },
          currentInvee: this.state.currentInvee,
          setCurrentInvee: (currentInvee) => this.setState({ currentInvee }),
          removeInvee: (inveeId) => {
            const inveeList = this.state.inveeList.filter((invee) => invee.inveeId !== inveeId);
            this.setState({ inveeList });
          },
          currentEditor: this.state.currentEditor,
          setCurrentEditor: (currentEditor) => this.setState({ currentEditor }),
          currentEditorChanges: this.state.currentEditorChanges,
          setCurrentEditorChanges: (currentEditorChanges) => this.setState({ currentEditorChanges }),
          tokens: this.state.tokens,
          setTokens: (tokens) => this.setState({ tokens }),
          tokensDetails: this.state.tokensDetails,
          setTokensDetails: (tokensDetails) => this.setState({ tokensDetails }),
          paymentsDetails: this.state.paymentsDetails,
          setPaymentsDetails: (paymentsDetails) => this.setState({ paymentsDetails }),
        }}
      >
        {this.props.children}
      </InveesContext.Provider>
    );
  }
}
