import { useContext } from 'react';
import GlobalContext from '../../context/GlobalContext';
import PropTypes from 'prop-types';
import { SCREEN_MODES } from '../../misc/Config';

const WhenMobile = ({ children }) => {
  const globalContext = useContext(GlobalContext);
  if (globalContext.screenMode === SCREEN_MODES.mobile) return children;
  return null;
};

WhenMobile.propTypes = {
  children: PropTypes.any,
};

export default WhenMobile;
