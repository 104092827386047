import React, { useState } from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import useTheme from 'src/hooks/useTheme';

const SelectStyleItem = ({ style, onSelect }) => {
  const [showSelectBtn, setShowSelectBtn] = useState(false);
  const { theme } = useTheme();

  const handleCreateProject = async (style) => {
    if (onSelect) onSelect(style);
  };

  return (
    <div className='relative w-[166px] lg:hover:scale-105' onMouseEnter={() => setShowSelectBtn(true)} onMouseLeave={() => setShowSelectBtn(false)}>
      {showSelectBtn && (
        <div className='absolute top-0 right-0 bottom-0 left-0 z-10 flex flex-row justify-center items-center'>
          <Button
            className='shadow-xl'
            type='default'
            size='large'
            onClick={() => {
              handleCreateProject(style);
            }}
          >
            Wybierz
          </Button>
        </div>
      )}

      <img src={style.thumbnail} alt={style.name} className='w-full' style={{ filter: `drop-shadow(0px 0px 5px ${theme.primary + '55'}` }} />
      {/* <div className='text-xs overflow-hidden truncate text-ellipsis text-center' style={{ color: theme.dark4 }}>
        {style.name}
      </div> */}
    </div>
  );
};

SelectStyleItem.propTypes = {
  style: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
};

export default SelectStyleItem;
