// export const theme = {
//   primary: '#0084b6',
//   light1: '#ffffff',
//   light2: '#fcfcfc',
//   light3: '#00b9ff',
//   dark3: '#424242',
//   dark4: '#000000',
//   centredTo: '1400px',
// };

export const themeLight = {
  primary: '#0097d1',
  logoBlue: '#00b9ff',
  whiteButton: '#cccccc',
  topBarBg: '#101214',
  light1: '#ffffff',
  light2: '#f5f5f5',
  light3: '#00b9ff',
  dark3: '#404244',
  dark4: '#303234',
  centredTo: '1400px',
};

export const themeDark = {
  primary: '#0097d1',
  logoBlue: '#00b9ff',
  whiteButton: '#cccccc',
  topBarBg: '#101214',
  light1: '#202224',
  light2: '#404244',
  light3: '#00b9ff',
  dark3: '#f5f5f5',
  dark4: '#eeeeee',
  centredTo: '1400px',
};
