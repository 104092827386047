import { useEffect, useState, useContext } from 'react';
import GlobalContext from '../context/GlobalContext';
import styled from 'styled-components';
import PropTypes from 'prop-types';
// import { getUserInfo } from '../api/cognito';
import { LOCAL_STORAGE_KEYS } from 'src/misc/Config';
import { getAuthUserInfo } from 'src/api/invee';

const StylesWrapper = styled.div`
  .loader-outer {
    position: fixed;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    text-align: center;
    padding-top: 50px;
  }

  .loader {
    border: 4px solid #eeeeee;
    border-top: 4px solid #888888;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 2s linear infinite;
    display: inline-block;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;

const AuthProvider = ({ children }) => {
  const globalContext = useContext(GlobalContext);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (checked) return;
    if (window.location.pathname.startsWith('/i/') || window.location.pathname.startsWith('/404') || window.location.pathname.startsWith('/pages/')) {
      setChecked(true);
      return;
    }
    checkAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkAuth = async () => {
    try {
      // const userInfo = await getUserInfo();
      const userInfo = await getAuthUserInfo();
      globalContext.setUserInfo(userInfo);
    } catch (error) {
      console.error(error);
      localStorage.removeItem(LOCAL_STORAGE_KEYS.cognitoAccessToken);
      globalContext.setUserInfo(false);
    }
    setChecked(true);
  };

  if (!checked)
    return (
      <StylesWrapper>
        <div className='loader-outer'>
          <div className='loader' />
        </div>
      </StylesWrapper>
    );
  return children;
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthProvider;
