import React, { useContext, useEffect } from 'react';
import GlobalContext from 'src/context/GlobalContext';
import UserControl from 'src/components/controls/userControl/UserControl';
import Layout from 'src/components/layout/Layout';
import TitleBar from 'src/components/layout/TitleBar';
import Footer from 'src/components/layout/Footer';
import Content from 'src/components/layout/Content';
import { Space } from 'antd';
import DarkModeControl from 'src/components/controls/darkModeControl/DarkModeControl';
import InveeStringList from 'src/components/controls/invee/InveeStringList';
import * as Ga4 from 'src/misc/Ga4';

const InveeStringListPage = () => {
  const breadcrumbRoutes = [
    {
      title: 'Moje projekty',
      path: '/projects',
    },
    {
      title: 'Ostatnie inwitki',
    },
  ];

  const globalContext = useContext(GlobalContext);

  useEffect(() => {
    Ga4.pageView('Invee String List');
  }, []);

  return (
    <Layout
      header={
        <TitleBar
          path='/'
          isLoading={globalContext.isBusy}
          rightExtras={
            <Space>
              <DarkModeControl />
              <UserControl />
            </Space>
          }
        />
      }
      footer={<Footer />}
    >
      <Content breadcrumbRoutes={breadcrumbRoutes} title='Ostatnie inwitki'>
        <InveeStringList />
      </Content>
    </Layout>
  );
};

InveeStringListPage.propTypes = {};

export default InveeStringListPage;
