import React, { useState, useEffect, useContext } from 'react';
import useTheme from 'src/hooks/useTheme';
import { Button, Modal, Checkbox, notification } from 'antd';
import InveesContext from 'src/context/InveesContext';
import GlobalContext from 'src/context/GlobalContext';
import { getTokens, postTokensGetForFree } from 'src/api/invee';
import { FaGift } from 'react-icons/fa6';
import * as Ga4 from 'src/misc/Ga4';
import StyledLink from 'src/components/styled/StyledLink';
//import styled from 'styled-components';
//import PropTypes from 'prop-types';

// const StylesWrapper = styled.div``;

const FreeTokensBanner = () => {
  const { theme } = useTheme();

  const { tokens, setTokens, setTokensDetails } = useContext(InveesContext);
  const { executeBlockingProcess, isBusy } = useContext(GlobalContext);

  const [isOpen, setIsOpen] = useState(false);
  const [consent, setConsent] = useState(false);

  useEffect(() => {
    // Ga4.pageView('TokensBanner / show');
    if (tokens === null) loadTokens();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tokens === 0) {
      Ga4.event('TokensBanner', 'TokensBanner_open', 'TokensBanner_open');
      setIsOpen(true);
    }
  }, [tokens]);

  const loadTokens = async () => {
    await executeBlockingProcess(async () => {
      try {
        const resp = await getTokens();
        setTokens(resp.tokens ?? 0);
      } catch (error) {
        console.error(error);
      }
    });
  };

  const handleGetFreeTokens = async () => {
    await executeBlockingProcess(async () => {
      try {
        setTokens(null);
        setTokensDetails(null);
        await postTokensGetForFree(true);
        const response = await getTokens();
        setTokens(response?.tokens ?? 0);
        Ga4.event('TokensBanner', 'Get_free_tokens', 'Get_free_tokens');
        setIsOpen(false);
        notification.success({
          message: 'Sukces',
          description: 'Dodano darmowe kredyty.',
          duration: 5,
        });
      } catch (error) {
        console.error(error);
      }
    });
  };

  const handleCancel = () => {
    setIsOpen(false);
    Ga4.event('TokensBanner', 'TokensBanner_cancel', 'TokensBanner_cancel');
  };

  return (
    <Modal
      maskClosable={false}
      open={isOpen}
      onCancel={handleCancel}
      styles={{ footer: { display: 'flex', justifyContent: 'center' } }}
      footer={[
        <Button key='submit' type='primary' size='large' onClick={handleGetFreeTokens} disabled={!consent || isBusy} loading={isBusy}>
          Odbieram darmowe kredyty
        </Button>,
      ]}
    >
      <div className='text-center'>
        <div className={`text-6xl`} style={{ color: theme.logoBlue }}>
          <FaGift style={{ filter: 'drop-shadow(rgba(0, 185, 255, 0.267) 0px 0px 8px)' }} />
        </div>
        <div className='text-xl font-bold'>Odbierz darmowe kredyty na start</div>
        <div className='text-lg opacity-60'>Zapraszaj gości. Wysyłaj inwitki.</div>
        <div className='my-4 flex flex-row justify-center items-start gap-2 cursor-pointer' onClick={() => setConsent(!consent)}>
          <Checkbox
            // style={{ flexDirection: 'column', gap: 4 }}
            checked={consent}
            size={'large'}
            onChange={() => {
              setConsent(!consent);
            }}
          ></Checkbox>
          <div>
            Zapoznałem się z{' '}
            <StyledLink href='/pages/regulations' target='_blank' theme={theme}>
              regulaminem
            </StyledLink>{' '}
            oraz{' '}
            <StyledLink href='/pages/privacy-policy' target='_blank' theme={theme}>
              polityką prywatności
            </StyledLink>
            <br />i akceptuję ich postanowienia.
          </div>
        </div>
      </div>
    </Modal>
  );
};

FreeTokensBanner.propTypes = {};

export default FreeTokensBanner;
