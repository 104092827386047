import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useTheme from 'src/hooks/useTheme';
import GlobalContext from 'src/context/GlobalContext';
import InveesContext from 'src/context/InveesContext';
import UserControl from 'src/components/controls/userControl/UserControl';
import Layout from 'src/components/layout/Layout';
import TitleBar from 'src/components/layout/TitleBar';
import Content from 'src/components/layout/Content';
import Footer from 'src/components/layout/Footer';
import { Button, Card, Input, Space, Checkbox, Alert, notification } from 'antd';
import { postCouponsActivate } from 'src/api/invee';
import { sleepMs } from 'src/misc/Misc';
import DarkModeControl from 'src/components/controls/darkModeControl/DarkModeControl';
import StyledLink from 'src/components/styled/StyledLink';
import * as Ga4 from 'src/misc/Ga4';

import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import 'dayjs/locale/pl';

dayjs.extend(isoWeek);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
dayjs.locale('pl');

const TokensCoupon = () => {
  const globalContext = useContext(GlobalContext);
  const inveesContext = useContext(InveesContext);
  const { theme } = useTheme();

  const navigate = useNavigate();

  const breadcrumbRoutes = [
    {
      title: 'Moje projekty',
      path: '/projects',
    },
    {
      title: 'Kredyty',
      path: '/account/tokens',
    },
    {
      title: 'Zrealizuj kupon',
    },
  ];

  const [couponId, setCouponId] = useState('');
  const [consent, setConsent] = useState(false);

  useEffect(() => {
    Ga4.pageView('Tokens / Coupon');
  }, []);

  const handleSubmitCoupon = async () => {
    globalContext.executeBlockingProcess(async () => {
      try {
        if (couponId?.length < 10) throw new Error('Invalid length');
        await postCouponsActivate(couponId);
        inveesContext.setTokens(null);
        inveesContext.setTokensDetails(null);
        notification.success({
          message: 'Sukces',
          description: 'Kupon został zatwierdzony.',
          duration: 5,
        });
        navigate('/account/tokens');
      } catch (error) {
        await sleepMs(10000);
        console.error(error);
        notification.error({
          message: 'Błąd',
          description: 'Błędny kod. Spróbuj ponownie.',
          duration: 5,
        });
      }
      setCouponId('');
      setConsent(false);
    });

    if (!inveesContext.tokensDetails) return;
  };

  return (
    <>
      <Layout
        header={
          <TitleBar
            path='/projects'
            isLoading={globalContext.isBusy}
            rightExtras={
              <Space>
                <DarkModeControl />
                <UserControl />
              </Space>
            }
          ></TitleBar>
        }
        footer={<Footer />}
      >
        <Content breadcrumbRoutes={breadcrumbRoutes} title={'Zrealizuj kupon'}>
          <Card>
            <div>
              <Alert message='Informacja' description={<>Dzięki kredytom możesz generować inwitki. Jeden kredyt wymieniasz na jedną imienną inwitkę. Niewykorzystane kredyty przepadają bezpowrotnie po 3 miesiącach od daty zakupu.</>} type='info' showIcon closable />

              <div className='mt-8 text-center'>Wpisz kod z kuponu</div>
              <div className='mt-2 text-center'>
                <Input
                  showCount
                  maxLength={10}
                  placeholder='wprowadź kod'
                  className='w-full lg:w-3/12'
                  value={couponId}
                  onChange={(e) => {
                    setCouponId(e.target.value);
                  }}
                />
              </div>
              <div className='mt-8 text-center'>
                <Checkbox
                  style={{ flexDirection: 'column', gap: 4 }}
                  checked={consent}
                  size={'large'}
                  onChange={() => {
                    setConsent(!consent);
                  }}
                >
                  Zapoznałem się z{' '}
                  <StyledLink href='/pages/regulations' target='_blank' theme={theme}>
                    regulaminem
                  </StyledLink>{' '}
                  oraz{' '}
                  <StyledLink href='/pages/privacy-policy' target='_blank' theme={theme}>
                    polityką prywatności
                  </StyledLink>
                  <br />i akceptuję ich postanowienia.
                </Checkbox>
              </div>
              <div className='mt-4 text-center'>
                <Button disabled={couponId?.length < 1 || !consent} loading={globalContext.isBusy} type='primary' onClick={handleSubmitCoupon}>
                  Zatwierdź
                </Button>
              </div>
            </div>
          </Card>
        </Content>
      </Layout>
    </>
  );
};

TokensCoupon.propTypes = {};

export default TokensCoupon;
