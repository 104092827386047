import { logout } from '../misc/Session';
import { notification } from 'antd';
import dayjs from 'dayjs';
import { LOCAL_STORAGE_KEYS, ENV } from '../misc/Config';
import jwt_decode from 'jwt-decode';
import { exception } from 'src//misc/Ga4';

export const handleResponse = async (response, handle401 = true, showErrorMsg = true) => {
  if (response.ok) return response.status === 200 ? response.json() : null;

  if (handle401 && response.status === 401) {
    logout();
    return;
  }

  if (showErrorMsg) {
    const message = (await response?.json()).message;
    notification.error({
      message: 'Ups...',
      description: (
        <>
          <div>coś poszło nie po naszej myśli :-(</div>
          {message ? <div className='text-xs text-gray-300'>{message}</div> : null}
        </>
      ),
      duration: 5,
    });
  }
  throw new Error(`${response.status} ${response.statusText}`);
};

export const handleError = (error) => {
  console.error(error);
  exception(error.message);
  notification.error({
    message: 'Ups...',
    description: (
      <>
        <div>coś poszło nie po naszej myśli :-(</div>
        <div className='text-xs text-gray-300'>{error.message}</div>
      </>
    ),
    duration: 5,
  });
};

export const getAccessToken = async () => {
  try {
    const access_token = localStorage.getItem(LOCAL_STORAGE_KEYS.cognitoAccessToken);
    const refresh_token_hash = localStorage.getItem(LOCAL_STORAGE_KEYS.cognitoRefreshTokenHash);
    if (!access_token) {
      return null;
    }
    const { exp: access_token_exp } = jwt_decode(access_token);
    if (dayjs().isBefore(dayjs.unix(access_token_exp).subtract(1, 'minute'))) return access_token;
    const access_token_refreshed = await refreshToken(access_token, refresh_token_hash);
    return access_token_refreshed;
  } catch (error) {
    console.log(error);
    logout();
    return null;
  }
};

export const refreshToken = async (access_token, refresh_token_hash) => {
  const respBody = await handleResponse(
    await fetch(`${ENV.inveeApi}/auth/token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ access_token, refresh_token_hash }),
    })
  );
  if (respBody.access_token) {
    if (respBody.access_token) localStorage.setItem(LOCAL_STORAGE_KEYS.cognitoAccessToken, respBody.access_token);
    if (respBody.refresh_token_hash) localStorage.setItem(LOCAL_STORAGE_KEYS.cognitoRefreshTokenHash, respBody.refresh_token_hash);
    // <remove this after sucessfull tests>
    // notification.success({
    //   message: 'Twoja sesja została przedłużona',
    //   duration: 5,
    // });
    // </remove this after sucessfull tests>
    return respBody.access_token;
  }
  return null;
};

export const getTokenExpirationDuration = async () => {
  const access_token = localStorage.getItem(LOCAL_STORAGE_KEYS.cognitoAccessToken);
  if (!access_token) return 0;
  const { exp: access_token_exp } = jwt_decode(access_token);
  return dayjs.unix(access_token_exp).diff(dayjs(), 'second');
};

export const forceRefreshToken = async () => {
  try {
    const access_token = localStorage.getItem(LOCAL_STORAGE_KEYS.cognitoAccessToken);
    const refresh_token_hash = localStorage.getItem(LOCAL_STORAGE_KEYS.cognitoRefreshTokenHash);
    if (!access_token) throw new Error('No access token');
    if (!refresh_token_hash) throw new Error('No refresh token');
    await refreshToken(access_token, refresh_token_hash);
  } catch (error) {
    console.log(error);
    logout();
  }
};
