import styled from 'styled-components';
import useTheme from 'src/hooks/useTheme';

//import PropTypes from 'prop-types';

const StylesWrapper = styled.div`
  ul > li {
    margin-block-start: 1em;
    margin-block-end: 1em;
  }

  ol {
    counter-reset: item;
  }

  ol > li {
    counter-increment: item;
    margin-block-start: 1em;
    margin-block-end: 1em;
  }

  ol ol > li {
    display: block;
  }

  ol ol > li:before {
    content: counters(item, '.') '. ';
    margin-left: -20px;
  }

  #page {
    counter-reset: heading;
  }

  h3:before {
    content: counter(heading) '. ';
    counter-increment: heading;
  }

  h3 {
    counter-reset: subheading;
  }

  h4:before {
    content: counter(heading) '.' counter(subheading) '. ';
    counter-increment: subheading;
  }
`;

const PrivacyPolicyContent = () => {
  const { isDarkMode } = useTheme();

  return (
    <StylesWrapper>
      <div className='my-4 lg:my-4 flex flex-col items-center gap-2' id='page'>
        <div className=' w-full lg:w-2/3 text-justify'>
          <h3>Dane administratora danych</h3>
          <p>Uprzejmie informujemy, że administratorem Pana/Pani danych osobowych jest:</p>
          <p>
            BULLAPP.CLOUD SP. Z O.O.
            <br />
            ul. Hoża 86 lok. 410
            <br />
            00-682 Warszawa
            <br />
            NIP: 7011202892
            <br />
            REGON: 528485891
            <br />
            KRS: 0001103080
          </p>
          <p>(dalej jako „Spółka”).</p>
          <p>Kontakt z Spółką w sprawie ochrony i przetwarzania danych osobowych jest możliwy:</p>
          <ul>
            <li>listownie na adres: BULLAPP.CLOUD SP. Z O.O., ul. Hoża 86 lok. 410, 00-682 Warszawa</li>
            <li>
              mailowo na adres: <img style={{ verticalAlign: 'middle', height: '22px', width: '191px', filter: isDarkMode ? 'invert(0.85)' : null }} src='/images/invee/25261f57-739a-43cb-a619-1cde51fe9f07.png' alt='inwitki' />
            </li>
            <li>telefonicznie pod numerem: wkrótce</li>
          </ul>
        </div>
        <div className=' w-full lg:w-2/3 text-justify'>
          <h3>Cele i podstawy przetwarzania danych</h3>
          <p>Aby świadczyć usługi poprzez Serwis, Spółka przetwarza Pana/Pani dane osobowe — w różnych celach, jednak zawsze zgodnie z prawem. Poniżej znajdzie Pan/Pani wyszczególnione cele przetwarzania danych osobowych wraz z podstawami prawnymi.</p>
          <p>
            Regulacją, która stanowi źródło praw i obowiązków podmiotów przetwarzających dane osobowe jak i podmiotów, których dane są przetwarzane jest w głównej mierze Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (określane popularnie jako „RODO”) oraz ustawa o ochronie danych osobowych z 10
            maja 2018 roku.
          </p>
          <h4>Spełnienie obowiązków wynikających z przepisów prawa podatkowego</h4>
          <p>W celu spełnienia obowiązków wynikających z przepisów prawa podatkowego, takich jak np. przechowywanie dokumentacji księgowej przez 5 lat, o ile użytkownik wskaże takie dane i zażąda wystawienia faktury VAT, przetwarzamy takie dane osobowe, jak:</p>
          <ol>
            <li>imię i nazwisko</li>
            <li>adres e-mail</li>
            <li>adres zamieszkania</li>
            <li>numer identyfikacji podatkowej (NIP)</li>
          </ol>
          <p>Podstawą prawną takiego przetwarzania danych jest art. 6 ust. 1 lit. c RODO, który pozwala przetwarzać dane osobowe, jeżeli takie przetwarzanie jest konieczne do wywiązania się przez Administratora Danych Osobowych z obowiązków wynikających z prawa;</p>
          <h4>Tworzenie rejestrów i ewidencji związanych z RODO</h4>
          <p>W celu tworzenia rejestrów i ewidencji związanych z RODO, w tym np. rejestru użytkowników Serwisu, którzy zgłosili sprzeciw zgodnie z RODO, przetwarzamy takie dane osobowe, jak:</p>
          <ol>
            <li>imię i nazwisko</li>
            <li>adres e-mail</li>
          </ol>
          <p>ponieważ, przepisy RODO nakładają na nas określone obowiązki dokumentacyjne dla wykazania zgodności i rozliczalności.</p>
          <p>
            Podstawą prawną takiego przetwarzania danych jest po pierwsze, art. 6 ust. 1 lit. c RODO, który pozwala przetwarzać dane osobowe, jeżeli takie przetwarzanie jest konieczne do wywiązania się przez Administratora Danych Osobowych z obowiązków wynikających z prawa; po drugie, art. 6 ust. 1 lit. f RODO, który pozwala przetwarzać dane osobowe, jeżeli tym sposobem Administrator Danych Osobowych realizuje swój prawnie uzasadniony interes (w tym przypadku interesem Spółki jest posiadanie
            wiedzy na temat osób, które realizują swoje uprawnienia wynikające z RODO);
          </p>
          <h4>Logowanie użytkownika do serwisu</h4>
          <p>Logowanie użytkownika do Serwisu następuje za pomocą zewnętrznych dostawców tożsamości (Google, Facebook). Podczas logowania, za zgodą użytkownika, od zewnętrznego dostawcy tożsamości do Serwisu przekazywane są:</p>
          <ol>
            <li>adres e-mail</li>
            <li>identyfikator ID</li>
          </ol>
          <p>Powyższe dane są przetwarzane w celu identyfikacji użytkowników. Podstawą prawną takiego przetwarzania danych jest art. 6 ust. 1 lit. b RODO, który pozwala przetwarzać dane osobowe, jeżeli są one konieczne do wykonania umowy lub podjęcia czynności zmierzających do zawarcia umowy.</p>
          <p>Dane służące do identyfikacji użytkowników są przechowywane przez Serwis tak długo jak użytkownik utrzymuje aktywne konto w Serwisie. Dane to są usuwane natychmiastowo w wyniku wybrania opcji usunięcia konta użytkownika w Serwisie.</p>
          <h4>Tworzenie projektu Inwitki</h4>
          <p>Użytkownik tworząc projekt Inwitki, opcjonalnie podaje swoje dane osobowe, które będzie udostępniał w postaci wygenerowanej Inwitki zapraszanym gościom:</p>
          <ol>
            <li>Imię i nazwisko - o ile chce żeby jego imię i nazwisko znalazło się na wygenerowanej Inwitce.</li>
            <li>Adres e-mail - o ile chce żeby jego adres e-mail znalazł się na wygenerowanej Inwitce.</li>
            <li>Nr telefonu - o ile chce żeby jego nr telefonu znalazł się na wygenerowanej Inwitce.</li>
            <li>Adres zamieszkania - o ile adres wydażenia jest tożsamy z jego adresem zamieszkania i chce żeby jego adres zamieszkania znalazł się na wygenerowanej Inwitce.</li>
            <li>Koordynaty GPS - o ile chce żeby koordynaty GPS znalazły się na wygenerowanej Inwitce.</li>
          </ol>
          <p>Powyższe dane są przetwarzane w celu stworzenia projektu Inwitki. Podstawą prawną takiego przetwarzania danych jest art. 6 ust. 1 lit. b RODO, który pozwala przetwarzać dane osobowe, jeżeli są one konieczne do wykonania umowy lub podjęcia czynności zmierzających do zawarcia umowy.</p>
          <p>Projekt Inwitki, wraz z danymi osobowymi użytkownika (o ile zostały przez niego podane) jest usuwany ze zbiorów Serwisu w następujących przypadkach:</p>
          <ol>
            <li>Nie później niż 30 dni po dacie wygaśnięcia Inwitki.</li>
            <li>W wyniku wybrania opcji usunięcia projektu przez użytkownika w Serwisie.</li>
          </ol>
          <h4>Generowanie Inwitki</h4>
          <p>Użytkownik na podstawie projektu generuje Inwitkę podając imię gościa, które co do zasady nie jest daną osobową.</p>

          <h4>Wyświetlanie Inwitki</h4>
          <p>
            Wygenerowana Inwitka (cyfrowe zaproszenie) jest publiczną stroną internetową lub ekranem w aplikacji mobilnej. Użytkownik Serwisu udostępnia swoim gościom Inwitki w postaci unikalnych adresów URL lub kodów QR. Wszystkie dane osobowe wprowadzone przez użytkownika do projektu Inwitki będą udostępnione pod powyższym adresem URL lub kodem QR. Oznacza to, że każda osoba posiadająca powyższy adres URL lub kod QR może wyświetlić dane osobowe użytkownika, o ile zostały podane. Użytkownik
            udostępnia wygenerowane Inwitki wskazanym gościom, jednocześnie akceptując fakt, że goście mają techniczną możliwość przekazać adres URL lub kod QR osobom trzecim lub opublikować je w domenie publicznej, czego Serwis nie jest w stanie kontrolować i zablokować.
          </p>
          <p>Powyższe dane są przetwarzane w celu wyświetlenia projektu Inwitki. Podstawą prawną takiego przetwarzania danych jest art. 6 ust. 1 lit. b RODO, który pozwala przetwarzać dane osobowe, jeżeli są one konieczne do wykonania umowy lub podjęcia czynności zmierzających do zawarcia umowy.</p>

          <h4>Więcej informacji</h4>
          <p>Informacje na temat przetwarzania danych przez Serwis znajdziesz również na stronach:</p>
          <ul>
            <li>
              <a target='_blank' rel='noreferrer' href='/pages/data-processing'>
                Przetwarzanie Danych
              </a>
            </li>
            <li>
              <a target='_blank' rel='noreferrer' href='/pages/regulations'>
                Regulamin
              </a>
            </li>
          </ul>
        </div>
        <div className=' w-full lg:w-2/3 text-justify'>
          <h3>Pliki cookie</h3>
          <ol>
            <li>Spółka w ramach Serwisu podobnie jak inne podmioty, wykorzystuje pliki cookie, czyli krótkie informacje tekstowe, zapisywane na komputerze, telefonie, tablecie, czy też innym urządzeniu użytkownika. Mogą być one odczytywane przez nasz system, a także przez systemy należące do innych podmiotów, z których usług korzystamy (np. Facebook, Google):</li>
            <li>
              Pliki cookie spełniają następujące funkcje w Serwisie:
              <ol>
                <li>
                  Tworzenie statystyk - pliki cookies są wykorzystywane do tego, aby przeanalizować, w jaki sposób użytkownicy korzystają ze strony internetowej (jak wielu otwiera stronę internetową, jak długo na niej pozostają, które treści wzbudzają największe zainteresowanie etc.). Dzięki temu można stale ulepszać stronę internetową i dostosowywać jej działanie do preferencji użytkowników. W celu śledzenia aktywności i tworzenia statystyk wykorzystujemy narzędzia Google Analytics.{' '}
                  <a target='_blank' rel='noreferrer' href='https://policies.google.com/technologies/cookies?hl=pl'>
                    Jak Google korzysta z plików cookie
                  </a>
                </li>
              </ol>
            </li>
            <li>Serwis wykorzystuje platformę Cookiebot w celu zarządzania zgodą użytkownika na wykorzystanie plików cookie.</li>
            <li>Pani/Pana przeglądarka internetowa domyślnie dopuszcza wykorzystywanie cookies w Pana/Pani urządzeniu. Jeżeli jednak nie życzy Pan/Pani sobie wykorzystania cookies przy przeglądaniu strony internetowej, można zmienić ustawienia w przeglądarce internetowej — całkowicie blokować automatyczną obsługę plików cookies lub żądać powiadomienia o każdorazowym zamieszczeniu cookies w urządzeniu. Ustawienia można zmienić w dowolnej chwili.</li>
          </ol>
        </div>
        <div className=' w-full lg:w-2/3 text-justify'>
          <h3>Local Storage</h3>
          <ol>
            <li>Spółka w ramach Serwisu podobnie jak inne podmioty, wykorzystuje Local Storage, czyli przechowuje dane w przeglądarce na komputerze, telefonie, tablecie, czy też innym urządzeniu użytkownika bez daty ważności (dane pozostaną dostępne nawet po zamknięciu karty/okna przeglądarki).</li>
            <li>
              Local Storage spełnia następujące funkcje w Serwisie:
              <ol>
                <li>Przechowywanie informacji o zalogowaniu użytkownika</li>
                <li>Przechowywanie informacji o udzielonych zgodach na zapisywanie plików cookie oraz local storage</li>
                <li>Przechowywanie informacji o wybranym trybie graficznym</li>
                <li>Przechowywanie informacji o ostatnio wygenerowanych Inwitkach</li>
              </ol>
            </li>
            <li>Local Storage jest niezbędny do prawidłowego działania serwisu.</li>
          </ol>
        </div>
        <div className=' w-full lg:w-2/3 text-justify'>
          <h3>Prawo wycofania zgody</h3>
          <ol>
            <li>Jeżeli przetwarzanie danych osobowych odbywa się na podstawie zgody, w każdej chwili może Pan/Pani tę zgodę cofnąć — wedle własnego uznania.</li>
            <li>Jeżeli chciałby/chciałaby Pan/Pani cofnąć zgodę na przetwarzanie danych osobowych, to w tym celu wystarczy wysłać maila bezpośrednio na adres ……………………………… </li>
            <li>Jeżeli przetwarzanie Pana/Pani danych osobowych odbywało się na podstawie zgody, jej cofnięcie nie powoduje, że przetwarzanie danych osobowych do tego momentu było nielegalne. Innymi słowy, do czasu cofnięcia zgody mamy prawo przetwarzać Pana/Pani dane osobowe i jej odwołanie nie wpływa na zgodność z prawem dotychczasowego przetwarzania.</li>
          </ol>
        </div>
        <div className=' w-full lg:w-2/3 text-justify'>
          <h3>Wymóg podania danych osobowych</h3>
          <ol>
            <li>Podanie jakichkolwiek danych osobowych jest dobrowolne i zależy od Pana/Pani decyzji. Jednakże w niektórych przypadkach podanie określonych danych osobowych jest konieczne, aby spełnić Pana/Pani oczekiwania w zakresie korzystania z usług Serwisu.</li>
            <li>Aby móc się skontaktować z Panem/Panią telefonicznie w sprawach związanych z realizacją usług, konieczne jest podanie numeru telefonu — bez tego nie jesteśmy w stanie nawiązać kontaktu telefonicznego.</li>
          </ol>
        </div>
        <div className=' w-full lg:w-2/3 text-justify'>
          <h3>Zautomatyzowane podejmowanie decyzji i profilowanie</h3>
          <p>Uprzejmie informujemy, że nie dokonujemy zautomatyzowanego podejmowania decyzji, w tym w oparciu o profilowanie.</p>
        </div>
        <div className=' w-full lg:w-2/3 text-justify'>
          <h3>Odbiorcy danych osobowych</h3>
          <p>
            Jak większość przedsiębiorców, w swojej działalności korzystamy z pomocy innych podmiotów, co niejednokrotnie wiąże się z koniecznością przekazania danych osobowych. W związku z powyższym, w razie potrzeby, przekazujemy Pana/Pani dane osobowe współpracującym z nami, którzy realizują usługi dla Spółki, biuru księgowemu, firmie doradztwa podatkowego (w przypadku skomplikowanych transakcji), dostawcy usług chmurowych, a także firmie ubezpieczeniowej (gdyby zaszła konieczność naprawienia
            szkody).
          </p>
        </div>
        <div className=' w-full lg:w-2/3 text-justify'>
          <h3>Przekazywanie danych osobowych do państw trzecich</h3>
          <ol>
            <li>Jak większość przedsiębiorców, korzystamy z różnych popularnych usług i technologii, oferowanych przez takie podmioty, jak Facebook, Google, Amazon Web Services. Firmy te mają siedziby poza Unią Europejską, a zatem w świetle przepisów RODO są traktowane jako państwa trzecie.</li>
            <li>Ze swojej strony zapewniamy, że przy korzystaniu z usług i technologii przekazujemy dane osobowe wyłącznie podmiotom ze Stanów Zjednoczonych i wyłącznie takim, które oferują technologie w procesie przetwarzania danych osobowych zgodne z prawem. </li>
          </ol>
        </div>
        <div className=' w-full lg:w-2/3 text-justify'>
          <h3>Uprawnienia podmiotów danych</h3>
          <ol>
            <li>
              Uprzejmie informujemy, że posiada Pani/Pan prawo do:
              <ul>
                <li>dostępu do swoich danych osobowych</li>
                <li>do przenoszenia danych</li>
                <li>poprawiania danych</li>
                <li>sprostowania danych</li>
                <li>usunięcia danych</li>
                <li>wniesienia sprzeciwu wobec przetwarzania danych</li>
                <li>bycia zapomnianym</li>
                <li>wniesienia skargi do organu nadzorczego</li>
                <li>ograniczenia przetwarzania</li>
              </ul>
            </li>
            <li>Szanujemy Pani/Pana prawa wynikające z przepisów o ochronie danych osobowych i staramy się ułatwiać ich realizację w najwyższym możliwym stopniu.</li>
            <li>Wskazujemy, że wymienione uprawnienia nie mają charakteru absolutnego, a zatem w niektórych sytuacjach możemy zgodnie z prawem odmówić Panu/Pani ich spełnienia. Jednakże, jeżeli odmawiamy uwzględnienia żądania, to tylko po wnikliwej analizie i tylko w sytuacji, gdy odmowa uwzględnienia żądania jest konieczna.</li>
          </ol>
        </div>
        <div className=' w-full lg:w-2/3 text-justify'>
          <h3>Prawo do wniesienia skargi</h3>
          <p>Jeżeli uważa Pan/Pani, że Pana/Pani dane osobowe są przetwarzane niezgodnie z obowiązującym prawem, może Pan/Pani wnieść skargę do Prezesa Urzędu Ochrony Danych Osobowych.</p>
        </div>
        <div className=' w-full lg:w-2/3 text-justify'>
          <h3>Postanowienia końcowe</h3>
          <ol>
            <li>W zakresie nieuregulowanym niniejszą Polityką prywatności obowiązują przepisy prawa polskiego.</li>
            <li>O wszelkich zmianach wprowadzonych do niniejszej Polityki prywatności zostanie Pan/Pani powiadomiony/powiadomiona drogą e-mailową.</li>
          </ol>
        </div>
      </div>
    </StylesWrapper>
  );
};

PrivacyPolicyContent.propTypes = {};

export default PrivacyPolicyContent;
