import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useScreen } from 'src/hooks/useScreen';
import useTheme from 'src/hooks/useTheme';
import useConsents from 'src/hooks/useConsents';
import { Drawer, Space, QRCode, Row, Col } from 'antd';
import { FloatButton } from 'antd';
import { MenuOutlined, CloseOutlined } from '@ant-design/icons';
import DarkModeControl from '../darkModeControl/DarkModeControl';
import StyledButton from 'src/components/styled/StyledButton';
import { getInveeUrl, handleDownloadAppStore, handleDownloadPlayStore } from 'src/misc/Misc';
import { ReactComponent as InveeLogo } from 'src/components/svg/invee-logo.svg';
import { ReactComponent as AppStoreBadge } from 'src/components/svg/badge-app-store.svg';
import { ReactComponent as PlayStoreBadge } from 'src/components/svg/badge-play-store.svg';
import { FaYoutube } from 'react-icons/fa';
import { FaFacebook } from 'react-icons/fa';
import { GoLaw } from 'react-icons/go';
import { LiaUserSecretSolid } from 'react-icons/lia';
import { MdOutlineCookie } from 'react-icons/md';
import { HomeOutlined } from '@ant-design/icons';
import * as Ga4 from 'src/misc/Ga4';
// import queryString from 'query-string';

// var showStoresTimeout = null;

const StyledQRCode = styled.div`
  .ant-qrcode {
    border: none !important;
  }
`;

const MobileAppsDrawer = ({ inveeString, style }) => {
  const { theme, themeLight } = useTheme();
  const { isDesktop, screenSize } = useScreen();
  const { setCookieConsentsOpen } = useConsents();

  const [open, setOpen] = useState(false);
  // const [showStores, setShowStores] = useState(false);
  const [zoom, setZoom] = useState('1.0');

  //const { debug } = queryString.parse(window.location.search);

  useEffect(() => {
    if (1400 < screenSize.width) {
      setZoom('1.8');
    } else if (1200 < screenSize.width) {
      setZoom('1.6');
    } else if (1000 < screenSize.width) {
      setZoom('1.4');
    } else if (800 < screenSize.width) {
      setZoom('1.2');
    } else {
      setZoom('1.0');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenSize.width]);

  // const handleOpenInApp = async () => {
  //   try {
  //     setShowStores(true);
  //     if (debug === '1') window.location.replace(`exp://192.168.1.164:8081/?i=${inveeString}`); // TEST IN EXPO
  //     else window.location.replace(`com.bullapp.inwitki://?i=${inveeString}`); // REAL USE IN THE APP
  //     if (showStoresTimeout) clearTimeout(showStoresTimeout);
  //     showStoresTimeout = setTimeout(() => setShowStores(false), 5000);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  return (
    <>
      <FloatButton
        style={{ zoom }}
        className='opacity-90 hover:opacity-100'
        icon={<MenuOutlined />}
        description='Menu'
        shape='square'
        onClick={() => {
          setOpen(true);
        }}
      />

      <Drawer
        title={
          <div style={{ color: style.bgColor }} className='font-roboto-slab flex flex-row gap-2 items-center'>
            <InveeLogo height='24px' width='15px' />
            <div className='text-xl'>
              inwitki<span className='opacity-60'>.net</span>
            </div>
          </div>
        }
        styles={{ body: { padding: '8px' }, header: { borderBottom: `1px solid ${style.bgColor}` } }}
        placement='bottom'
        open={open}
        closeIcon={<></>}
        closable={false}
        maskClosable={false}
        destroyOnClose={true}
        onClose={() => setOpen(false)}
        height={'auto'}
        style={{ backgroundColor: style.color }}
        extra={
          <Space>
            <DarkModeControl color={style.bgColor} />
            <StyledButton
              color={style.bgColor}
              bgColor='transparent'
              onClick={() => {
                setOpen(false);
              }}
            >
              <Space className='btn'>{<CloseOutlined />}</Space>
            </StyledButton>
          </Space>
        }
      >
        <div className='flex flex-row justify-center'>
          <div style={{ width: theme.centredTo }}>
            {isDesktop ? (
              <>
                <Row>
                  <Col span={12}>
                    <div className='flex flex-col gap-2 justify-start items-start h-full'>
                      <div className='flex-initial flex flex-col gap-2 justify-start items-start'>
                        <StyledButton color={style.bgColor} bgColor='transparent' style={{ width: '180px' }} onClick={handleDownloadAppStore}>
                          <AppStoreBadge />
                        </StyledButton>
                        <StyledButton color={style.bgColor} bgColor='transparent' style={{ width: '180px' }} onClick={handleDownloadPlayStore}>
                          <PlayStoreBadge />
                        </StyledButton>
                      </div>
                      <div className='flex-auto flex flex-col gap-2 justify-end items-start' style={{ color: style.bgColor }}>
                        <div
                          className='flex flex-row gap-1 items-center cursor-pointer text-sm hover:opacity-80'
                          onClick={() => {
                            window.open('/', '_blank');
                          }}
                        >
                          <Space className='btn'>
                            <HomeOutlined />
                            Strona domowa
                          </Space>
                        </div>
                        <div
                          className='flex flex-row gap-1 items-center cursor-pointer text-sm hover:opacity-80 '
                          onClick={() => {
                            window.open('/pages/regulations', '_blank');
                          }}
                        >
                          <Space className='btn'>
                            <GoLaw />
                            Regulamin
                          </Space>
                        </div>
                        <div
                          className='flex flex-row gap-1 items-center cursor-pointer text-sm hover:opacity-80 '
                          onClick={() => {
                            window.open('/pages/privacy-policy', '_blank');
                          }}
                        >
                          <Space className='btn'>
                            <LiaUserSecretSolid />
                            Polityka Prywatności
                          </Space>
                        </div>
                        <div
                          className='flex flex-row gap-1 items-center cursor-pointer text-sm hover:opacity-80 '
                          onClick={() => {
                            setOpen(false);
                            setCookieConsentsOpen(true);
                          }}
                        >
                          <Space className='btn'>
                            <MdOutlineCookie />
                            Cookies
                          </Space>
                        </div>
                        <div
                          className='flex flex-row gap-1 items-center cursor-pointer text-sm hover:opacity-80 '
                          onClick={() => {
                            Ga4.event('Footer', 'Go_to_facebook', 'Go_to facebook');
                            window.open('https://www.facebook.com/inwitki.net/', '_blank');
                          }}
                        >
                          <Space className='btn'>
                            <FaFacebook />
                            Facebook
                          </Space>
                        </div>
                        <div
                          className='flex flex-row gap-1 items-center cursor-pointer text-sm hover:opacity-80 '
                          onClick={() => {
                            Ga4.event('Footer', 'Go_to_youtube', 'Go_to youtube');
                            window.open('https://www.youtube.com/@inwitki', '_blank');
                          }}
                        >
                          <Space className='btn'>
                            <FaYoutube />
                            Youtube
                          </Space>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col span={12} className='text-right'>
                    <div className='flex flex-col gap-2 items-end h-full'>
                      <div style={{ position: 'relative', width: '280px', backgroundColor: themeLight.light1, borderRadius: '1rem', border: `1px solid ${themeLight.dark3}`, color: themeLight.dark4 }}>
                        <div className='p-4 flex flex-col items-center overflow-hidden'>
                          <InveeLogo style={{ height: '32px', color: theme.logoBlue }} />
                          <div className='font-roboto-slab font-bold'>
                            inwitki<span className='opacity-60'>.net</span>
                          </div>
                          <StyledQRCode>
                            <QRCode value={getInveeUrl(inveeString)} color='black' />
                          </StyledQRCode>

                          <div className='break-words w-11/12 font-bold text-center'>
                            Zeskanuj inwitkę
                            <br />
                            smartfonem
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <div className='my-4 flex-auto flex flex-col gap-4 xl:gap-2 justify-center items-center' style={{ color: style.bgColor }}>
                  <div
                    className='flex flex-row gap-1 items-center cursor-pointer text-sm hover:opacity-80 '
                    onClick={() => {
                      window.open('/', '_blank');
                    }}
                  >
                    <Space className='btn'>Strona domowa</Space>
                  </div>
                  <div
                    className='flex flex-row gap-1 items-center cursor-pointer text-sm hover:opacity-80 '
                    onClick={() => {
                      window.open('/pages/regulations', '_blank');
                    }}
                  >
                    <Space className='btn'>Regulamin</Space>
                  </div>
                  <div
                    className='flex flex-row gap-1 items-center cursor-pointer text-sm hover:opacity-80 '
                    onClick={() => {
                      window.open('/pages/privacy-policy', '_blank');
                    }}
                  >
                    <Space className='btn'>Polityka Prywatności</Space>
                  </div>
                  <div
                    className='flex flex-row gap-1 items-center cursor-pointer text-sm hover:opacity-80 '
                    onClick={() => {
                      setOpen(false);
                      setCookieConsentsOpen(true);
                    }}
                  >
                    <Space className='btn'>Cookies</Space>
                  </div>
                  <div
                    className='flex flex-row gap-1 items-center cursor-pointer text-sm hover:opacity-80 '
                    onClick={() => {
                      Ga4.event('Footer', 'Go_to_facebook', 'Go_to facebook');
                      window.open('https://www.facebook.com/inwitki.net/', '_blank');
                    }}
                  >
                    <Space className='btn'>Facebook</Space>
                  </div>
                  <div
                    className='flex flex-row gap-1 items-center cursor-pointer text-sm hover:opacity-80 '
                    onClick={() => {
                      Ga4.event('Footer', 'Go_to_youtube', 'Go_to youtube');
                      window.open('https://www.youtube.com/@inwitki', '_blank');
                    }}
                  >
                    <Space className='btn'>Youtube</Space>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </Drawer>
    </>
  );
};

MobileAppsDrawer.propTypes = {
  inveeString: PropTypes.string,
  style: PropTypes.object,
};

export default MobileAppsDrawer;
