import React from 'react';
import styled from 'styled-components';
//import PropTypes from 'prop-types';

const StylesWrapper = styled.div`
  // text-align: justify;
  ul > li {
    margin-block-start: 1em;
    margin-block-end: 1em;
  }

  ol {
    counter-reset: item;
  }

  ol > li {
    counter-increment: item;
    margin-block-start: 1em;
    margin-block-end: 1em;
  }

  ol ol > li {
    display: block;
  }

  ol ol > li:before {
    content: counters(item, '.') '. ';
    margin-left: -20px;
  }

  #page {
    counter-reset: heading;
  }

  h3:before {
    content: '§' counter(heading) ' ';
    counter-increment: heading;
  }

  h3 {
    counter-reset: subheading;
  }

  h4:before {
    content: counter(heading) '.' counter(subheading) '. ';
    counter-increment: subheading;
  }
`;

const RegulationsContent = () => {
  return (
    <StylesWrapper>
      <div className='my-4 lg:my-4 flex flex-col items-center gap-2' id='page'>
        <div className=' w-full lg:w-2/3 text-justify'>
          <h3>Definicje</h3>
          <p>Użyte poniżej w niniejszym regulaminie treści (zwroty) oznaczają odpowiednio:</p>
          <ol>
            <li>
              <strong>Serwis</strong> - platforma internetowa działająca w ogólnodostępnej sieci internetowej (on-line) pod adresem https://inwitki.net której właścicielem i administratorem jest Sprzedawca.
            </li>
            <li>
              <strong>Aplikacja Mobilna</strong> - aplikacja na smartfony/urządzenia mobilne z systemem operacyjnym Android lub iOS pozwalająca otwierać, przechowywać w pamięci urządzenia oraz wyświetlać na ekranie Inwitki.
            </li>
            <li>
              <strong>Regulamin</strong> - niniejszy dokument regulujący zasady korzystania z Serwisu i realizowania poprzez Serwis usług przez Sprzedawcę.
            </li>
            <li>
              <strong>Sprzedawca</strong>
              <p>
                BULLAPP.CLOUD SP. Z O.O.
                <br />
                ul. Hoża 86 lok. 410
                <br />
                00-682 Warszawa
                <br />
                NIP: 7011202892
                <br />
                REGON: 528485891
                <br />
                KRS: 0001103080
              </p>
            </li>

            <li>
              <strong>Użytkownik </strong> - pełnoletnia osoba fizyczna posiadająca pełną zdolność do czynności prawnych, mogąca we własnym imieniu nabywać prawa i zaciągać zobowiązania, korzystająca z usług oferowanych przez Sprzedawcę w ramach Serwisu.
            </li>
            <li>
              <strong>Inwitka</strong> - odpłatna usługa świadczona drogą elektroniczną przez Sprzedawcę w ramach Serwisu, umożliwiająca Użytkownikowi stworzenie i wysłanie on-line jednego, spersonalizowanego, imiennego zaproszenia na wydarzenia okolicznościowe organizowane przez Użytkownika. Inwitka jest dostępna za pośrednictwem unikalnego adresu URL i może zostać wysłana w postaci linku lub kodu QR zawierającego ten link, na adres e-mail, nr telefonu, komunikator zaproszonego gościa. Kod QR
              zawierający adres URL Inwitki może zostać także nadrukowany na papierowe zaproszenie w celu zeskanowania go aparatem fotograficznym w smartfonie/urządzeniu mobilnym. Koszt usługi związanej z generowaniem Inwitki będzie każdorazowo wskazywany w Serwisie w procesie tworzenia Inwitki, w cenie brutto tj. z podatkiem VAT.
            </li>

            <li>
              <strong>Konto</strong> - indywidualny profil Użytkownika zawierający listę Projektów Inwitek oraz liczbę Kredytów. Użytkownik tworzy Konto logowaniem się do Serwisu z użyciem swojego profilu w platformie Google lubFacebook.
            </li>
            <li>
              <strong>Projekt Inwitki</strong> - stworzony w Serwisie przez Użytkownika rodzaj szablonu, który może być wielokrotnie wykorzystywany do generowania Inwitek. Projekt Inwitki zawiera jej treść oraz wybór schmatu graficznego.
            </li>
            <li>
              <strong>Kredyty</strong> - przypisana Użytkownikowi dana ilość jednostek kredytowych, które pozwalają na wygenerowanie Inwitek. Zakup Kredytów odbywa się poprzez platformę realizującą płatności: Przelewy24, zgodnie z regulaminem tej platformy płatniczej. Ilość Kredytów jest każdorazowo wskazana w ramach Konta.
            </li>
          </ol>
          <h3>Dane kontaktowe</h3>
          <p>
            Sprzedawca udostępnia Użytkownikowy dedykowaną podstronę, na której znajdują się aktualne dane kontaktowe do korespondencji:{' '}
            <a href='/pages/contact' target='_blank'>
              https://inwitki.net/pages/contact
            </a>
          </p>
          <h3>Korzystanie z Serwisu</h3>
          <ol>
            <li>Korzystanie z Serwisu nie wymaga od Użytkowników podawania swoich jak i swoich gości danych osobowych zapewniając w tym zakresie dużo bezpieczniejsze rozwiązania. Serwis nie tworzy i nie zawiera w ramach Kont Użytkowników ich danych osobowych, korzystając w tym zakresie wyłącznie z zewnętrznych dostawców tożsamości to jest platform Google, Facebook.</li>
            <li>Po zalogowaniu się Użytkownika, w zbiorach danych Serwisu przechowywany jest wyłącznie identyfikator (zakodowany ciąg znaków) oraz adres email Użytkownika. </li>
            <li>Użytkownik w Serwisie ma w każdym czasie możliwość trwałego wykasowania swojego Konta.</li>
            <li>Sprzedawca stale czyni starania, aby korzystanie z Serwisu spełniało oczekiwania Użytkowników, jednak z przyczyn niezależnych od Sprzedawcy, okoliczności siły wyższej, którym Sprzedawca nie mógł przeciwdziałać wcześniej, mogą mieć miejsce przerwy w dostępie do Serwisu, brak możliwości połączenia się z Serwisem lub ustereki natury informatycznej.</li>
            <li>Celem skorzystania z Serwisu, jego Użytkownik powinien posiadać urządzenie końcowe (komputer/urządzenie mobilne), dostęp do sieci internet poprzez aktualną przeglądarkę, swoje aktywne konto w platformie Google lub Facebook oraz możliwość zakupu Kredytów.</li>
            <li>Umowa pomiędzy Użytkownikiem, a Sprzedawcą jest zawierana w momencie zakupu Kredytów. Zakup jednego Kredytu pozwala na wygenerowanie jednej Inwitki. Sprzedaż Kredytów odbywa się za pośrednictwem sieci internet, w formie elektronicznej jako umowa zawierana na odległość pomiędzy Sprzedawcą, a Użytkownikiem.</li>
            <li>Zakup Kredytów odbywa się w złotych polskich, stanowi cenę brutto.</li>
            <li>Umowa polega na świadczeniu przez Sprzedawcę usługi w formie elektronicznej pozwalającej Użytkownikowi na opłacenie Inwitki z użyciem Kredytów. Inwitka pozwala na stworzenie własnego, imiennego zaproszenia w formie graficznej (Inwitki), które może być edytowane, redagowane w ramach funkcjonalności Serwisu i finalnie udostępniane w postaci unikalnego adresu URL, który może być rozsyłany do wskazanych gości w formie linku lub kodu QR zawierającego ten link. </li>
            <li>Dla każdego projektu Inwitki generowany jest unikalny klucz szyfrujący. Klucz szyfrujący jest przechowywany w bazie danych serwisu jako atrybut projektu Inwitki. Skasowanie projektu Inwitki - bezpowrotnie kończy możliwość odszyfrowania imienia gościa będącego częścią adresu URL Inwitki i kodu QR, gdyż kasowany jest klucz szyfrujący. Serwis nie utrwala i nie przechowuje danych gości w swoich zbiorach w jakiejkolwiek postaci (zaszyfrowanej, bądź niezaszyfrowanej).</li>
            <li>Sprzedawca ma prawo odmówić realizacji Inwitki w przypadku, gdy jej treść lub sposób korzystania przez Użytkownika z Serwisu narusza Regulamin lub przepisy prawa.</li>
            <li>Sprzedawca nie odpowiada za treści umieszczane przez Użytkowników w Inwitkach. Pełną odpowiedzialność za umieszczane treści ponosi Użytkownik.</li>
            <li>Sprzedawca nie odpowiada za imiona podawane przez Użytkowników w Inwitkach. Pełną odpowiedzialność za umieszczane imiona ponosi Użytkownik.</li>
          </ol>
          <h3>Tworznie inwitki</h3>
          <ol>
            <li>Po wejściu do Serwisu należy wybrać zakładkę „Stwórz zaproszenie”.</li>
            <li>Następnie należy stworzyć Konto poprzez zalogowanie się do Serwisu z użyciem swojego konta (profilu) w platformie Google lub Facebook.</li>
            <li>Następnie należy wybrać zakładkę: „Stwórz nowy projekt” i stworzyć swoje własne oryginalne zaproszenie dokonując odpowiednio wyborów i edycji w zakresie rodzaju wydarzenia, stylu zaproszenia, użytej czcionki, treści zaproszenia, lokalizacji wydarzenia. Treść Inwitki jest każdorazowo zapisywana, na każdym etapie tworzenia zaproszenia. Projekty Inwitek są przechowywane przez Serwis w zakładce Moje projekty.</li>
            <li>Serwis nie zapisuje (nie gromadzi) imion gości wskazanych w Inwitce, są one zaszyfrowane w linkach do Inwitki lub kodach QR. W trakcie generowania, Serwis udostępnia funkcjonalność zaszyfrowania imion gości. Podczas otwierania Inwitki na urządzeniu gościa, Serwis umożliwia odszyfrowanie i wyświetlenie imienia. Klucz szyfrujący jest przechowywany w bazie danych Serwisu i jest on unikalny dla każdego projektu Inwitki.</li>
            <li>Użytkownik ma możliwość załadowania do treści Inwitki współrzędnych geograficznych wydarzenia, które mogą być następnie otwierane za pomocą platformy https://www.google.pl/maps.</li>
            <li>Po zakończeniu edycji projektu Inwitki należy ją wygenerować, poprzez podanie imienia osoby zaproszonej (gościa), a następnie wybrać sposób jej przekazania gościowi. Na podstawie jednego projektu można wygenerować wiele imiennych Inwitek, a następnie przekazać je gościom w postaci linków lub kodów QR.</li>
            <li>
              Po wygenerowaniu Inwitki Użytkownik powinien niezwłocznie pobrać ją w postaci linku i/lub pliku graficznego zawierającego kod QR na swój lokalny dysk i z tą chwilą Użytkownik odpowiada za przechowywanie lub skasowanie Inwitki. Po stronie Serwisu jest przechowywany projekt Inwitki zawierający jej treść oraz szatę graficzną. Wygenerowana imienna Inwitka w postaci linku lub kodu QR zawiera informację o projekcie Inwitki, który ma zostać użyty do jej wyświetlenia na urządzeniu
              zaproszonego gościa oraz zaszyfrowane imię gościa.
            </li>
            <li>Zarówno projekt Inwitki przechowywany przez Serwis jak i pobrany na urządzenie Użytkownika link lub kod QR są niezbędne do wyświetlenia Inwitki na urządzeniu gościa. Usunięcie przynajmniej jednego z powyższych elementów uniemożliwia jej wyświetlenie.</li>
            <li>Celem wygenerowania jednej imiennej Inwitki, Użytkownik musi posiadać zakupiony co najmniej jeden Kredyt, który nie wygasł i nie został dotychczas zużyty celem wygenerowania innej Inwitki. Wygenerowanie imiennej Inwitki pomniejsza liczbę posiadanych Kredytów o jeden. Operacji wygenerowania Inwitki nie można cofnąć.</li>
            <li>Użytkownik przekazuje adres URL lub kod QR spersonalizowanej (imiennej) Inwitki swojemu gościowi. W momencie, gdy gość otwiera link z Inwitką, Serwis pobiera treść oraz szatę graficzną Inwitki ze swojej bazy danych oraz odszyfrowuje imię gościa z adresu i wyświetla całość na ekranie.</li>
            <li>Gość może otworzyć Inwitkę za pomocą przeglądarki internetowej lub Aplikacji Mobilnej.</li>
            <li>Zakup Kredytów wymaga uprzedniego potwierdzenia przez Użytkownika zapoznania się z treścią oraz zaakceptowania Regliaminu.</li>
            <li>Treść Inwitki nie może stanowić: naruszenia prawa, w tym dóbr osób trzecich, wligaryzmów, naruszenia uczuć religijnych i dobrych obyczajów.</li>
            <li>Ważność Kredytu wynosi 3 (trzy) miesięce od daty zakupu. Po upływie trzech miesięcy Kredyt traci ważność i jest bezpowrotnie kasowany z konta Użytkownika.</li>
            <li>Ważność projektu Inwitki wynosi 6 (sześć) miesięcy od daty jego utworzenia. Po upływie sześciu miesięcy projekt traci ważność i jest bezpowrotnie kasowany z Serwisu. Wobec powyższego, data Imprezy wskazywana przez Użytkownika nie może być ustalona po upływie sześciu miesięcy od daty stworzenia projektu Inwitki.</li>
            <li>Formą płatności za Inwitkę jest skorzystanie z Kredytów czyli przedpłata.</li>
            <li>Umowa z Użytkownikiem dotycząca Inwitki jest zawierana na czas jej realizacji. Realizacja umowy następuje w dacie pobrania przez Serwis Kredytów z Konta. </li>
            <li>Zakupione kredyty nie mogą być zwrócone lub transferowane do kont innych Użytkowników.</li>
          </ol>
          <h3>Reklamacje i prawo do odstępienia</h3>
          <ol>
            <li>
              Inwitki to usługa:
              <ol>
                <li>Dotycząca zawarcia umowy o dostarczanie treści cyfrowych, które nie są zapisane na nośniku materialnym, której świadczenie rozpoczęło się za wyraźną zgodą Konsumenta przed upływem terminu do odstąpienia od umowy i po poinformowaniu go przez przedsiębiorcę o utracie prawa odstąpienia od umowy.</li>
                <li>Spersonalizowana, wyprodukowana według osobistych wskazań i specyfikacji Użytkownika i służąca zaspokojeniu jego zindywidualizowanych potrzeb.</li>
              </ol>
            </li>
            <li>Wobec charakteru Inwitki wskazanego w art. 1 powyżej, Użytkownikowi nie przysługuje prawo do odstąpienia od umowy dotyczącej Inwitki w terminie 14 dni o czym mowa w art. 27 Ustawy.</li>
            <li>Przed zakupem Kredytów Użytkownik wyraża zgodę na realizację Inwitki przed upływem terminu do odstąpienia od umowy zawartej na odległość oraz potwierdza utratę prawa odstąpienia od umowy w terminie 14 od daty jej zawarcia.</li>
            <li>
              Reklamacje dotyczące niezgodności przedmiotu zamówienia z umową należy zgłaszać:
              <ol>
                <li>pisemnie na adres Sprzedawcy BULLAPP.CLOUD SP. Z O.O., ul. Hoża 86 lok. 410, 00-682 Warszawa,</li>
                <li>
                  za pomocą danych kontaktowych do korespondencji podanych na podstronie:{' '}
                  <a href='/pages/contact' target='_blank'>
                    https://inwitki.net/pages/contact
                  </a>
                  .
                </li>
              </ol>
            </li>
            <li>Reklamacje rozpatrywane są bez zbędnej zwłoki, nie później niż w ciągu 14 dni od daty otrzymania reklamacji przez Sprzedawcę.</li>
            <li>
              Użytkownik, który jest konsumentem, gdy postępowanie reklamacyjne nie zostanie pozytywnie dla niego zakończone, ma możliwości skorzystania z pozasądowych sposobów rozpatrywania reklamacji i dochodzenia roszczeń, a zasady dostępu do tych procedur dostępne są w siedzibach oraz na stronach internetowych powiatowych (miejskich) rzeczników konsumentów, organizacji społecznych, do których zadań statutowych należy ochrona konsumentów, Wojewódzkich Inspektoratów Inspekcji Handlowej oraz
              pod następującymi adresami internetowymi Urzędu Ochrony Konkurencji i Konsumentów:{' '}
              <a href='https://polubowne.uokik.gov.pl/rejestr,5,pl.html' target='blank'>
                https://polubowne.uokik.gov.pl/rejestr,5,pl.html
              </a>
            </li>
            <li>Treść niniejszego regulaminu nie prowadzi do wyłączenia lub ograniczenia praw Użytkowników będących konsumentami. </li>
          </ol>
          <h3>Dane osobowe</h3>
          <ol>
            <li>
              Serwis i Sprzedawca przetwarzają dane osobowe zgodnie z treścią{' '}
              <a href='/pages/privacy-policy' target='blank'>
                Polityki Prywatności
              </a>{' '}
              oraz informacjami o{' '}
              <a href='/pages/data-processing' target='blank'>
                Przetwarzaniu Danych
              </a>
              .
            </li>
          </ol>
          <h3>Postanowienia końcowe</h3>
          <ol>
            <li>Sądem właściwym dla rozpatrywania wszelkich sporów Sprzedawcy z Użytkownikiem nie będącym konsumentem będzie sąd właściwy dla siedziby Sprzedawcy.</li>
            <li>Sprzedawca ma prawo dokonywać zmian w Regulaminie bez utraty praw nabytych przez Użytkowników po zakupie Kredytów w dacie obowiązywania dotychczasowego Regulaminu.</li>
            <li>W zakresie nieuregulowanym postanowieniami niniejszego Regulaminu stosuje się powszechnie obowiązujące przepisy prawa. </li>
          </ol>
        </div>
      </div>
    </StylesWrapper>
  );
};

RegulationsContent.propTypes = {};

export default RegulationsContent;
