import React from 'react';
import PropTypes from 'prop-types';
import BarLoader from 'react-bar-loader';
import { themeLight } from '../../misc/Theme';
import Title from './Title';

const TitleBar = ({ path, title, leftExtras, rightExtras, isLoading, backgroundColor, dividerColor, loaderColor, foregroundColor, centredTo }) => {
  return (
    <div className='flex flex-col w-1.0' style={{ backgroundColor: backgroundColor }}>
      <div className='flex flex-row justify-center'>
        <div className='flex flex-row flex-initial gap-4' style={{ color: foregroundColor, width: centredTo ?? '100%', height: '50px', padding: '0 12px' }}>
          <div className='flex flex-row items-center gap-2' style={{ width: '40%' }}>
            {leftExtras}
          </div>

          <div className='flex items-center justify-center' style={{ width: '20%' }}>
            <>{title ? title : <Title path={path} />}</>
          </div>

          <div className='flex-auto flex items-center justify-end gap-2' style={{ width: '40%' }}>
            {rightExtras}
          </div>
        </div>
      </div>
      {isLoading ? (
        <div style={{ height: '3px', marginTop: '0' }}>
          <BarLoader color={loaderColor} height='2' />
        </div>
      ) : (
        <div style={{ height: '2px', backgroundColor: backgroundColor, borderBottom: `1px solid ${dividerColor}` }} />
      )}
    </div>
  );
};

TitleBar.propTypes = {
  path: PropTypes.string,
  title: PropTypes.any,
  leftExtras: PropTypes.any,
  rightExtras: PropTypes.any,
  isLoading: PropTypes.bool,
  dividerColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  loaderColor: PropTypes.string,
  foregroundColor: PropTypes.string,
  multipleExtras: PropTypes.bool,
  centredTo: PropTypes.string,
};

TitleBar.defaultProps = {
  path: '/',
  isLoading: false,
  foregroundColor: themeLight.light2,
  backgroundColor: themeLight.topBarBg,
  dividerColor: themeLight.topBarBg,
  loaderColor: themeLight.light3,
  multipleExtras: true,
  centredTo: themeLight.centredTo,
};

export default TitleBar;
