import React from 'react';
import PropTypes from 'prop-types';
import useTheme from 'src/hooks/useTheme';

const SelectStyleTagItem = ({ tag, onSelect }) => {
  const { theme } = useTheme();
  return (
    <div
      style={{ color: theme.dark4 }}
      className={`w-full flex justify-center items-center cursor-pointer  border border-solid rounded-md py-2 font-bold shadow-md hover:scale-105`}
      onClick={() => {
        onSelect(tag.value);
      }}
    >
      {tag.label}
    </div>
  );
};

SelectStyleTagItem.propTypes = {
  tag: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default SelectStyleTagItem;
