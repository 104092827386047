import React, { useContext } from 'react';
import GlobalContext from '../../context/GlobalContext';
import { PropTypes } from 'prop-types';
import useTheme from 'src/hooks/useTheme';
import DisplayConsentsDrawer from 'src/components/controls/drawers/DisplayConsentsDrawer';

const Layout = ({ header, children, footer, backgroundColor, marginBottom, footerMarginTop }) => {
  const globalContext = useContext(GlobalContext);
  const { theme } = useTheme();

  return (
    <>
      <DisplayConsentsDrawer />
      <div style={{ zIndex: 10, backgroundColor: theme.light1, position: 'fixed', display: 'flex', flexDirection: 'column', top: 0, right: 0, bottom: 0, left: 0 }} className={globalContext.isBusy ? 'disabled' : null}>
        {header && <div>{header}</div>}
        <div
          onScroll={(x) => {
            globalContext.setLayoutScrollTop(x?.target?.scrollTop ?? -1);
          }}
          style={{
            flex: 'auto',
            position: 'relative',
            display: 'flex',
            flexFlow: 'column',
            overflowY: 'auto',
            overflowX: 'hidden',
            backgroundColor,
            marginBottom,
          }}
        >
          <div style={{ flex: 'auto' }}>{children}</div>
          {footer && <div style={{ flex: '0 1 auto', marginTop: footerMarginTop }}>{footer}</div>}
        </div>
      </div>
    </>
  );
};

Layout.propTypes = {
  header: PropTypes.any,
  children: PropTypes.any,
  footer: PropTypes.any,
  backgroundColor: PropTypes.string,
  marginBottom: PropTypes.string,
  footerMarginTop: PropTypes.string,
};

Layout.defaultProps = {
  backgroundColor: null,
  marginBottom: null,
  footerMarginTop: '8px',
};

export default Layout;
