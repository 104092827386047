import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { GUEST_REGEX } from 'src/misc/Config';
import { QRCode, Button, Dropdown, Space, notification } from 'antd';
import { WarningOutlined, MoreOutlined, DownloadOutlined, DeleteOutlined, CopyOutlined, SendOutlined, LinkOutlined } from '@ant-design/icons';
import { ReactComponent as InveeLogo } from 'src/components/svg/invee-logo.svg';
import YesNoDialog from 'src/components/layout/YesNoDialog';
import Highlighter from 'react-highlight-words';
import html2canvas from 'html2canvas';
import { getInveeUrl } from 'src/misc/Misc';
import useTheme from 'src/hooks/useTheme';

import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import 'dayjs/locale/pl';

dayjs.extend(isoWeek);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
dayjs.locale('pl');

const StyledQRCode = styled.div`
  .ant-qrcode {
    border: none !important;
  }
`;

const InveeStringItem = ({ item, onDelete, searchedPhrase }) => {
  const { themeLight } = useTheme();

  const getItems = () => {
    let result = [];
    result.push({
      label: 'Otwórz link',
      key: 'open-link',
      icon: <LinkOutlined />,
    });
    result.push({
      label: 'Skopiuj link',
      key: 'copy-link',
      icon: <CopyOutlined />,
    });
    if (navigator.share) {
      result.push({
        label: 'Wyślij inwitkę',
        key: 'send-share',
        icon: <SendOutlined />,
      });
    } else {
      result.push({
        label: 'Wyślij link e-mailem',
        key: 'send-email',
        icon: <SendOutlined />,
      });
    }
    return result;
  };

  const handleMenuClick = (e) => {
    console.log('click', e);
    switch (e.key) {
      case 'open-link':
        handleOpenLink();
        break;
      case 'copy-link':
        handleCopyLink();
        break;
      case 'send-share':
        handleShareLink();
        break;
      case 'send-email':
        handleSendByEmail();
        break;
      default:
        break;
    }
  };

  const menuProps = {
    items: getItems(),
    onClick: handleMenuClick,
  };

  const handleDownloadLink = () => {
    const link = getInveeUrl(item.inveeString);
    const fileName = `inwitka ${item.guest.replace(GUEST_REGEX, '').trim()}.txt`;
    const file = new File(['\ufeff' + link], fileName, { type: 'text/plain:charset=UTF-8' });
    const url = window.URL.createObjectURL(file);
    const a = document.createElement('a');
    a.download = fileName;
    a.href = url;
    //document.body.appendChild(a);
    a.click();
    //document.body.removeChild(a);
  };

  const handleDownloadQr = () => {
    html2canvas(document.getElementById(item.id), { backgroundColor: null }).then((canvas) => {
      if (canvas) {
        // const deleteIcon = document.getElementById('1f170cdd-d5ab-45c0-a448-e36ffbd18a52');
        // if (deleteIcon) deleteIcon.style.display = 'hidden';
        const url = canvas.toDataURL();
        const a = document.createElement('a');
        const fileName = `inwitka ${item.guest.replace(GUEST_REGEX, '').trim()}.png`;
        a.download = fileName;
        a.href = url;
        a.click();
        // if (deleteIcon) deleteIcon.style.display = 'block';
      }
    });
  };

  const handleOpenLink = () => {
    const link = getInveeUrl(item.inveeString);
    window.open(link, '_blank');
  };

  const handleCopyLink = () => {
    const link = getInveeUrl(item.inveeString);
    navigator.clipboard.writeText(link);
    notification.success({
      message: 'Skopiowano link do schowka.',
      description: (
        <a href={link} target='_blank' rel='noreferrer'>
          {link}
        </a>
      ),
      duration: 5,
    });
  };

  const handleSendByEmail = () => {
    const link = getInveeUrl(item.inveeString);
    window.open(`mailto:?subject=${item.title}&body=${link}`);
  };

  const handleShareLink = () => {
    const link = getInveeUrl(item.inveeString);

    if (navigator.share) {
      navigator
        .share({
          title: item.title,
          text: item.title,
          url: link,
        })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    } else {
      handleCopyLink();
    }
  };

  return (
    <div className={`flex flex-col self-start items-center justify-center p-4 gap-2`}>
      <div id={item.id} style={{ position: 'relative', width: '280px', backgroundColor: themeLight.light1, borderRadius: '1rem', border: `1px solid ${themeLight.dark3}`, color: themeLight.dark4 }}>
        <div className='p-4 flex flex-col items-center overflow-hidden'>
          <InveeLogo style={{ height: '32px', color: themeLight.logoBlue }} />
          <div className='font-roboto-slab font-bold'>
            inwitki<span className='opacity-60'>.net</span>
          </div>
          <StyledQRCode>
            <QRCode value={getInveeUrl(item.inveeString)} color='black' />
          </StyledQRCode>

          <div className='break-words w-11/12 font-bold text-center'>
            <Highlighter searchWords={[searchedPhrase]} autoEscape={true} textToHighlight={item.title} />
          </div>
        </div>
      </div>
      <div className='text-xs break-words w-11/12 flex flex-col text-center'>
        <div>
          wygenerowano <span className='font-bold'>{item.createdAt && dayjs().to(dayjs(item.createdAt))}</span>
        </div>
        <div>
          dla{' '}
          <span className='font-bold'>
            <Highlighter searchWords={[searchedPhrase]} autoEscape={true} textToHighlight={item.guest} />
          </span>
        </div>
      </div>
      <div className='flex flex-col items-center self-center justify-center gap-2'>
        <Button size='small' style={{ width: '280px' }} onClick={handleDownloadLink}>
          <Space>
            <DownloadOutlined />
            Pobierz Link
          </Space>
        </Button>
        <Button size='small' style={{ width: '280px' }} onClick={handleDownloadQr}>
          <Space>
            <DownloadOutlined />
            Pobierz Kod QR
          </Space>
        </Button>
        <YesNoDialog
          // showConfirmation
          // labelUnconfirmed='kliknij aby potwierdzić'
          // labelConfirmed='potwierdzam'
          onYesClick={() => {
            if (onDelete) onDelete(item.id);
          }}
          onNoClick={() => {}}
          title='Usuń inwitkę'
          body={
            <>
              <div className='mb-4'>Czy na pewno chcesz trwale usunąć tą inwitkę z listy?</div>
              <div className=''>
                <WarningOutlined className='mr-2 text-red-600' />
                Jeśli nie pobrałeś jeszcze linku lub kodu QR, nie będzie można ich odzyskać.
              </div>
              <div className=''>
                <WarningOutlined className='mr-2 text-red-600' />
                Jeśli pobrałeś już link lub kod QR, będą one dalej działać.
              </div>
              <div className='mb-4'>
                <WarningOutlined className='mr-2 text-red-600' />
                Tej operacji nie można cofnąć.
              </div>
            </>
          }
          labelYes='Rozumiem, usuń'
          labelNo='Anuluj'
        >
          <Button size='small' style={{ width: '280px' }}>
            <Space>
              <DeleteOutlined />
              Usuń
            </Space>
          </Button>
        </YesNoDialog>
        <Dropdown menu={menuProps}>
          <Button size='small' style={{ width: '280px' }}>
            <Space>
              <MoreOutlined />
              <div>Więcej opcji</div>
            </Space>
          </Button>
        </Dropdown>
      </div>
    </div>
  );
};

InveeStringItem.propTypes = {
  item: PropTypes.object.isRequired,
  onDelete: PropTypes.func,
  searchedPhrase: PropTypes.string,
};

export default InveeStringItem;
