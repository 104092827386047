import React, { useEffect, useRef } from 'react';
import useTheme from 'src/hooks/useTheme';
import useConsents from 'src/hooks/useConsents';
import { useScreen } from 'src/hooks/useScreen';
import { Drawer, Space, Checkbox, Button } from 'antd';
import { FaRegSquareCheck, FaRegSquare } from 'react-icons/fa6';
// import GlobalContext from 'src/context/GlobalContext';
import { sleepMs } from 'src/misc/Misc';
import { LOCAL_STORAGE_KEYS, SCREEN_MODES } from 'src/misc/Config';
import { MdCookie } from 'react-icons/md';
import { GoHeartFill } from 'react-icons/go';
import { ImHappy2 } from 'react-icons/im';
import WhenDesktop from 'src/components/layout/WhenDesktop';
import WhenMobile from 'src/components/layout/WhenMobile';
import * as dayjs from 'dayjs';
import { updateConsents } from 'src/misc/Ga4';

const ConsentsDrawer = () => {
  const { theme } = useTheme();
  const btnsRef = useRef(null);

  // const globalContext = useContext(GlobalContext);
  const { screenMode, isDesktop } = useScreen();
  const { cookieConsentsOpen, setCookieConsentsOpen, cookieConsents, setCookieConsents } = useConsents();

  useEffect(() => {
    if (!cookieConsentsOpen) return;
    btnsRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
  }, [cookieConsentsOpen]);

  const handleStatsCkecked = (e) => {
    setCookieConsents({ ...cookieConsents, stats: e.target.checked });
  };

  const handleAcceptAll = async (e) => {
    const cookieConsentToBeSaved = { ...cookieConsents, stats: true, at: new Date().toISOString() };
    setCookieConsents(cookieConsentToBeSaved);
    var cookieConsentsStr = JSON.stringify(cookieConsentToBeSaved);
    localStorage.setItem(LOCAL_STORAGE_KEYS.cookieConsents, cookieConsentsStr);
    updateConsents(cookieConsentToBeSaved.stats ? 'granted' : 'denied');
    await sleepMs(500);
    setCookieConsentsOpen(false);
  };

  const handleAcceptSelected = (e) => {
    const cookieConsentToBeSaved = { ...cookieConsents, at: new Date().toISOString() };
    setCookieConsents(cookieConsentToBeSaved);
    var cookieConsentsStr = JSON.stringify(cookieConsentToBeSaved);
    localStorage.setItem(LOCAL_STORAGE_KEYS.cookieConsents, cookieConsentsStr);
    updateConsents(cookieConsentToBeSaved.stats ? 'granted' : 'denied');
    setCookieConsentsOpen(false);
  };

  const handleRejectOptional = async (e) => {
    const cookieConsentToBeSaved = { ...cookieConsents, stats: false, at: new Date().toISOString() };
    setCookieConsents(cookieConsentToBeSaved);
    var cookieConsentsStr = JSON.stringify(cookieConsentToBeSaved);
    localStorage.setItem(LOCAL_STORAGE_KEYS.cookieConsents, cookieConsentsStr);
    updateConsents(cookieConsentToBeSaved.stats ? 'granted' : 'denied');
    await sleepMs(500);
    setCookieConsentsOpen(false);
  };

  return (
    <Drawer placement='bottom' open={cookieConsentsOpen} closeIcon={null} closable={false} height={isDesktop ? 'auto' : '100%'} style={{ backgroundColor: theme.light1, color: theme.dark4 }}>
      <div className='flex flex-row justify-center items-center'>
        <div style={{ width: theme.centredTo }}>
          <Space direction='vertical' className='text-center lg:text-left text-[0.7rem] xl:text-sm mx-1 lg:mx-64 overflow-y-auto overflow-x-scroll'>
            <div className='flex flex-row gap-2 items-center font-roboto-slab text-3xl mb-1 justify-center lg:justify-start' style={{ color: theme.dark3 }}>
              {/* <MdCookie className='text-amber-600 dark:text-amber-400' />
              <GoHeartFill className='text-red-500 dark:text-red-400' />
              <ImHappy2 className='text-yellow-500 dark:text-yellow-200 font-roboto-' /> */}
              <WhenDesktop>
                Lubimy ciasteczka, <span className='opacity-60'>a Ty... ?</span>
              </WhenDesktop>
              <MdCookie className='text-amber-600 dark:text-amber-600' />
              <GoHeartFill className='text-red-600 dark:text-red-500' />
              <ImHappy2 className='text-yellow-500 dark:text-yellow-400 font-roboto-' />
              <WhenMobile>Ciasteczka</WhenMobile>
            </div>

            <div className='opacity-60'>
              Niniejsza strona wykorzystuje pliki cookie (tzw. ciasteczka) oraz local storage, czyli krótkie informacje tekstowe, zapisywane urządzeniu użytkownika. Mogą być one odczytywane przez nasz system, a także przez systemy należące do innych podmiotów, z których usług korzystamy (np. Facebook, Google). Masz wybór, które pliki cookie i local storage możemy zapisywać na Twoim urządzeniu. Cały czas masz możliwość modyfikować swoje zgody, wybierając opcję Cookies w stopce strony.
            </div>

            <Checkbox checked={true} className='text-xl disabled'>
              Niezbędne do działania strony
            </Checkbox>
            <div className='opacity-60'>Niezbędne pliki cookie oraz local storage przechowują informacje o zalogowaniu użytkownika, udzielonych zgodach na zapisywanie plików cookie oraz local storage, wybranym trybie graficznym, ostatnio wygenerowanych Inwitkach.</div>
            <Checkbox checked={cookieConsents?.stats ?? false} onChange={handleStatsCkecked} className='text-xl'>
              Statystyczne
            </Checkbox>
            <div className='opacity-60'>Statystyczne pliki cookie i podobne technologie pomagają zbierać dane, dzięki którym usługi „wiedzą”, jak z nich korzystasz. Dane te ułatwiają ulepszanie treści w usługach i tworzenie lepszych funkcji zwiększających komfort użytkowania.</div>
            <div className='mt-2' ref={btnsRef}>
              <Space direction={SCREEN_MODES.mobile === screenMode ? 'vertical' : null}>
                <Button size='large' style={{ width: '220px' }} onClick={handleRejectOptional}>
                  <Space className='btn'>
                    <div className='flex flex-row items-center'>
                      <FaRegSquareCheck />
                      <FaRegSquare />
                    </div>
                    Odrzuć opcjonalne
                  </Space>
                </Button>
                <Button size='large' style={{ width: '220px' }} onClick={handleAcceptSelected}>
                  <Space className='btn'>
                    <div className='flex flex-row items-center'>
                      <FaRegSquareCheck />
                      {cookieConsents?.stats ? <FaRegSquareCheck /> : <FaRegSquare />}
                    </div>
                    Akceptuj wybrane
                  </Space>
                </Button>
                <Button size='large' type='primary' style={{ width: '220px' }} onClick={handleAcceptAll}>
                  <Space className='btn'>
                    <div className='flex flex-row items-center'>
                      <FaRegSquareCheck />
                      <FaRegSquareCheck />
                    </div>
                    Akceptuj wszystko
                  </Space>
                </Button>
              </Space>
            </div>
            {cookieConsents?.at && <div className='opacity-40'>Ostatnia aktualizacja zgód: {dayjs(cookieConsents?.at).fromNow()}</div>}
          </Space>
        </div>
      </div>
    </Drawer>
  );
};

ConsentsDrawer.propTypes = {};

export default ConsentsDrawer;
