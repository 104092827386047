import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Breadcrumb from './Breadcrumb';

const StylesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: ${(props) => props.theme.light1};

  .content-container {
    width: ${(props) => props.theme.centredTo ?? '100%'};
    display: flex;
    flex-direction: column;
    padding: 12px 12px;
    overflow-y: auto;
  }

  .content-title {
    //border-bottom: 1px solid ${(props) => props.theme.dark3};
    margin-bottom: 24px;
    font-size: 24px;
    color: ${(props) => props.theme.dark4};
  }
`;

const Content = ({ children, breadcrumbRoutes, title }) => {
  return (
    <StylesWrapper>
      <div className='content-container'>
        {breadcrumbRoutes && <Breadcrumb routes={breadcrumbRoutes} />}
        {title && (
          <div className='content-title'>
            <h3 className='m-0'>{title}</h3>
          </div>
        )}
        {children}
      </div>
    </StylesWrapper>
  );
};

Content.propTypes = {
  children: PropTypes.any,
  breadcrumbRoutes: PropTypes.any,
  title: PropTypes.any,
};

Content.defaultProps = {
  breadcrumbRoutes: null,
};

export default Content;
