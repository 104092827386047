import './index.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import GlobalProvider from './context/GlobalProvider';
import InveesProvider from './context/InveesProvider';
import Router from './router/Router';
import AuthProvider from './wrappers/AuthProvider';
import PageThemeProvider from './wrappers/PageThemeProvider';
import { Helmet } from 'react-helmet';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <GlobalProvider>
    <InveesProvider>
      <AuthProvider>
        <PageThemeProvider>
          <Helmet>
            <title>inwitki.net - cyfrowe e-zaproszenia na smartfony</title>
            <meta name='keywords' content='e-zaproszenia, cyfrowe, zaproszenia, inwitki, ślub, wesele, komunia, chrzest, urodziny, impreza, event, zaproszenia na telefon, zaproszenia na komórkę, zaproszenia na smartfony' />
            <meta name='description' content='Twórz cyfrowe inwitki - e-zaproszenia na smartfony. Udostępniaj jako link lub kody QR. Pokaż lokalizację wydarzenia na mapach. Otwieraj zaproszenia w aplikacji mobilnej na iOS i Android.' />
          </Helmet>
          <Router />
        </PageThemeProvider>
      </AuthProvider>
    </InveesProvider>
  </GlobalProvider>
);
