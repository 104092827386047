import React from 'react';

// import PropTypes from 'prop-types';
import ConsentsDrawer from 'src/components/controls/drawers/ConsentsDrawer';
const DisplayConsentsDrawer = () => {
  // if (window.location.pathname === '/pages/privacy-policy') return null;
  // if (window.location.pathname === '/pages/regulations') return null;
  return <ConsentsDrawer />;
};

// ConsentsWrapper.propTypes = {};

export default DisplayConsentsDrawer;
