import React from 'react';
import PropTypes from 'prop-types';
import { AiOutlineFileUnknown } from 'react-icons/ai';
import useTheme from 'src/hooks/useTheme';

const OptionButton = ({ key, title, icon, onClick, disabled }) => {
  const { theme } = useTheme();

  return (
    <div
      key={key}
      className={`flex flex-col justify-start items-center min-h-28 w-28 px-2 py-4 shadow-neutral-200 dark:shadow-neutral-700 border border-solid border-gray-300 rounded-xl transition-all ${!disabled ? 'cursor-pointer opacity-80 hover:scale-105 hover:opacity-100 hover:shadow-md' : 'cursor-not-allowed opacity-30'}`}
      onClick={() => {
        if (disabled) return;
        if (onClick) onClick();
      }}
    >
      <div className={`text-6xl`} style={{ color: theme.logoBlue }}>
        {icon}
      </div>
      <div className='overflow-hidden whitespace-nowrap text-center'>{title}</div>
    </div>
  );
};

OptionButton.propTypes = {
  key: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

OptionButton.defaultProps = {
  icon: <AiOutlineFileUnknown />,
  disabled: false,
};

export default OptionButton;
