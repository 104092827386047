import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ReactComponent as InveeLogo } from 'src/components/svg/invee-logo.svg';
import { getMapUrl, notEmptyArray } from 'src/misc/Misc';
import { FaLocationDot } from 'react-icons/fa6';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import MobileAppPrompt from './MobileAppPrompt';
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

const StylesWrapper = styled.div`
  p {
    //font-family: 'Roboto', sans-serif;
    margin: 0;
    word-break: break-word;
  }

  .scroll-to-name {
    animation: scroll-to-name-anim 1s ease-in-out 1s;
  }

  @keyframes scroll-to-name-anim {
    0% {
      transform: scale(1);
    }
    20% {
      transform: scale(1.5);
    }
    80% {
      transform: scale(1.5);
    }
    100% {
      transform: scale(1);
    }
  }
`;

const InveeView = ({ invee, guest, hideContent, scrollToName, fontSize, logoSize, separatorMargin, borderRadius, inveeString }) => {
  const nameRef = useRef(null);

  useEffect(() => {
    if (!scrollToName) return;
    nameRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StylesWrapper style={{ fontSize, color: invee?.style?.color, backgroundColor: invee?.style?.bgColor, position: 'relative', width: '100%', minHeight: '100%', overflowX: 'hidden', display: 'flex', flexDirection: 'column', borderRadius }}>
      {guest && <MobileAppPrompt inveeString={inveeString} style={invee?.style} />}
      <div style={{ marginTop: 'auto', height: '64px', display: 'contents' }}>{invee?.style?.topImage && <img style={{ width: '100%' }} src={invee?.style?.topImage} alt='header' />}</div>
      <div className={invee?.font?.class} style={{ flex: '1', display: 'flex', flexDirection: 'column', justifyContent: 'center', backgroundImage: invee?.style?.bgImage ? `url("${invee?.style?.bgImage}")` : null, backgroundRepeat: 'no-repeat', backgroundSize: '100%', backgroundPosition: 'top' }}>
        {!hideContent && (
          <>
            <div>
              <div className='my-5'></div>
              <InveeLogo style={{ height: logoSize }} />
              <div className='w-full flex flex-row justify-center'>
                <div className='break-words w-11/12 px-2 font-bold text-center'>{invee.title}</div>
              </div>
            </div>
            {invee?.style?.separatorImage ? (
              <div style={{ margin: `${separatorMargin} 0` }} className='w-full flex flex-row justify-center'>
                <img style={{ width: '80%' }} src={invee?.style?.separatorImage} alt='---' />
              </div>
            ) : (
              <div style={{ margin: `${separatorMargin} 0` }} />
            )}
            <div className=' mx-1'>
              <div className='w-full flex flex-row justify-center'>
                <div className='break-words w-11/12  text-center'>
                  <div className={invee?.font?.class} dangerouslySetInnerHTML={{ __html: invee.contents?.content1 }}></div>

                  <>
                    {guest ? (
                      <div ref={nameRef} className={`p-4 font-bold ${scrollToName ? 'scroll-to-name' : null}`}>
                        {guest}
                      </div>
                    ) : (
                      <div className='m-4 p-1 border border-dotted font-bold italic'>( imię gościa )</div>
                    )}
                  </>

                  <div dangerouslySetInnerHTML={{ __html: invee.contents?.content2 }}></div>
                </div>
              </div>
            </div>
            {invee?.style?.separatorImage && true === notEmptyArray(invee.datesLocations) ? (
              <div style={{ margin: `${separatorMargin} 0` }} className='w-full flex flex-row justify-center'>
                <img style={{ width: '80%' }} src={invee?.style?.separatorImage} alt='---' />
              </div>
            ) : (
              <div style={{ margin: `${separatorMargin} 0` }} />
            )}
            <div>
              {true === notEmptyArray(invee.datesLocations) && (
                <>
                  {invee.datesLocations.map((item) => (
                    <div key={item.id} className='text-center break-words' style={{ marginBottom: separatorMargin }}>
                      <div className='font-bold'>{item.name}</div>
                      <div className='mt-1'>{dayjs().to(dayjs(item.date))}</div>
                      <div>{dayjs(item.date).format('llll')}</div>
                      <div className='mt-1 whitespace-break-spaces'>{item.location}</div>
                      {item.coords?.lat && item.coords?.lon && (
                        <div
                          className='mt-2 font-bold flex flex-row items-center justify-center gap-1 hover:cursor-pointer'
                          onClick={() => {
                            const mapUrl = getMapUrl(item.coords);
                            window.open(mapUrl, '_blank');
                          }}
                        >
                          <FaLocationDot />
                          <div>Pokaż na mapie</div>
                        </div>
                      )}
                    </div>
                  ))}
                </>
              )}
            </div>
          </>
        )}
      </div>
      <div style={{ marginTop: 'bottom', minHeight: '64px', display: 'contents' }}>{invee?.style?.bottomImage && <img style={{ width: '100%' }} src={invee?.style?.bottomImage} alt='footer' />}</div>
    </StylesWrapper>
  );
};

InveeView.propTypes = {
  invee: PropTypes.object,
  guest: PropTypes.string,
  hideContent: PropTypes.bool,
  scrollToName: PropTypes.bool,
  fontSize: PropTypes.string,
  logoSize: PropTypes.string,
  separatorMargin: PropTypes.string,
  borderRadius: PropTypes.string,
  inveeString: PropTypes.string,
};

InveeView.defaultProps = {
  scrollToName: false,
  hideContent: false,
  fontSize: '1.1rem',
  logoSize: '32px',
  separatorMargin: '24px',
  borderRadius: null,
};

export default InveeView;
