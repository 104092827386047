import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import InveeStyles, { getAllTags } from 'src/misc/InveeStyles';
import InveesContext from 'src/context/InveesContext';
import { Select } from 'antd';

import SelectStyleTagItem from '../../pages/selectStyle/SelectStyleTagItem';
import SelectStyleItem from '../../pages/selectStyle/SelectStyleItem';

const StyledSelect = styled(Select)`
  .ant-select-selector {
    box-shadow: none !important;
    height: 36px !important;
    text-align: center !important;
  }

  .ant-select-selection-item {
    padding-left: 18px !important;

    font-weight: 600 !important;
    font-size: 1rem !important;
    font-family: 'Roboto', sans-serif;
  }
`;

const InveeEditorStyle = ({ onSelect }) => {
  const inveesContext = useContext(InveesContext);
  //const { screenMode } = useScreen();

  //  const [selectedTags, setSelectedTags] = useState([]);

  const [selectedTag, setSelectedTag] = useState(null);

  useEffect(() => {
    const tags = getAllTags();
    if (!tags.map((t) => t.value).includes(inveesContext?.currentInvee?.tag)) return;
    setSelectedTag(inveesContext.currentInvee.tag);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const handleTagsOnChange = (value) => {
  //   //console.log(`selected ${value}`);
  //   setSelectedTags(value);
  // };

  const handleTagsOnChange = (value) => {
    //console.log(`selected ${value}`);
    setSelectedTag(value);
  };

  const handleStyleOnSelect = (style) => {
    if (!onSelect) return;
    onSelect(style, selectedTag);
  };

  return (
    <div className='mt-2 w-full flex flex-col items-center'>
      {!selectedTag && (
        <div className='max-w-[500px] w-full'>
          <div>
            {/* <div className='mb-2 font-bold text-center'>Na jaką okazję?</div> */}
            <div className='flex flex-col gap-2'>
              {getAllTags().map((x) => (
                <SelectStyleTagItem tag={x} onSelect={setSelectedTag} />
              ))}
            </div>
          </div>
        </div>
      )}

      {selectedTag && (
        <>
          <div className='w-full flex flex-col  items-center'>
            <div className='max-w-[500px] w-full r'>
              {/* <div className='mb-2 font-bold text-center'>Na jaką okazję?</div> */}
              <StyledSelect dropdownStyle={{ textAlign: 'center', fontFamily: "'Roboto', sans-serif" }} className='shadow-md' mode='single' showSearch={false} style={{ width: '100%' }} placeholder='wybierz okazję...' defaultValue={selectedTag} onChange={handleTagsOnChange} options={getAllTags()} />
            </div>
          </div>
          {/* <div className='mt-8 mb-2 font-bold text-center'>Wybierz styl</div> */}
          <div className='mt-8 flex flex-row flex-wrap gap-4 justify-center'>
            {InveeStyles.filter((x) => x.depricated !== true && x.tags?.includes(selectedTag)).map((x) => (
              <SelectStyleItem style={x} onSelect={handleStyleOnSelect} />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

InveeEditorStyle.propTypes = {
  onSelect: PropTypes.func.isRequired,
};

InveeEditorStyle.defaultProps = {};

export default InveeEditorStyle;
