import React, { useState, useEffect, useContext } from 'react';
import GlobalContext from 'src/context/GlobalContext';
import UserControl from 'src/components/controls/userControl/UserControl';
import Layout from 'src/components/layout/Layout';
import TitleBar from 'src/components/layout/TitleBar';
import Content from 'src/components/layout/Content';
import Footer from 'src/components/layout/Footer';
import { Button, Table, Card, Alert, Space, Modal, Checkbox, notification } from 'antd';

import DarkModeControl from 'src/components/controls/darkModeControl/DarkModeControl';
import useUserInfo from 'src/hooks/useUserInfo';
import { WarningOutlined } from '@ant-design/icons';
import { deleteAccount } from 'src/api/invee';
import * as Ga4 from 'src/misc/Ga4';

const Tokens = () => {
  const userInfo = useUserInfo();
  const globalContext = useContext(GlobalContext);

  const columns = [
    {
      title: 'Property',
      key: 'property',
      width: 120,
      render: (record) => <div style={{ maxWidth: '110px', wordWrap: 'normal', overflow: 'hidden', textOverflow: 'ellipsis' }}>{record.property}</div>,
    },
    {
      title: 'Value',
      key: 'value',
      width: 320,
      render: (record) => <div style={{ wordWrap: 'normal', overflow: 'hidden', textOverflow: 'ellipsis' }}>{record.value}</div>,
    },
  ];

  const breadcrumbRoutes = [
    {
      title: 'Moje projekty',
      path: '/projects',
    },
    {
      title: 'Profil użytkownika',
    },
  ];

  const [removeModalOpen, setRemoveModalOpen] = useState(false);
  const [check1, setCheck1] = useState(false);
  const [check2, setCheck2] = useState(false);
  const [check3, setCheck3] = useState(false);
  const [check4, setCheck4] = useState(false);
  const [check5, setCheck5] = useState(false);

  useEffect(() => {
    Ga4.pageView('User Profile');
  }, []);

  useEffect(() => {
    setCheck1(false);
    setCheck2(false);
    setCheck3(false);
    setCheck4(false);
    setCheck5(false);
  }, [removeModalOpen]);

  const getData = () => {
    if (!userInfo) return [];
    let result = [];
    for (const key in userInfo) {
      if (Object.hasOwnProperty.call(userInfo, key)) {
        if (key === 'principalId') continue;
        if (key === 'integrationLatency') continue;
        const element = userInfo[key];
        result.push({ key, property: key, value: element });
      }
    }
    return result;
  };

  const handleDeleteAccount = async () => {
    console.log('handleDeleteAccount');
    Ga4.event('User Profile', 'Delete_account', 'Delete_account');
    setRemoveModalOpen(false);
    await globalContext.executeBlockingProcess(async () => {
      try {
        await deleteAccount();
        localStorage.clear();
        sessionStorage.clear();
        notification.success({
          message: 'Konto zostało usunięte.',
          duration: 5,
        });
        window.location.href = '/';
      } catch (error) {
        console.error(error);
      }
    });
  };

  return (
    <>
      <Modal
        title='Usuwanie konta użytkownika'
        destroyOnClose={true}
        open={removeModalOpen}
        closeIcon={<></>}
        footer={[
          <Button disabled={!check1 || !check2 || !check3 || !check4 || !check5} key='1' type='primary' danger onClick={handleDeleteAccount}>
            <strong>Usuń</strong>
          </Button>,
          <Button
            key='2'
            onClick={() => {
              setRemoveModalOpen(false);
            }}
          >
            Anuluj
          </Button>,
        ]}
      >
        <div>
          <Checkbox
            className='mt-2'
            checked={check1}
            onChange={() => {
              setCheck1(!check1);
            }}
          >
            Usuń bezpowrotnie wszystkie niewykorzystane żetony bez możliwości zwrotu pieniędzy.
          </Checkbox>
          <Checkbox
            className='mt-2'
            checked={check2}
            onChange={() => {
              setCheck2(!check2);
            }}
          >
            Usuń bezpowrotnie historię kredytów.
          </Checkbox>
          <Checkbox
            className='mt-2'
            checked={check3}
            onChange={() => {
              setCheck3(!check3);
            }}
          >
            Usuń bezpowrotnie wszystkie projekty. Inwitki utworzone na ich podstawie przestaną się otwierać. Inwitki już zaimportowane do aplikacji mobilnych, pozostaną tam tak długo aż użytkownicy je usuną.
          </Checkbox>
          <Checkbox
            className='mt-2'
            checked={check4}
            onChange={() => {
              setCheck4(!check4);
            }}
          >
            Usuń bezpowrotnie dane strony z przeglądarki.
          </Checkbox>
          <Checkbox
            className='mt-2'
            checked={check5}
            onChange={() => {
              setCheck5(!check5);
            }}
          >
            Usuń bezpowrotnie konto użytkownika.
          </Checkbox>
        </div>
      </Modal>
      <Layout
        header={
          <TitleBar
            path='/projects'
            isLoading={globalContext.isBusy}
            rightExtras={
              <Space>
                <DarkModeControl />
                <UserControl />
              </Space>
            }
          ></TitleBar>
        }
        footer={<Footer />}
      >
        <Content breadcrumbRoutes={breadcrumbRoutes} title={'Profil użytkownika'}>
          <Card title='Wgląd w dane służące do identyfikacji użytkownika' className='mt-4'>
            <Alert
              description={
                <>
                  Ta sekcja umożliwia Ci wgląd w Twoje dane służące do identyfikacji użytkownika, które są przetwarzane przez serwis inwitki<span className='opacity-60'>.net</span>.
                </>
              }
              type='info'
              showIcon
              closable
            />
            <Table columns={columns} dataSource={getData()} pagination={false} size='small' bordered={false} className='mt-4' showHeader={false} />
          </Card>
          <Card title='Usuwanie konta użytkownika' className='mt-4'>
            <Alert
              description={
                <>
                  Ta sekcja umożliwia Ci całkowite usunięcie Twojego konta użytkownika oraz wszystkich danych z nim powiązanych.
                  <br />
                  <strong>
                    Bądź ostrożny, ta operacja bezpowrotnie usunie wszystko co stworzyłeś w serwisie inwitki<span className='opacity-60'>.net</span>.<br />
                    Tej operacji nie można cofnąć.
                  </strong>
                  <br />
                  Ponowne założenie konta będzie możliwe, ale nie będzie możliwe przywrócenie żadnych usuniętych danych.
                </>
              }
              type='warning'
              showIcon
              closable
            />
            <Button
              type='primary'
              danger
              className='mt-4'
              onClick={() => {
                setRemoveModalOpen(true);
              }}
            >
              <Space className='btn'>
                <WarningOutlined />
                <strong>Bezpowrotnie usuń konto</strong>
              </Space>
            </Button>
          </Card>
        </Content>
      </Layout>
    </>
  );
};

Tokens.propTypes = {};

export default Tokens;
