import React, { useState, useContext, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import InveesContext from 'src/context/InveesContext';
import GlobalContext from 'src/context/GlobalContext';
import UserControl from 'src/components/controls/userControl/UserControl';
import Layout from 'src/components/layout/Layout';
import TitleBar from 'src/components/layout/TitleBar';
import Content from 'src/components/layout/Content';
import Footer from 'src/components/layout/Footer';
import { Card, Button, Input, Modal, Space, Row, Col, Statistic, notification } from 'antd';
import WhenDesktop from 'src/components/layout/WhenDesktop';
import WhenMobile from 'src/components/layout/WhenMobile';
import InveeMobileView from './InveeMobileView';
import { useScreen } from 'src/hooks/useScreen';
import { SCREEN_MODES, MIN_GUEST_LENGTH, GUEST_REGEX } from 'src/misc/Config';
import { postEncodeInveeString, getTokens } from 'src/api/invee';
import LsDb from 'src/misc/LsDb';
import InveeStringList from './InveeStringList';
import short from 'short-uuid';
import { LeftOutlined } from '@ant-design/icons';
import { GiTwoCoins } from 'react-icons/gi';
import { ImQrcode } from 'react-icons/im';
import { TbWorldWww } from 'react-icons/tb';
import StyledScrollbars from 'src/components/styled/StyledScrollbars';
import { getInvee } from 'src/api/invee';
import { ReactComponent as InveeLogo } from 'src/components/svg/invee-logo.svg';
import { sleepMs } from 'src/misc/Misc';
import StyledButton from 'src/components/styled/StyledButton';
import DarkModeControl from 'src/components/controls/darkModeControl/DarkModeControl';
import useTheme from 'src/hooks/useTheme';
import * as Ga4 from 'src/misc/Ga4';

const InveeStringCreator = () => {
  const scrollToInveeListRef = useRef(null);
  const { inveeId } = useParams();
  const navigate = useNavigate();
  const { screenMode } = useScreen();
  const inveesContext = useContext(InveesContext);
  const globalContext = useContext(GlobalContext);
  const { theme } = useTheme();

  const breadcrumbRoutes = !inveesContext.currentInvee
    ? [
        {
          title: 'Moje projekty',
          path: '/projects',
        },
        {
          title: <span className='blinking'>...</span>,
        },
      ]
    : [
        {
          title: 'Moje projekty',
          path: '/projects',
        },
        {
          title: (
            <div style={{ maxWidth: '120px' }} className='overflow-hidden truncate text-ellipsis'>
              {inveesContext.currentInvee?.title}
            </div>
          ),
          path: `/projects/${inveesContext.currentInvee?.inveeId}/create`,
        },
        {
          title: 'Zapraszaj gości',
        },
      ];

  const [guest, setGuest] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [forceRerender, setForceRerender] = useState(true);

  useEffect(() => {
    Ga4.pageView('Project / Inviting quests');
    if (inveesContext.tokens === null) loadTokens();
    if (inveesContext.currentInvee?.inveeId !== inveeId) {
      inveesContext.setCurrentInvee(null);
      loadCurrentInvee();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadCurrentInvee = async () => {
    await globalContext.executeBlockingProcess(async () => {
      try {
        const resp = await getInvee(inveeId);
        inveesContext.setCurrentInvee(resp);
      } catch (error) {
        console.error(error);
        if (error?.toString()?.includes(404)) {
          navigate('/404');
        }
      }
    });
  };

  const loadTokens = async () => {
    await globalContext.executeBlockingProcess(async () => {
      try {
        const resp = await getTokens();
        inveesContext.setTokens(resp.tokens ?? 0);
      } catch (error) {
        console.error(error);
      }
    });
  };

  const handleCreateInveeString = async () => {
    if (!guest || guest.trim().length < MIN_GUEST_LENGTH) return;

    Ga4.event('Project Invite', 'Create_invee_string', 'Create_invee_string');

    await globalContext.executeBlockingProcess(async () => {
      try {
        const resp = await postEncodeInveeString(inveesContext.currentInvee.inveeId, guest);
        LsDb.setInveeStringItem({ ...resp, id: short.generate(), inveeId: inveesContext.currentInvee.inveeId, title: inveesContext.currentInvee.title, guest, createdAt: new Date() });
        setShowModal(false);

        setGuest('');
        setForceRerender(!forceRerender);
        inveesContext.setTokens(resp.tokens);
        notification.success({
          message: 'Wygenerowano nową inwitkę.',
          description: 'Skopiuj link lub pobierz kod QR i wyślij je do zaproszonego gościa.',
          duration: 5,
        });

        await sleepMs(500);
        scrollToInveeListRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      } catch (error) {
        console.error(error);
      }
    });
  };

  const handleBuyTokens = async () => {
    navigate('/account/tokens');
  };

  return (
    <>
      <Modal
        disabled={true}
        closable={false}
        maskClosable={false}
        destroyOnClose={true}
        width={SCREEN_MODES.desktop === screenMode ? 800 : null}
        open={showModal}
        onOk={() => {
          setShowModal(false);
        }}
        onCancel={() => {
          setShowModal(false);
        }}
        footer={[
          <Space className='btn'>
            <Button disabled={!inveesContext.tokens} key='btnOk' type='primary' onClick={handleCreateInveeString} loading={globalContext.isBusy}>
              <Space className='btn'>
                <WhenDesktop>
                  <InveeLogo style={{ height: '18px', width: '12px' }} />
                </WhenDesktop>
                Wygeneruj
              </Space>
            </Button>
            <Button disabled={globalContext.isBusy} key='btnBuy' type={!inveesContext.tokens ? 'primary' : 'default'} onClick={handleBuyTokens}>
              Dodaj kredyty
            </Button>
            <Button key='btnCancel' onClick={() => setShowModal(false)} disabled={globalContext.isBusy}>
              Anuluj
            </Button>
          </Space>,
        ]}
      >
        <Row gutter={[16, 16]}>
          <Col sm={24} lg={12}>
            <WhenMobile>
              <div className='my-1 font-bold'>Imię gościa na inwitce:</div>
              <div>{guest}</div>
            </WhenMobile>
            <WhenDesktop>
              <StyledScrollbars color={inveesContext.currentInvee?.style?.colorScrollBar ?? inveesContext.currentInvee?.style?.color} bgColor={inveesContext.currentInvee?.style?.bgColorScrollBar ?? inveesContext.currentInvee?.style?.bgColor}>
                <div style={{ width: '360px', height: '640px', overflowY: 'auto', overflowX: 'hidden' }}>
                  <InveeMobileView invee={inveesContext.currentInvee} guest={guest} scrollToName />
                </div>
              </StyledScrollbars>
            </WhenDesktop>
          </Col>

          <Col sm={24} md={12}>
            <Statistic title='Suma dostępnych kredytów' value={inveesContext.tokens} prefix={<GiTwoCoins style={{ color: '#ff8d23' }} />} />

            {inveesContext.tokens > 0 ? (
              <div className='mt-4'>
                <p>Sprawdź czy imię jest poprawne i wygeneruj inwitkę. Twoje konto zostanie pomniejszone o jeden kredyt. Tej operacji nie można cofnąć.</p>
                <p>Zwróć uwagę na odpowiednią odmianę wpisanego imienia, która powinna być dostosowana do treści inwitki. </p>
                <p>
                  <span className='text-xs'>przykład biernika:</span>
                  <br />
                  <span className='italic'>
                    mam zaszczyt zaprosić <strong>Piotra</strong>
                  </span>
                </p>
                <p>
                  <span className='text-xs'>przykład mianownika:</span>
                  <br />
                  <span className='italic'>
                    został zaproszony <strong>Piotr</strong>
                  </span>
                </p>
              </div>
            ) : (
              <div className='mt-4'>Dzięki kredytom możesz generować inwitki. Jeden kredyt wymieniasz na jedną imienną inwitkę.</div>
            )}
          </Col>
        </Row>
      </Modal>

      <Layout
        header={
          <TitleBar
            path='/projects'
            isLoading={globalContext.isBusy}
            leftExtras={
              <>
                <StyledButton color={theme.whiteButton} bgColor='transparent' onClick={() => navigate(`/projects/${inveeId}/create`)}>
                  <Space>
                    <LeftOutlined />
                    <WhenDesktop>Edytuj</WhenDesktop>
                  </Space>
                </StyledButton>
              </>
            }
            rightExtras={
              <Space>
                <DarkModeControl />
                <UserControl />
              </Space>
            }
          ></TitleBar>
        }
        footer={<Footer />}
      >
        <Content breadcrumbRoutes={breadcrumbRoutes}>
          {inveesContext.currentInvee && (
            <>
              <Card title='Wygeneruj inwitki aby zapraszać gości'>
                <div className='w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-8'>
                  <Space direction='vertical'>
                    <div>
                      <div className='my-1 font-bold'>Imię:</div>
                      <Input
                        showCount
                        placeholder='wpisz imię gościa'
                        value={guest}
                        onChange={(e) => {
                          setGuest(e.target.value);
                        }}
                        maxLength={40}
                      />
                    </div>
                    <div>
                      <Button
                        onClick={() => {
                          setGuest(guest.replace(GUEST_REGEX, '').trim());
                          setShowModal(true);
                        }}
                        disabled={!guest || guest.trim().length < MIN_GUEST_LENGTH}
                        type='primary'
                      >
                        <Space className='btn'>
                          <WhenDesktop>
                            <InveeLogo style={{ height: '18px', width: '12px' }} />
                          </WhenDesktop>
                          Wygeneruj
                        </Space>
                      </Button>
                    </div>
                  </Space>
                  <div className='text-justify'>
                    <div className='float-left text-4xl mr-2'>
                      <InveeLogo style={{ height: '36px', width: '30px', color: theme.primary }} />
                    </div>
                    <div>
                      W tym miejscu możesz wygenerować inwitki. <strong>Inwitka</strong> to imienne, cyfrowe zaproszenie wg Twojego projektu, które możesz przekazać gościowi w postaci <strong>linku</strong> lub <strong>kodu QR</strong>.
                    </div>
                  </div>

                  <div className='text-justify '>
                    <div className='float-left text-4xl mr-2'>
                      <TbWorldWww style={{ color: theme.primary }} />
                    </div>
                    <div>
                      Użyj <strong>linku</strong> jeśli chcesz wysłać inwitkę za pomocą <strong>e-maila</strong> lub <strong>komunikatora</strong>. Twoi goście mogą wyświetlać inwitki na ekranach komputerów, tabletów lub smartfonów. Bezpośrednio w przeglądarce internetowej albo w <strong>aplikacji mobilnej</strong> na Android i iOS.
                    </div>
                  </div>

                  <div className='text-justify '>
                    <div className='float-left text-4xl mr-2'>
                      <ImQrcode style={{ color: theme.primary }} />
                    </div>
                    <div>
                      Użyj <strong>kodu QR</strong> jeśli chcesz nadrukować inwitkę na <strong>papierowe zaproszenie</strong>. W ten sposób połączysz nowoczesne funkcje (np. <strong>nawigację do miejsca wydarzenia</strong>) z klasyczną formą zaproszenia.
                    </div>
                  </div>
                </div>
              </Card>

              <div className='mt-4'>{forceRerender !== null && <InveeStringList forceRerender={forceRerender} scrollToInveeListRef={scrollToInveeListRef} hideWhenEmpty={true} />}</div>
            </>
          )}
        </Content>
      </Layout>
    </>
  );
};

//InveeStringCreator.propTypes = {};

export default InveeStringCreator;
