import React, { useContext, useEffect, useState } from 'react';
import GlobalContext from 'src/context/GlobalContext';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
//import styled from 'styled-components';
import * as Ga4 from 'src/misc/Ga4';
import Layout from 'src/components/layout/Layout';
import TitleBar from 'src/components/layout/TitleBar';
import Footer from 'src/components/layout/Footer';
import Content from 'src/components/layout/Content';
import UserControl from 'src/components/controls/userControl/UserControl';
import DarkModeControl from 'src/components/controls/darkModeControl/DarkModeControl';
import { Space } from 'antd';
import StyledButton from 'src/components/styled/StyledButton';
import { LeftOutlined } from '@ant-design/icons';
import WhenDesktop from 'src/components/layout/WhenDesktop';
import useTheme from 'src/hooks/useTheme';
import { blogList } from './misc/config';
import { getStaticFile } from 'src/api/invee';
import { Helmet } from 'react-helmet';

//import PropTypes from 'prop-types';

// const StylesWrapper = styled.div``;

const BlogHome = () => {
  const globalContext = useContext(GlobalContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { theme } = useTheme();
  const { articleId } = useParams();
  const articleDetails = blogList.find((blog) => blog.id === articleId);

  const breadcrumbRoutes = globalContext.userInfo
    ? [
        {
          title: 'Moje projekty',
          path: '/projects',
        },
        {
          title: 'Blog',
          path: '/blog',
        },
        {
          title: articleDetails.title,
        },
      ]
    : [
        {
          title: 'Blog',
          path: '/blog',
        },
        {
          title: articleDetails.title,
        },
      ];

  const [blogHtml, setBlogHtml] = useState(null);

  useEffect(() => {
    Ga4.pageView(`Blog - ${articleDetails.id}`);
    loadContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadContent = async () => {
    if (blogHtml) return;
    const file = articleDetails.file;
    const html = await getStaticFile(file);
    setBlogHtml(html);
    console.log(html);
  };

  return (
    <>
      <Helmet>
        <title>{articleDetails.title}</title>
        <meta name='keywords' content={articleDetails.keywords} />
        <meta name='description' content={articleDetails.description} />
      </Helmet>
      <Layout
        header={
          <TitleBar
            path='/'
            isLoading={globalContext.isBusy}
            leftExtras={
              <>
                {location.key !== 'default' && (
                  <StyledButton color={theme.whiteButton} bgColor='transparent' onClick={() => navigate(-1)}>
                    <Space>
                      <LeftOutlined />
                      <WhenDesktop>Cofnij</WhenDesktop>
                    </Space>
                  </StyledButton>
                )}
              </>
            }
            rightExtras={
              <Space>
                <DarkModeControl />
                <UserControl />
              </Space>
            }
          />
        }
        footer={<Footer />}
      >
        <Content breadcrumbRoutes={breadcrumbRoutes}>{blogHtml && <div className='text-justify leading-8 mx-2 my-2 lg:mx-64 lg:my-12' dangerouslySetInnerHTML={{ __html: blogHtml }} />}</Content>
      </Layout>
    </>
  );
};

//BlogHome.propTypes = {};

export default BlogHome;
