import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Breadcrumb } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { notEmptyArray } from '../../misc/Misc';

const Breadcrumb1 = ({ routes }) => {
  const navigate = useNavigate();

  const getItems = () => {
    const result = [{ title: <HomeOutlined className='cursor-pointer' />, onClick: () => navigate('/') }];
    if (true === notEmptyArray(routes)) {
      for (const route of routes) {
        if (route.path) {
          result.push({ title: <div className='cursor-pointer'>{route.title}</div>, onClick: () => navigate(route.path) });
        } else {
          result.push({ title: route.title });
        }
      }
    }

    return result;
  };

  return <Breadcrumb className=' mb-4' items={getItems()} />;
};

Breadcrumb1.propTypes = {
  routes: PropTypes.array,
};

export default Breadcrumb1;
