const styles = [
  //#region "COMUNION STYLES 2"
  {
    id: 'flat-communion-1',
    name: 'Inwitka komuniijna',
    thumbnail: '/images/styles/flat-communion-1/thumbnail.png',
    bgColor: '#ffffff',
    color: '#3e488c',
    topImage: '/images/styles/flat-communion-1/top.jpg',
    bgImage: '/images/styles/flat-communion-1/bg.jpg',
    bottomImage: '/images/styles/flat-communion-1/bottom.jpg',
    // separatorImage: '/images/styles/wedding-1/separator.jpg',
    tags: ['Na pierwszą komunię świętą'],
    contents: 'comunion',
  },
  //#endregion

  //#region "WEDDING STYLES 2"
  {
    id: 'flat-wedding-1',
    name: 'Inwitka ślubna',
    thumbnail: '/images/styles/flat-wedding-1/thumbnail.png',
    bgColor: '#ecf9fa',
    color: '#00214d',
    topImage: '/images/styles/flat-wedding-1/top.jpg',
    bgImage: '/images/styles/flat-wedding-1/bg.jpg',
    bottomImage: '/images/styles/flat-wedding-1/bottom.jpg',
    // separatorImage: '/images/styles/wedding-1/separator.jpg',
    tags: ['Na ślub'],
    contents: 'wedding',
  },
  {
    id: 'flat-wedding-3',
    name: 'Inwitka ślubna',
    thumbnail: '/images/styles/flat-wedding-3/thumbnail.png',
    bgColor: '#ffffff',
    color: '#02294c',
    topImage: '/images/styles/flat-wedding-3/top.jpg',
    bgImage: '/images/styles/flat-wedding-3/bg.jpg',
    bottomImage: '/images/styles/flat-wedding-3/bottom.jpg',
    // separatorImage: '/images/styles/wedding-1/separator.jpg',
    tags: ['Na ślub'],
    contents: 'wedding',
  },
  {
    id: 'flat-wedding-2',
    name: 'Inwitka ślubna',
    thumbnail: '/images/styles/flat-wedding-2/thumbnail.png',
    bgColor: '#f7ece5',
    color: '#2a2940',
    topImage: '/images/styles/flat-wedding-2/top.jpg',
    bgImage: '/images/styles/flat-wedding-2/bg.jpg',
    bottomImage: '/images/styles/flat-wedding-2/bottom.jpg',
    // separatorImage: '/images/styles/wedding-1/separator.jpg',
    tags: ['Na ślub'],
    contents: 'wedding',
  },
  {
    id: 'flat-wedding-4',
    name: 'Inwitka ślubna',
    thumbnail: '/images/styles/flat-wedding-4/thumbnail.png',
    bgColor: '#fef2df',
    color: '#552100',
    topImage: '/images/styles/flat-wedding-4/top.jpg',
    bgImage: '/images/styles/flat-wedding-4/bg.jpg',
    bottomImage: '/images/styles/flat-wedding-4/bottom.jpg',
    // separatorImage: '/images/styles/wedding-1/separator.jpg',
    tags: ['Na ślub'],
    contents: 'wedding',
  },
  {
    id: 'flat-wedding-6',
    name: 'Inwitka ślubna',
    thumbnail: '/images/styles/flat-wedding-6/thumbnail.png',
    bgColor: '#f2f2f2',
    color: '#462623',
    topImage: '/images/styles/flat-wedding-6/top.jpg',
    bgImage: '/images/styles/flat-wedding-6/bg.jpg',
    bottomImage: '/images/styles/flat-wedding-6/bottom.jpg',
    // separatorImage: '/images/styles/wedding-1/separator.jpg',
    tags: ['Na ślub'],
    contents: 'wedding',
  },
  {
    id: 'flat-wedding-5',
    name: 'Inwitka ślubna',
    thumbnail: '/images/styles/flat-wedding-5/thumbnail.png',
    bgColor: '#ffecea',
    color: '#311b6c',
    topImage: '/images/styles/flat-wedding-5/top.jpg',
    bgImage: '/images/styles/flat-wedding-5/bg.jpg',
    bottomImage: '/images/styles/flat-wedding-5/bottom.jpg',
    // separatorImage: '/images/styles/wedding-1/separator.jpg',
    tags: ['Na ślub'],
    contents: 'wedding',
  },
  //#endregion

  //#region "HEN/BACHELOR STYLES 2"
  {
    id: 'flat-bachelor-1',
    name: 'Inwitka',
    thumbnail: '/images/styles/flat-bachelor-1/thumbnail.png',
    bgColor: '#fe7d9a',
    color: '#2b2a5a',
    topImage: '/images/styles/flat-bachelor-1/top.jpg',
    bgImage: '/images/styles/flat-bachelor-1/bg.jpg',
    bottomImage: '/images/styles/flat-bachelor-1/bottom.jpg',
    // separatorImage: '/images/styles/wedding-1/separator.jpg',
    tags: ['Na wieczór panieński / kawalerski'],
    contents: 'hen',
  },
  {
    id: 'flat-bachelor-2',
    name: 'Inwitka',
    thumbnail: '/images/styles/flat-bachelor-2/thumbnail.png',
    bgColor: '#FFAD51',
    color: '#182148',
    topImage: '/images/styles/flat-bachelor-2/top.jpg',
    bgImage: '/images/styles/flat-bachelor-2/bg.jpg',
    bottomImage: '/images/styles/flat-bachelor-2/bottom.jpg',
    // separatorImage: '/images/styles/wedding-1/separator.jpg',
    tags: ['Na wieczór panieński / kawalerski'],
    contents: 'bachelor',
  },
  // {
  //   id: 'flat-bachelor-3',
  //   name: 'Inwitka',
  //   thumbnail: '/images/styles/flat-bachelor-3/thumbnail.png',
  //   bgColor: '#2c8bb5',
  //   color: '#fde5e5',
  //   topImage: '/images/styles/flat-bachelor-3/top.jpg',
  //   bgImage: '/images/styles/flat-bachelor-3/bg.jpg',
  //   bottomImage: '/images/styles/flat-bachelor-3/bottom.jpg',
  //   // separatorImage: '/images/styles/wedding-1/separator.jpg',
  //   tags: ['Na wieczór panieński / kawalerski'],
  //   contents: 'bachelor',
  // },
  {
    id: 'flat-bachelor-4',
    name: 'Inwitka',
    thumbnail: '/images/styles/flat-bachelor-4/thumbnail.png',
    bgColor: '#ff9df1',
    color: '#090957',
    topImage: '/images/styles/flat-bachelor-4/top.jpg',
    bgImage: '/images/styles/flat-bachelor-4/bg.jpg',
    bottomImage: '/images/styles/flat-bachelor-4/bottom.jpg',
    // separatorImage: '/images/styles/wedding-1/separator.jpg',
    tags: ['Na wieczór panieński / kawalerski'],
    contents: 'bachelor',
  },
  {
    id: 'flat-bachelor-5',
    name: 'Inwitka',
    thumbnail: '/images/styles/flat-bachelor-5/thumbnail.png',
    bgColor: '#420b38',
    color: '#ffd3fd',
    topImage: '/images/styles/flat-bachelor-5/top.jpg',
    bgImage: '/images/styles/flat-bachelor-5/bg.jpg',
    bottomImage: '/images/styles/flat-bachelor-5/bottom.jpg',
    // separatorImage: '/images/styles/wedding-1/separator.jpg',
    tags: ['Na wieczór panieński / kawalerski'],
    contents: 'bachelor',
  },
  //#endregion

  //#region "BIRTHDAY STYLES 2"
  {
    id: 'flat-bday-1',
    name: 'Inwitka urodzinowa',
    thumbnail: '/images/styles/flat-bday-1/thumbnail.png',
    bgColor: '#64fef4',
    color: '#001b38',
    topImage: '/images/styles/flat-bday-1/top.jpg',
    bgImage: '/images/styles/flat-bday-1/bg.jpg',
    bottomImage: '/images/styles/flat-bday-1/bottom.jpg',
    // separatorImage: '/images/styles/bday-1/separator.jpg',
    tags: ['Na urodziny'],
    contents: 'birthday',
  },
  {
    id: 'flat-bday-3',
    name: 'Inwitka urodzinowa',
    thumbnail: '/images/styles/flat-bday-3/thumbnail.png',
    bgColor: '#de5f5a',
    color: '#fff4d4',
    topImage: '/images/styles/flat-bday-3/top.jpg',
    bgImage: '/images/styles/flat-bday-3/bg.jpg',
    bottomImage: '/images/styles/flat-bday-3/bottom.jpg',
    // separatorImage: '/images/styles/bday-1/separator.jpg',
    tags: ['Na urodziny'],
    contents: 'birthday',
  },
  {
    id: 'flat-bday-2',
    name: 'Inwitka urodzinowa',
    thumbnail: '/images/styles/flat-bday-2/thumbnail.png',
    bgColor: '#fee7d4',
    color: '#370070',
    topImage: '/images/styles/flat-bday-2/top.jpg',
    bgImage: '/images/styles/flat-bday-2/bg.jpg',
    bottomImage: '/images/styles/flat-bday-2/bottom.jpg',
    // separatorImage: '/images/styles/bday-1/separator.jpg',
    tags: ['Na urodziny'],
    contents: 'birthday',
  },
  {
    id: 'flat-bday-4',
    name: 'Inwitka urodzinowa',
    thumbnail: '/images/styles/flat-bday-4/thumbnail.png',
    bgColor: '#d2bbe7',
    color: '#1a2a30',
    topImage: '/images/styles/flat-bday-4/top.jpg',
    bgImage: '/images/styles/flat-bday-4/bg.jpg',
    bottomImage: '/images/styles/flat-bday-4/bottom.jpg',
    // separatorImage: '/images/styles/bday-1/separator.jpg',
    tags: ['Na urodziny'],
    contents: 'birthday',
  },
  {
    id: 'flat-bday-6',
    name: 'Inwitka urodzinowa',
    thumbnail: '/images/styles/flat-bday-6/thumbnail.png',
    bgColor: '#dbf3ff',
    color: '#3e2125',
    topImage: '/images/styles/flat-bday-6/top.jpg',
    bgImage: '/images/styles/flat-bday-6/bg.jpg',
    bottomImage: '/images/styles/flat-bday-6/bottom.jpg',
    // separatorImage: '/images/styles/bday-1/separator.jpg',
    tags: ['Na urodziny'],
    contents: 'birthday',
  },
  {
    id: 'flat-bday-5',
    name: 'Inwitka urodzinowa',
    thumbnail: '/images/styles/flat-bday-5/thumbnail.png',
    bgColor: '#fdb009ff',
    color: '#04221a',
    topImage: '/images/styles/flat-bday-5/top.jpg',
    bgImage: '/images/styles/flat-bday-5/bg.jpg',
    bottomImage: '/images/styles/flat-bday-5/bottom.jpg',
    // separatorImage: '/images/styles/bday-1/separator.jpg',
    tags: ['Na urodziny'],
    contents: 'birthday',
  },
  //#endregion

  //#region "OTHERS STYLES 2"
  {
    id: 'flat-others-1',
    name: 'Inwitka',
    thumbnail: '/images/styles/flat-others-1/thumbnail.png',
    bgColor: '#fbae44',
    color: '#310f23',
    topImage: '/images/styles/flat-others-1/top.jpg',
    bgImage: '/images/styles/flat-others-1/bg.jpg',
    bottomImage: '/images/styles/flat-others-1/bottom.jpg',
    // separatorImage: '/images/styles/wedding-1/separator.jpg',
    tags: ['Na różne okazje'],
    contents: 'other',
  },
  {
    id: 'flat-others-2',
    name: 'Inwitka',
    thumbnail: '/images/styles/flat-others-2/thumbnail.png',
    bgColor: '#c9e9b6',
    color: '#3f3f47',
    topImage: '/images/styles/flat-others-2/top.jpg',
    bgImage: '/images/styles/flat-others-2/bg.jpg',
    bottomImage: '/images/styles/flat-others-2/bottom.jpg',
    // separatorImage: '/images/styles/wedding-1/separator.jpg',
    tags: ['Na różne okazje'],
    contents: 'other',
  },
  {
    id: 'flat-others-3',
    name: 'Inwitka',
    thumbnail: '/images/styles/flat-others-3/thumbnail.png',
    bgColor: '#fff5de',
    color: '#002429',
    topImage: '/images/styles/flat-others-3/top.jpg',
    bgImage: '/images/styles/flat-others-3/bg.jpg',
    bottomImage: '/images/styles/flat-others-3/bottom.jpg',
    // separatorImage: '/images/styles/wedding-1/separator.jpg',
    tags: ['Na różne okazje'],
    contents: 'other',
  },
  {
    id: 'flat-others-4',
    name: 'Inwitka',
    thumbnail: '/images/styles/flat-others-4/thumbnail.png',
    bgColor: '#f9626b',
    color: '#260d35',
    topImage: '/images/styles/flat-others-4/top.jpg',
    bgImage: '/images/styles/flat-others-4/bg.jpg',
    bottomImage: '/images/styles/flat-others-4/bottom.jpg',
    // separatorImage: '/images/styles/wedding-1/separator.jpg',
    tags: ['Na różne okazje'],
    contents: 'other',
  },
  {
    id: 'flat-others-5',
    name: 'Inwitka',
    thumbnail: '/images/styles/flat-others-5/thumbnail.png',
    bgColor: '#ea7037',
    color: '#00214f',
    topImage: '/images/styles/flat-others-5/top.jpg',
    bgImage: '/images/styles/flat-others-5/bg.jpg',
    bottomImage: '/images/styles/flat-others-5/bottom.jpg',
    // separatorImage: '/images/styles/wedding-1/separator.jpg',
    tags: ['Na różne okazje'],
    contents: 'other',
  },

  //#endregion

  //#region "BAPTISM STYLES" 2
  {
    id: 'flat-baptism-1',
    name: 'Inwitka na chrzest różowa',
    thumbnail: '/images/styles/flat-baptism-1/thumbnail.png',
    bgColor: '#ffffff',
    color: '#45748c',
    topImage: '/images/styles/flat-baptism-1/top.jpg',
    bgImage: '/images/styles/flat-baptism-1/bg.jpg',
    bottomImage: '/images/styles/flat-baptism-1/bottom.jpg',
    // separatorImage: '/images/styles/wedding-1/separator.jpg',
    tags: ['Na chrzest'],
    contents: 'christening',
  },
  {
    id: 'flat-baptism-2',
    name: 'Inwitka na chrzest różowa',
    thumbnail: '/images/styles/flat-baptism-2/thumbnail.png',
    bgColor: '#ffffff',
    color: '#b10d6c',
    topImage: '/images/styles/flat-baptism-2/top.jpg',
    bgImage: '/images/styles/flat-baptism-2/bg.jpg',
    bottomImage: '/images/styles/flat-baptism-2/bottom.jpg',
    // separatorImage: '/images/styles/wedding-1/separator.jpg',
    tags: ['Na chrzest'],
    contents: 'christening',
  },
  //#endregion
];

const tags = [
  {
    tag: 'Na ślub',
  },
  {
    tag: 'Na urodziny',
  },
  {
    tag: 'Na wieczór panieński / kawalerski',
  },
  {
    tag: 'Na chrzest',
  },
  {
    tag: 'Na pierwszą komunię świętą',
  },
  {
    tag: 'Na różne okazje',
  },
];

export const getAllTags = () => {
  return tags.map((x) => {
    return { value: x.tag, label: x.tag };
  });
};

export const getTagDetails = (tag) => {
  let tagDetails = tags.find((x) => x.tag === tag);
  if (!tagDetails) tagDetails = tags[0];
  return tagDetails;
};

export const inveeContents = {
  comunion: {
    title: 'Pierwsza Komunia Święta',
    contents: { content1: '<p>Niniejszym</p><p>mamy zaszczyt zaprosić</p>', content2: '<p>na uroczystość</p><p>przyjęcia sakramentu</p><p>Pierwszej Komunii Świętej</p>' },
  },
  wedding: {
    title: 'Zaproszenie na Ślub',
    contents: { content1: '<p>Serdecznie zapraszamy</p>', content2: '<p>na Uroczystość Zaślubin</p><p>i Przyjęcie Weselne</p>' },
  },
  christening: {
    title: 'Sakrament Chrztu',
    contents: { content1: '<p>Z radością</p><p>mamy zaszczyt zaprosić</p>', content2: '<p>na uroczystość przyjęcia</p><p>Sakramentu Chrztu Świętego</p>' },
  },
  birthday: {
    title: 'Zaproszenie na Urodziny',
    contents: { content1: '<p>Serdecznie zapraszam</p>', content2: '<p>na przyjęcie urodzinowe</p>' },
  },
  hen: {
    title: 'Wieczór Panieński',
    contents: { content1: '<p>Mam ogromną przyjemność zaprosić</p>', content2: '<p>na wieczór pełen zabawy i niespodzianek,</p><p>abyśmy razem pożegnały ostatnie chwile mojego panieństwa.</p>' },
  },
  bachelor: {
    title: 'Wieczór Kawalerski',
    contents: { content1: '<p>Mam ogromną przyjemność zaprosić</p>', content2: '<p>na wieczór pełen rozrywki i przygód,</p><p>abyśmy razem pożegnali ostatnie chwile mojej wolności.</p>' },
  },
  other: {
    title: 'Zaproszenie',
    contents: { content1: '<p>Niezmiernie mi miło zaprosić</p>', content2: '<p>na to niesamowie wydarzenie</p>' },
  },
};

export default styles;
