import React from 'react';
import styled from 'styled-components';

//import PropTypes from 'prop-types';

const StylesWrapper = styled.div`
  ul > li {
    margin-block-start: 1em;
    margin-block-end: 1em;
  }

  ol {
    counter-reset: item;
  }

  ol > li {
    counter-increment: item;
    margin-block-start: 1em;
    margin-block-end: 1em;
  }

  ol ol > li {
    display: block;
  }

  ol ol > li:before {
    content: counters(item, '.') '. ';
    margin-left: -20px;
  }

  #page {
    counter-reset: heading;
  }

  h3:before {
    content: counter(heading) '. ';
    counter-increment: heading;
  }

  h3 {
    counter-reset: subheading;
  }

  h4:before {
    content: counter(heading) '.' counter(subheading) '. ';
    counter-increment: subheading;
  }
`;

const DataProcessingContent = () => {
  // const { theme } = useTheme();
  return (
    <StylesWrapper>
      <div className='my-4 lg:my-4 flex flex-col items-center gap-2' id='page'>
        <div className=' w-full lg:w-2/3 text-justify'>
          <h3>Informacje dla gościa</h3>
          <h4>W jaki sposób jest przechowywane moje imię w serwisie?</h4>
          <p>Imię zaproszonego gościa podane na zaproszeniu co do zasady nie stanowi danej osobowej. Jednak dla pełnej przejrzystości działania Serwisu, podajemy w jaki sposób ta informacja jest przetwarzana. </p>
          <ol>
            <li>
              <strong>Serwis nie przechowuje imion zapraszanych gości w swoich zbiorach</strong> - w jakiejkolwiek postaci zaszyfrowanej lub niezaszyfrowanej.
            </li>
            <li>W momencie gdy użytkownik na podstawie projektu generuje inwitkę dla gościa, podaje jego imię, które zostaje zaszyfrowane przez Serwis i stanowi fragment adresu URL lub kodu QR.</li>
            <li>Adres URL i kod QR nie są utrwalane w zbiorach serwisu. Mogą być one jedynie tymczasowo zapisane w przeglądarce użytkownika (Local Storage). Użytkownik pobiera adres URL lub kod QR na swoje urządzenie i od tego momentu to on staje się za nie odpowiedzialny.</li>
            <li>W momencie gdy zaproszony gość otwiera inwitkę na swoim urządzeniu, Serwis odszyfrowuje jego imię z adresu URL lub kodu QR i wyświetla je na ekranie razem z treścią.</li>
          </ol>
          <h4>W jaki sposób trwale skasować wygenerowaną inwitkę?</h4>
          <ol>
            <li>Aby skasować inwitkę wraz z zaszyfrowanym imieniem, należy trwale usunąć wszystkie kopie adresu URL oraz kodu QR na wszystkich urządzeniach, na których zostały zapisane (lub fizycznie je zniszczyć, jeżeli zostały wydrukowane). Np. skasować wszystkie kopie e-maila z adresem URL inwitki i opróżnić kosz. Zniszczyć zaproszenie, na które został nadrukowany kod QR.</li>
          </ol>
        </div>
        <div className=' w-full lg:w-2/3 text-justify'>
          <h3>Informacje dla użytkownika</h3>
          <h4>W jaki sposób są przechowywane moje dane w Serwisie?</h4>
          <p>Dane użytkowników Serwisu są przechowywane:</p>
          <ol>
            <li>
              Dane od dostawcy tożsamości (Facebook lub Google) otrzymane za zgodą użytkownika podczas pierwszego logowania, przechowywane są w systemie autoryzacji i autentykacji użytkowników AWS Cognito, więcej informacji na temat jak działa AWS Cognito znajdziesz pod adresem:{' '}
              <a target='_blank' rel='noreferrer' href='https://docs.aws.amazon.com/cognito/'>
                https://docs.aws.amazon.com/cognito/
              </a>
            </li>
            <li>
              Dane dobrowolnie wprowadzone przez użytkownika do projektu inwitki przechowywane są w usłudze AWS Dynamo DB. Więcej informacji na temat jak działa AWS Dynamo DB znajdziesz pod adresem:{' '}
              <a target='_blank' rel='noreferrer' href='https://aws.amazon.com/dynamodb/'>
                https://aws.amazon.com/dynamodb/
              </a>
            </li>
            <li>
              Zgodnie ze standardami branżowymi dane są szyfrowane <strong>in transit</strong> oraz <strong>at rest</strong>:
              <br />
              <a target='_blank' rel='noreferrer' href='https://docs.aws.amazon.com/cognito/latest/developerguide/data-protection.html'>
                https://docs.aws.amazon.com/cognito/latest/developerguide/data-protection.html
              </a>
              <br />
              <a target='_blank' rel='noreferrer' href='https://docs.aws.amazon.com/amazondynamodb/latest/developerguide/EncryptionAtRest.html'>
                https://docs.aws.amazon.com/amazondynamodb/latest/developerguide/EncryptionAtRest.html
              </a>
            </li>
          </ol>
          <p>Przeglądarka internetowa oraz aplikacja mobilna po pobraniu danych z serwisu odszyfrowuje je aby móc je wyświetlić na urządzeniu gościa. Dane mogą być tymczasowo zapisywane w pamięci podręcznej na urządzeniach gości w postaci niezaszyfrowanej. Aby je usunąć, należy usunąć historię przeglądania i dane witryn w przypadku przeglądarki lub usunąć pobraną inwitkę w aplikacji mobilnej.</p>
          <h4>W jaki sposób Serwis realizuje prawa użytkownika w zakresie przetwarzania danych osobowych?</h4>
          <p>Użytkownik poprzez wybranie odpowiedniej opcji w menu Serwisu nawiguje do podstrony Profil Użytkownika.</p>
          <ol>
            <li>Profil Użytkownika zapewnia wgląd w dane służące do identyfikacji użytkownika.</li>
            <li>Profil Użytkownika zapewnia możliwość bycia zapomnianym poprzez wybranie opcji Usuń Konto.</li>
          </ol>
          <p>Użytkownik poprzez wybranie odpowiedniej opcji w menu Serwisu nawiguje do podstron Lista Projektów i Edytuj Projekt.</p>
          <ol>
            <li>Lista Projektów zapewnia możliwość usunięcia projektu wraz z danymi osobowymi użytkownika (o ile zostały przez niego podane) lub przejście do Edytuj Projekt.</li>
            <li>Edytuj Projekt zapewnia wgląd w dane użytkownika podane w treści projektu Inwitki, a także umożliwia ich poprawianie, sprostowanie lub usunięcie.</li>
          </ol>
        </div>
        <div className=' w-full lg:w-2/3 text-justify'>
          <h3>Więcej informacji</h3>
          <p>Informacje na temat przetwarzania danych przez Serwis znajdziesz również na stronach:</p>
          <ul>
            <li>
              <a target='_blank' rel='noreferrer' href='/pages/privacy-policy'>
                Polityka Prywatności
              </a>
            </li>
            <li>
              <a target='_blank' rel='noreferrer' href='/pages/regulations'>
                Regulamin
              </a>
            </li>
          </ul>
        </div>
      </div>
    </StylesWrapper>
  );
};

DataProcessingContent.propTypes = {};

export default DataProcessingContent;
