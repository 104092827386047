import { useContext } from 'react';
import GlobalContext from '../../context/GlobalContext';
import PropTypes from 'prop-types';
import { SCREEN_RATIOS } from 'src/misc/Config';

const WhenLandscape = ({ children }) => {
  const globalContext = useContext(GlobalContext);
  if (globalContext.screenRatio === SCREEN_RATIOS.landscape) return children;
  return null;
};

WhenLandscape.propTypes = {
  children: PropTypes.any,
};

export default WhenLandscape;
