import React, { useState } from 'react';
// import { useScreen } from 'src/hooks/useScreen';
// import { SCREEN_MODES } from 'src/misc/Config';
import PropTypes from 'prop-types';
import { ReactComponent as InveeLogo } from 'src/components/svg/invee-logo.svg';
import { WarningOutlined, DeleteOutlined } from '@ant-design/icons';
import YesNoDialog from 'src/components/layout/YesNoDialog';
import { notEmptyArray } from 'src/misc/Misc';
import { Space, Badge } from 'antd';
import StyledButton from 'src/components/styled/StyledButton';
import { TfiRulerAlt2 } from 'react-icons/tfi';
import * as Ga4 from 'src/misc/Ga4';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

const InveeBox = ({ invee, onCreateClick, onInviteClick, onDelete, expiredAt }) => {
  const [showMenu, setShowMenu] = useState(false);
  // const { screenMode } = useScreen();

  const expiredRibbon = (control) => {
    if (expiredAt)
      return (
        <Badge.Ribbon text={`Wygasł ${dayjs(expiredAt).fromNow()}`} color='red'>
          {control}
        </Badge.Ribbon>
      );

    return control;
  };

  return (
    <div
      onMouseLeave={(e) => {
        setShowMenu(false);
      }}
      onMouseEnter={(e) => {
        setShowMenu(true);
      }}
      style={{ color: invee?.style?.color, backgroundColor: invee?.style?.bgColor, position: 'relative' }}
      className={'relative h-48 shadow-md shadow-neutral-200 dark:shadow-neutral-800 rounded-[25px] cursor-pointer lg:hover:scale-105 transition-all'}
    >
      <div className='absolute z-10 top-0 right-0 bottom-0 left-0 rounded-[25px]' style={{ background: 'linear-gradient( #ffffff33, #00000022)' }} />

      {/* <MENU> */}
      <div
        onMouseLeave={(e) => {
          setShowMenu(false);
        }}
        className={`${showMenu ? null : 'hidden'} absolute right-0 top-0 bottom-0 left-0 overflow-hidden z-30 flex flex-col gap-3 rounded-[25px] justify-center items-center`}
      >
        {!expiredAt && (
          <>
            <StyledButton
              color={invee?.style?.color}
              size='large'
              className='shadow-md'
              style={{ width: '200px' }}
              onClick={() => {
                setShowMenu(false);
                if (onCreateClick) onCreateClick(invee.inveeId);
              }}
            >
              <Space className='btn'>
                <TfiRulerAlt2 />
                Edytuj
              </Space>
            </StyledButton>
            <StyledButton
              color={invee?.style?.color}
              size='large'
              className='shadow-md'
              style={{ width: '200px' }}
              onClick={() => {
                setShowMenu(false);
                if (onInviteClick) onInviteClick(invee.inveeId);
              }}
            >
              <Space className='btn'>
                <InveeLogo style={{ height: '24px', width: '18px' }} />
                Zapraszaj gości
              </Space>
            </StyledButton>
          </>
        )}
        <YesNoDialog
          // showConfirmation
          // labelUnconfirmed='kliknij aby potwierdzić'
          // labelConfirmed='potwierdzam'
          onYesClick={() => {
            Ga4.event('Landing', 'Delete_project', 'Delete_project');
            setShowMenu(false);
            if (onDelete) onDelete(invee.inveeId);
          }}
          onNoClick={() => {
            setShowMenu(false);
          }}
          title='Usuń projekt'
          body={
            <>
              <div className='mb-4'>Czy na pewno chcesz trwale usunąć ten projekt?</div>
              <div>
                <WarningOutlined className='mr-2 text-red-600' />
                Jeśli wygenerowałeś inwitki do tego projektu, nie będzie można ich ponownie otworzyć.
              </div>
              <div className='mb-4'>
                <WarningOutlined className='mr-2 text-red-600' />
                Tej operacji nie można cofnąć.
              </div>
            </>
          }
          labelYes='Rozumiem, usuń'
          labelNo='Anuluj'
        >
          <StyledButton color={invee?.style?.color} size='large' className='shadow-md' style={{ width: '200px' }}>
            <Space className='btn'>
              <DeleteOutlined />
              Usuń
            </Space>
          </StyledButton>
        </YesNoDialog>
      </div>
      {/* </MENU> */}

      {/* <LABELS> */}

      <div className={`${showMenu ? 'hidden' : null} flex flex-col justify-center absolute z-20 top-0 right-0 bottom-0 left-0 rounded-[25px] ${invee?.font?.class}`}>
        <InveeLogo style={{ height: '32px' }} />
        <div className='w-full flex flex-row justify-center'>
          <div className='overflow-hidden truncate text-ellipsis w-11/12 px-2 text-base font-bold text-center'>{invee.title}</div>
        </div>
        <div className='w-full flex flex-row justify-center'>
          <div className='overflow-hidden truncate text-ellipsis w-11/12 px-1 text-base text-center'>{true === notEmptyArray(invee.datesLocations) && invee.datesLocations[0].date && dayjs().to(dayjs(invee.datesLocations[0].date))}</div>
        </div>
      </div>
      {/* <LABELS> */}

      {expiredRibbon(<div className='z-50 top-0 right-0 bottom-0 left-0 '></div>)}
    </div>
  );
};

InveeBox.propTypes = {
  invee: PropTypes.object.isRequired,
  onCreateClick: PropTypes.func,
  onInviteClick: PropTypes.func,
  onDelete: PropTypes.func,
  expiredAt: PropTypes.string,
};

export default InveeBox;
