import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Input, Card } from 'antd';
import InveesContext from 'src/context/InveesContext';

const InveeEditorStyle = ({ defaultTitle }) => {
  const inveesContext = useContext(InveesContext);

  const [title, setTitle] = useState(null);

  useEffect(() => {
    setTitle(defaultTitle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card>
      <div className='mt-1 font-bold'>Tytuł:</div>
      <div className='mb-1 font-light text-xs'>np: Ślub i Wesele Alicji oraz Marka, Siódme urodziny Janka, Wieczór Panieński Kaśki</div>
      <Input
        showCount
        maxLength={50}
        style={{ width: '100%' }}
        value={title}
        onChange={(e) => {
          setTitle(e.target.value);
          inveesContext.setCurrentEditorChanges({ ...inveesContext.setCurrentEditorChanges, title: e.target.value.trim() });
        }}
      />
    </Card>
  );
};

InveeEditorStyle.propTypes = {
  defaultTitle: PropTypes.string.isRequired,
};

InveeEditorStyle.defaultProps = {
  defaultTitle: '',
};

export default InveeEditorStyle;
