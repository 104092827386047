import dayjs from 'dayjs';

export const sleepMs = (milliseconds) => new Promise((resolve) => setTimeout(resolve, milliseconds));

export const notArrayOrEmpty = (arr) => !Array.isArray(arr) || arr.length < 1;

export const notEmptyArray = (arr) => Array.isArray(arr) && arr.length > 0;

export const notEmptyString = (str) => {
  if (!str) return false;
  if (typeof str !== 'string') return false;
  if (str.length < 1) return false;
  return true;
};

export const objToQueryStringParams = (obj) => {
  const result = [];
  for (const prop in obj) {
    if (!obj[prop]) continue;
    const paramName = prop.replace(/[^a-zA-Z0-9]/g, '');
    const paramValue = encodeURIComponent(obj[prop]);
    result.push(`${paramName}=${paramValue}`);
  }
  if (notArrayOrEmpty(result)) return '';
  return `?${result.join('&')}`;
};

export const dateTimeSorter = (a, b, fieldName) => {
  return dayjs(a[fieldName]).diff(b[fieldName]);
};

export const getInveeUrl = (inweeString) => {
  return `${window.location.origin}/i/${inweeString}`;
};

export const getMapUrl = (coords) => {
  return `https://www.google.com/maps/search/?api=1&query=${coords.lat},${coords.lon}`;
};

export const includesItemOfArray = (arr1, arr2) => {
  if (notArrayOrEmpty(arr1) || notArrayOrEmpty(arr2)) return false;
  return arr1.some((item) => arr2.includes(item));
};

export const handleDownloadAppStore = () => {
  window.open('https://apps.apple.com/pl/app/inwitki/id6504160502', '_blank');
};

export const handleDownloadPlayStore = () => {
  window.open('https://play.google.com/store/apps/details?id=cloud.bullapp.inwitki', '_blank');
};
