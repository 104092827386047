import 'react-image-gallery/styles/css/image-gallery.css';
import ImageGallery from 'react-image-gallery';

import { CaretDownOutlined, LoginOutlined } from '@ant-design/icons';
import { Badge, QRCode, Space } from 'antd';
import { useContext, useEffect } from 'react';
import { useNavigate /*, useSearchParams */ } from 'react-router-dom';
import WhenDesktop from 'src/components/layout/WhenDesktop';
import WhenLandscape from 'src/components/layout/WhenLandscape';
import WhenMobile from 'src/components/layout/WhenMobile';
import WhenPortrait from 'src/components/layout/WhenPortrait';
import { ReactComponent as InveeLogo } from 'src/components/svg/invee-logo.svg';
import { ReactComponent as InveeLogoFull } from 'src/components/svg/invee-logo-full.svg';
import { useScreen } from 'src/hooks/useScreen';
import styled from 'styled-components';
import GlobalContext from '../../../context/GlobalContext';
import { login } from '../../../misc/Session';
import UserControl from '../../controls/userControl/UserControl';
import Footer from '../../layout/Footer';
import Layout from '../../layout/Layout';
import TitleBar from '../../layout/TitleBar';
// import ConsentsDrawer from 'src/components/controls/drawers/ConsentsDrawer';
import { Fade, Zoom } from 'react-awesome-reveal';
import { CiMobile2 } from 'react-icons/ci';
import { GiPositionMarker } from 'react-icons/gi';
import { HiOutlineCursorClick } from 'react-icons/hi';
import { MdOutlineQrCode2 } from 'react-icons/md';
import DarkModeControl from 'src/components/controls/darkModeControl/DarkModeControl';
import StyledButton from 'src/components/styled/StyledButton';
import useTheme from 'src/hooks/useTheme';
import { SCREEN_RATIOS } from 'src/misc/Config';
import * as Ga4 from 'src/misc/Ga4';
import { ReactComponent as AppStoreBadge } from 'src/components/svg/badge-app-store.svg';
import { ReactComponent as PlayStoreBadge } from 'src/components/svg/badge-play-store.svg';
import { handleDownloadAppStore, handleDownloadPlayStore } from 'src/misc/Misc';

const StyledQRCode = styled.div`
  .ant-qrcode {
    border: none !important;
    zoom: 1.4 !important;
  }
`;

const images = [
  {
    original: 'images/invee/szablony-1.png',
    originalAlt: 'Szablony na urodziny',
  },
  {
    original: 'images/invee/szablony-2.png',
    originalAlt: 'Szablony na ślub',
  },
  {
    original: 'images/invee/szablony-3.png',
    originalAlt: 'Szablony na wiczór panieński, kawalerski',
  },
  {
    original: 'images/invee/szablony-4.png',
    originalAlt: 'Szablony na chrzest, komunię',
  },
];

// const SlideItem = ({ picture, title, children }) => (
//   <div className='h-full flex flex-col gap-4 items-center shadow-lg  shadow-neutral-200 dark:shadow-neutral-700 rounded-2xl border border-solid border-neutral-200 dark:border-neutral-700 overflow-hidden'>
//     <div className='bg-neutral-100 dark:bg-neutral-600 w-full h-[160px] px-0 py-4 text-center'>{picture}</div>
//     <div className='text-2xl text-center px-4'>{title}</div>
//     <div className='text-base text-center px-4 mb-4'>{children}</div>
//   </div>
// );

const SlideItem2 = ({ picture, title, children }) => (
  <div className='h-full flex flex-col gap-4 items-center shadow-lg  shadow-neutral-200 dark:shadow-neutral-700 rounded-2xl border border-solid border-neutral-200 dark:border-neutral-700 overflow-hidden'>
    <div className='bg-neutral-100 dark:bg-neutral-600 w-full  px-0 py-4 text-center'>{picture}</div>
    <div className='text-2xl text-center px-4'>{title}</div>
    <div className='text-base text-center px-4 mb-4'>{children}</div>
  </div>
);

const Index = () => {
  const globalContext = useContext(GlobalContext);
  const navigate = useNavigate();
  const { theme, isDarkMode, themeLight } = useTheme();
  // const [searchParams] = useSearchParams();
  const { isDesktop } = useScreen();

  useEffect(() => {
    Ga4.pageView('Landing Page');

    globalContext.setLayoutScrollTop(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const shadowFilter = isDarkMode ? 'drop-shadow(0px 0px 10px #bbbbbb66)' : 'drop-shadow(0px 0px 10px #44444466)';
  const shadowFilter1 = isDarkMode ? 'drop-shadow(0px 0px 8px #00b9ff44)' : 'drop-shadow(0px 0px 8px #00b9ff44)';
  const shadowFilter2 = isDarkMode ? 'drop-shadow(0px 8px 8px #00000088)' : 'drop-shadow(0px 8px 8px #00000088)';

  const ytStyle = { aspectRatio: '16 / 9', height: 'auto', width: '100%', borderRadius: '1rem', filter: shadowFilter, border: '1px solid #888888' };

  const getOpacity = (threshold1, threshold2) => {
    if (globalContext.layoutScrollTop < threshold1) return 0;
    if (globalContext.layoutScrollTop > threshold2) return 1.0;
    return (globalContext.layoutScrollTop - threshold1) / (threshold2 - threshold1);
  };

  const getOpacityInv = (threshold1, threshold2) => {
    if (globalContext.layoutScrollTop < threshold1) return 1.0;
    if (globalContext.layoutScrollTop > threshold2) return 0;
    return (globalContext.layoutScrollTop - threshold2) / (threshold1 - threshold2);
  };

  const getBlur = (threshold1, threshold2) => {
    if (globalContext.layoutScrollTop < threshold1) return 0;
    if (globalContext.layoutScrollTop > threshold2) return 5;
    return ((globalContext.layoutScrollTop - threshold1) / (threshold2 - threshold1)) * 5;
  };

  const getIncreasingScale = (threshold1, threshold2, factor = 4) => {
    if (globalContext.layoutScrollTop < threshold1) return 1;
    if (globalContext.layoutScrollTop > threshold2) return 1.25;
    return 1 + (globalContext.layoutScrollTop - threshold1) / (threshold2 - threshold1) / factor;
  };

  const getDecreasingScale = (threshold1, threshold2, factor = 4) => {
    if (globalContext.layoutScrollTop < threshold1) return 1;
    if (globalContext.layoutScrollTop > threshold2) return 0.75;
    return 1 - (globalContext.layoutScrollTop - threshold1) / (threshold2 - threshold1) / factor;
  };

  const handleWeStart = () => {
    if (globalContext.userInfo) {
      // window.gtag('event', 'We_start_was_logged_in', { event_category: 'Landing' });
      Ga4.event('Landing', 'We_start_was_logged_in', 'We_start / was_logged_in');
      navigate('/projects');
    } else {
      //window.gtag('event', 'We_start_was_logged_out', { event_category: 'Landing' });
      Ga4.event('Landing', 'We_start_was_logged_in', 'We_start / was_logged_in');
      login();
    }
  };

  const getMainImageHeight = () => document.getElementById('33573990-756a-4de4-b5bd-e08e4e49e2cd')?.clientWidth * 0.75;

  const getMainImageWidth = () => document.getElementById('33573990-756a-4de4-b5bd-e08e4e49e2cd')?.clientHeight * 1.3333;

  const getLandingPagePictureStyle = () => {
    return SCREEN_RATIOS.landscape === globalContext.screenRatio ? { width: '100%', height: getMainImageHeight() } : { width: getMainImageWidth(), height: '95%' };
  };

  const landingPagePictures = (
    <div id='33573990-756a-4de4-b5bd-e08e4e49e2cd' className={'relative '} style={getLandingPagePictureStyle()}>
      <img className='w-full absolute top-0 right-0 bottom-0 left 0 z-10' src='/images/invee/cyfrowe-zaproszenia-na-smartfony-1.png' alt='cyfrowe-zaproszenia na smartfony' style={{ filter: shadowFilter2, transform: `scale(${getDecreasingScale(globalContext.screenSize.height * 0.0, globalContext.screenSize.height * 0.6, 6)})` }} />
      <img className='w-full absolute top-0 right-0 bottom-0 left 0 z-20' src='/images/invee/cyfrowe-zaproszenia-na-smartfony-2.png' alt='cyfrowe-zaproszenia na smartfony' style={{ filter: shadowFilter2, transform: `scale(${getDecreasingScale(globalContext.screenSize.height * 0.0, globalContext.screenSize.height * 0.6, 20)})` }} />
      <img className='w-full absolute top-0 right-0 bottom-0 left 0 z-30' src='/images/invee/cyfrowe-zaproszenia-na-smartfony-3.png' alt='cyfrowe-zaproszenia na smartfony' style={{ filter: shadowFilter2, transform: `scale(${getIncreasingScale(globalContext.screenSize.height * 0.0, globalContext.screenSize.height * 0.6, 16)})` }} />
      <img className='w-full absolute top-0 right-0 bottom-0 left 0 z-40' src='/images/invee/cyfrowe-zaproszenia-na-smartfony-4.png' alt='cyfrowe-zaproszenia na smartfony' style={{ filter: shadowFilter2, transform: `scale(${getIncreasingScale(globalContext.screenSize.height * 0.0, globalContext.screenSize.height * 0.6, 6)})` }} />
    </div>
  );

  return (
    <Layout backgroundColor={theme.light1} footerMarginTop='0px' footer={<Footer centredTo={isDesktop ? theme.centredTo : null} />}>
      {/* <ConsentsDrawer /> */}
      <div className={`fixed text-black dark:text-white text-2xl bottom-0 left-0 right-0 text-center animate-bounce ${globalContext.layoutScrollTop > 10 ? 'opacity-0' : 'opacity-60'}`}>{globalContext.layoutScrollTop < 10 && <CaretDownOutlined />}</div>

      {globalContext.layoutScrollTop > 10 && (
        <div className='fixed w-full z-10' style={{ opacity: getOpacity(globalContext.screenSize.height * 0.05, globalContext.screenSize.height * 0.25) }}>
          <TitleBar
            path={null}
            rightExtras={
              <div style={{ opacity: getOpacity(globalContext.screenSize.height * 0.2, globalContext.screenSize.height * 0.4) }} className=' flex flex-row items-center justify-end gap-2'>
                <Space>
                  <DarkModeControl />
                  <UserControl />
                </Space>
              </div>
            }
          />
        </div>
      )}

      <div className='w-full flex flex-row justify-center' style={{ backgroundColor: theme.light2, backgroundImage: `url("images/invee/cyfrowe-zaproszenia-bg-${isDarkMode ? 'dark' : 'light'}.jpg")`, backgroundSize: 'cover', color: themeLight.light1 }}>
        <div style={{ width: '1600px' }}>
          <WhenLandscape>
            <div className={'flex flex-row'} style={{ height: 'calc(100vh)', opacity: getOpacityInv(globalContext.screenSize.height * 0.2, globalContext.screenSize.height * 0.6), filter: `blur(${getBlur(globalContext.screenSize.height * 0.3, globalContext.screenSize.height * 0.7)}px)` }}>
              <div className='flex-1 flex flex-row justify-center items-center gap-2 pl-24'>{landingPagePictures}</div>
              <WhenDesktop>
                <div className='flex-1 flex flex-col pl-12 justify-center overflow-hidden'>
                  <div className='flex flex-row items-center'>
                    <Badge.Ribbon size='large' color='red' text={<span className='blink-5'>NOWOŚĆ</span>} style={{ marginTop: '-20px' }}>
                      <InveeLogoFull style={{ height: '72px', width: 'auto', filter: shadowFilter1 }} />
                    </Badge.Ribbon>
                  </div>
                  <div className='text-2xl mt-3 font-extralight'>
                    <span className=' opacity-70'>cyfrowe</span> zaproszenia <span className=' opacity-70'>na smartfony</span>
                  </div>
                  <div className='mt-6'>
                    <Space className='flex-wrap'>
                      <StyledButton color={themeLight.light1} size='large' type='primary' onClick={handleWeStart} className='shadow-xl scailing-1'>
                        <Space className='btn'>
                          <LoginOutlined />
                          Stwórz zaproszenie
                        </Space>
                      </StyledButton>
                    </Space>
                    {/* </RegulationsAccpetance> */}
                  </div>
                </div>
              </WhenDesktop>
              <WhenMobile>
                <div className='flex-1 flex flex-col pl-6 justify-center overflow-hidden'>
                  <div className='font-roboto-slab flex flex-row items-center'>
                    <Badge.Ribbon size='large' color='red' text='NOWOŚĆ' style={{ marginRight: '-8px', marginTop: '-22px' }}>
                      <InveeLogoFull style={{ height: '36px', width: 'auto', filter: shadowFilter1 }} />
                    </Badge.Ribbon>
                  </div>
                  <div className='text-xl mt-1 font-extralight'>
                    <span className=' opacity-70'>cyfrowe</span> zaproszenia <span className=' opacity-70'>na smartfony</span>
                  </div>
                  <div className='mt-3'>
                    <Space className='flex-wrap'>
                      <StyledButton color={themeLight.light1} size='large' type='primary' onClick={handleWeStart} className='shadow-xl scailing-1'>
                        <Space className='btn'>
                          <LoginOutlined />
                          Stwórz zaproszenie
                        </Space>
                      </StyledButton>
                    </Space>
                    {/* </RegulationsAccpetance> */}
                  </div>
                </div>
              </WhenMobile>
            </div>
          </WhenLandscape>
          <WhenPortrait>
            <div className={'flex flex-col'} style={{ minHeight: 'calc(100vh)', opacity: getOpacityInv(globalContext.screenSize.height * 0.2, globalContext.screenSize.height * 0.6), filter: `blur(${getBlur(globalContext.screenSize.height * 0.3, globalContext.screenSize.height * 0.7)}px)` }}>
              <div className='h-[50vh] flex flex-row justify-center items-end'>
                <div className='w-full h-[35vh] flex flex-row py-6 justify-center items-center gap-2'>{landingPagePictures}</div>
              </div>
              <div className='h-[50vh] py-4 flex flex-col items-center  overflow-hidden'>
                <Badge.Ribbon size='large' color='red' text='NOWOŚĆ' style={{ marginRight: '-8px', marginTop: '-24px' }}>
                  <InveeLogoFull style={{ height: '52px', width: 'auto', filter: shadowFilter1 }} />
                </Badge.Ribbon>
                <div className='text-xl mt-1 font-extralight'>
                  <span className=' opacity-70'>cyfrowe</span> zaproszenia <span className=' opacity-70'>na smartfony</span>
                </div>
                <div className='mt-3 flex flex-col gap-4 justify-center items-center'>
                  <StyledButton color={themeLight.light1} size='large' type='link' onClick={handleWeStart} style={{ width: '300px' }} className='shadow-xl scailing-1'>
                    <Space className='btn'>
                      <LoginOutlined />
                      Stwórz zaproszenie
                    </Space>
                  </StyledButton>
                </div>
              </div>
            </div>
          </WhenPortrait>
        </div>
      </div>

      <div className='w-full flex flex-row justify-center' style={{ backgroundColor: theme.light1, color: theme.dark3 }}>
        <div className='w-full grid px-4' style={{ width: isDesktop ? theme.centredTo : null }}>
          <div>
            <div className='text-center text-2xl m-4 md:text-5xl md:mx-6 md:my-24 font-roboto-slab'>
              <Zoom>
                <span>
                  <span className=' opacity-60'>Jak działają</span> inwitki?
                </span>
              </Zoom>
            </div>
            <div className='flex flex-row justify-center'>
              <div class='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 gap-8 mb-16'>
                <Fade className='h-full' cascade triggerOnce damping={0.2}>
                  <div className='flex flex-col justify-center items-center text-xl gap-4 w-full'>
                    <iframe src='https://www.youtube.com/embed/i9j0Lr0-HsQ' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowFullScreen title='Zobacz jak Paweł tworzy inwitki ślubne!.' style={ytStyle} />
                    <div>Zobacz jak Paweł tworzy inwitki ślubne!</div>
                  </div>

                  <div className='flex flex-col justify-center items-center text-xl gap-4 w-full'>
                    <iframe src='https://www.youtube.com/embed/09BRgH4xHCs' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowFullScreen title='Zobacz co Marek zrobi z kodem QR na zaproszeniu!' style={ytStyle} />
                    <div>Zobacz co Marek zrobi z kodem QR na zaproszeniu!</div>
                  </div>

                  {/* <SlideItem theme={theme} inverted title={<>Wybór szablonu</>} picture={<IconTemplate className='max-h-full w-auto lg:h-auto lg:max-w-full' />}>
                    Wybierz jeden z naszych eleganckich i nowoczesnych szablonów zaproszeń. Mamy szeroki wybór wzorów dostosowanych do różnych okazji – od urodzin, przez wesela, aż po spotkania z przyjaciółmi.
                  </SlideItem> */}
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='flex flex-row justify-center ' style={{ backgroundColor: theme.light2, color: theme.dark3 }}>
        <div className='w-full grid px-4' style={{ width: isDesktop ? theme.centredTo : null }}>
          <div className='flex flex-col justify-center'>
            <div>
              <div className='text-center text-2xl m-4 md:text-5xl md:mx-6 md:my-24 font-roboto-slab'>
                <Zoom>
                  <span>
                    Przebieraj <span className='opacity-60'>w szablonach</span>
                  </span>
                </Zoom>
              </div>
            </div>
            <div className='flex flex-row justify-center mb-16'>
              <div className='lg:w-4/6' style={{ filter: shadowFilter }}>
                <Fade>
                  <ImageGallery items={images} showNav={false} showThumbnails={false} showFullscreenButton={false} showPlayButton={false} autoPlay={true} />;
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='w-full flex flex-row justify-center' style={{ backgroundColor: theme.light1, color: theme.dark3 }}>
        <div className='w-full grid px-4' style={{ width: isDesktop ? theme.centredTo : null }}>
          <div>
            <div className='text-center text-2xl m-4 md:text-5xl md:mx-6 md:my-24 font-roboto-slab'>
              <Zoom>
                <span>
                  <span className=' opacity-60'>Niech każdy</span> dotrze na czas
                </span>
              </Zoom>
            </div>
            <div className='w-full grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-16 mb-16'>
              <div className='flex flex-col justify-center'>
                <Fade>
                  <img src={isDarkMode ? '/images/invee/cyfrowe-zaproszenie-edycja-dark.png' : '/images/invee/cyfrowe-zaproszenie-edycja-light.png'} alt='cyfrowe zaproszenie edycja' className='w-full object-cover rounded-xl' style={{ filter: shadowFilter }} />
                </Fade>
              </div>
              <div className='flex flex-col justify-center items-center'>
                <div className='w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8 mb-9 m-4 ' style={{ maxWidth: isDesktop ? theme.centredTo : null }}>
                  <Fade cascade damping={0.2}>
                    <SlideItem2
                      theme={theme}
                      picture={
                        <div className='text-sky-900 dark:text-sky-200'>
                          <HiOutlineCursorClick className='text-6xl' />
                          <div className='text-2xl'>Link</div>
                        </div>
                      }
                    >
                      Wysyłaj bezpośrednio inwitki za pomocą komunikatora albo e-maila.
                    </SlideItem2>
                    <SlideItem2
                      theme={theme}
                      picture={
                        <div className='text-yellow-900 dark:text-yellow-200'>
                          <MdOutlineQrCode2 className='text-6xl' />
                          <div className='text-2xl'>Kod QR</div>
                        </div>
                      }
                    >
                      Nadrukuj kody QR na papierowe zaproszenia aby dodać do nich cyfrowe funkcje inwitek.
                    </SlideItem2>
                    <SlideItem2
                      theme={theme}
                      picture={
                        <div className='text-green-900 dark:text-green-200'>
                          <GiPositionMarker className='text-6xl' />
                          <div className='text-2xl'>Mapa</div>
                        </div>
                      }
                    >
                      Podaj lokalizację żeby Twoi Goście użyli nawigacji GPS do miejsca wydarzenia.
                    </SlideItem2>
                    <SlideItem2
                      theme={theme}
                      picture={
                        <div className='text-rose-900 dark:text-rose-200'>
                          <CiMobile2 className='text-6xl' />
                          <div className='text-2xl'>Aplikacja</div>
                        </div>
                      }
                    >
                      <div>Aplikacja mobilna pozawala jeszcze lepiej korzystać z cyfrowych inwitek.</div>
                      <div className='flex flex-row flex-wrap gap-2 justify-center items-center mt-4'>
                        <StyledButton color={theme.dark3} bgColor='transparent' onClick={handleDownloadAppStore}>
                          <AppStoreBadge />
                        </StyledButton>
                        <StyledButton color={theme.dark3} bgColor='transparent' onClick={handleDownloadPlayStore}>
                          <PlayStoreBadge />
                        </StyledButton>
                      </div>
                    </SlideItem2>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='flex flex-row justify-center ' style={{ backgroundColor: theme.light2, color: theme.dark3 }}>
        <div className='w-full grid px-4' style={{ width: isDesktop ? theme.centredTo : null }}>
          <div className='flex flex-col justify-center'>
            <div>
              <div className='text-center text-2xl m-4 md:text-5xl md:mx-6 md:my-24 font-roboto-slab'>
                <Zoom>
                  <span>
                    Zeskanuj smartfonem <span className='opacity-60'>cyfrowe zaproszenia</span>
                  </span>
                </Zoom>
              </div>
              <div className='flex flex-col flex-wrap md:flex-row justify-center items-center gap-24 lg:gap-40 mb-16 text-center'>
                <Fade cascade damping={0.4}>
                  <StyledQRCode style={{ filter: shadowFilter2 }}>
                    <div className='w-60 py-4 px-8 flex flex-col items-center overflow-hidden bg-blue-700 dark:bg-blue-100 rounded-3xl'>
                      <InveeLogo style={{ height: '32px' }} className='text-blue-100 dark:text-blue-900' />
                      <QRCode color={isDarkMode ? '#172554' : '#eff6ff'} style={{ zoom: '1.4' }} value={'https://inwitki.net/i/eyJpIjoidVpDakUydmRNMzdHMTNzQWlkUk45aSIsImciOiJlNDQyNTQ4YTAyMTA4ZmY3ZjU4NDFjYjBjOTRiNzFiMjpURUZUWTJkNnJVOWN3UVN0b0Fva1NPdjdSS2o5ZDI3L0p1OGVBY0Z6MzlvPSJ9'} />
                      <div className='font-roboto-slab font-bold text-xl text-blue-100 dark:text-blue-900'>
                        inwitki<span className='opacity-60'>.net</span>
                      </div>
                    </div>
                    <div className='mt-4 text-base'>
                      <a className='text-blue-900 dark:text-blue-100' href='https://inwitki.net/i/eyJpIjoidVpDakUydmRNMzdHMTNzQWlkUk45aSIsImciOiJlNDQyNTQ4YTAyMTA4ZmY3ZjU4NDFjYjBjOTRiNzFiMjpURUZUWTJkNnJVOWN3UVN0b0Fva1NPdjdSS2o5ZDI3L0p1OGVBY0Z6MzlvPSJ9' target='_blank' rel='noreferrer'>
                        lub kliknij link
                      </a>
                    </div>
                  </StyledQRCode>

                  <StyledQRCode style={{ filter: shadowFilter2 }}>
                    <div className='w-60 py-4 px-8 flex flex-col items-center overflow-hidden  bg-orange-700 dark:bg-orange-100 rounded-3xl'>
                      <InveeLogo style={{ height: '32px' }} className='text-orange-100 dark:text-orange-900' />
                      <QRCode color={isDarkMode ? '#421407' : '#fff7ed'} style={{ zoom: '1.4' }} value={'https://inwitki.net/i/eyJpIjoiOUZMS3lnVmNlYW5iQU1OVXRDWXlCeCIsImciOiIxYzU0Zjk5ZWZiZjEwMWU1OTQzYTc2NGQ1MjU4ZTE5ODoxSTI4bWNSMldRcDhERDNWWHlpMXpiejVkRzBjaEtQZjI0MzlTbFRnSmo0PSJ9'} />
                      <div className='font-roboto-slab font-bold text-xl text-orange-100 dark:text-orange-900'>
                        inwitki<span className='opacity-60'>.net</span>
                      </div>
                    </div>
                    <div className='mt-4 text-base'>
                      <a className='text-yellow-900 dark:text-orange-100' href='https://inwitki.net/i/eyJpIjoiOUZMS3lnVmNlYW5iQU1OVXRDWXlCeCIsImciOiIxYzU0Zjk5ZWZiZjEwMWU1OTQzYTc2NGQ1MjU4ZTE5ODoxSTI4bWNSMldRcDhERDNWWHlpMXpiejVkRzBjaEtQZjI0MzlTbFRnSmo0PSJ9' target='_blank' rel='noreferrer'>
                        lub kliknij link
                      </a>
                    </div>
                  </StyledQRCode>

                  <StyledQRCode style={{ filter: shadowFilter2 }}>
                    <div className='w-60 py-4 px-8 flex flex-col items-center overflow-hidden  bg-violet-700 dark:bg-violet-100 rounded-3xl'>
                      <InveeLogo style={{ height: '32px' }} className='text-violet-100 dark:text-violet-900' />
                      <QRCode color={isDarkMode ? '#2e1065' : '#f5f3ff'} style={{ zoom: '1.4' }} value={'https://inwitki.net/i/eyJpIjoiM0ZWNHQxelJvdDY3N2ZiM0trU1J6byIsImciOiJhYTM2MmMzNDU2ODE1MzU2MTFiOGVkZWJiMjgyNzYwODp1bHVDa0V6NytaT3ZXd0lHUVdORkN6ZGNUTFlWZnJPTEUyM0t3VEIxTnBBPSJ9'} />
                      <div className='font-roboto-slab font-bold text-xl text-violet-100 dark:text-violet-900'>
                        inwitki<span className='opacity-60'>.net</span>
                      </div>
                    </div>
                    <div className='mt-4 text-base'>
                      <a className='text-violet-900 dark:text-violet-200' href='https://inwitki.net/i/eyJpIjoiM0ZWNHQxelJvdDY3N2ZiM0trU1J6byIsImciOiJhYTM2MmMzNDU2ODE1MzU2MTFiOGVkZWJiMjgyNzYwODp1bHVDa0V6NytaT3ZXd0lHUVdORkN6ZGNUTFlWZnJPTEUyM0t3VEIxTnBBPSJ9' target='_blank' rel='noreferrer'>
                        lub kliknij link
                      </a>
                    </div>
                  </StyledQRCode>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='flex flex-row justify-center ' style={{ backgroundColor: theme.light1, color: theme.dark3 }}>
        <div className='w-full grid p-4' style={{ width: isDesktop ? theme.centredTo : null }}>
          <div className='text-center text-2xl m-4 md:text-5xl md:mx-6 md:my-24 font-roboto-slab'>
            <Zoom>
              <div className='flex flex-row justify-center'>
                <Badge.Ribbon color='red' text='CAŁKOWICIE ZA DARMO' style={{ marginRight: '-8px', marginTop: '-26px' }}>
                  <div className='text-2xl xl:text-5xl'>
                    <span className='opacity-60'>Chcesz mieć takie</span> w 2 minuty?
                  </div>
                </Badge.Ribbon>
              </div>
            </Zoom>
            <div className='text-center my-16 md:text-5xl md:mt-12 md:mb-16 font-roboto-slab'>
              <Space>
                <Fade>
                  <StyledButton color={theme.dark4} size='large' type='primary' onClick={handleWeStart} className='shadow-xl scailing-1-5'>
                    <Space className='btn'>
                      <LoginOutlined />
                      Stwórz zaproszenie
                    </Space>
                  </StyledButton>
                </Fade>
              </Space>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

Index.propTypes = {};

export default Index;
