import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import useTheme from 'src/hooks/useTheme';
import { useScreen } from 'src/hooks/useScreen';
// import useTheme from 'src/hooks/useTheme';

const MobileMockup = ({ children, scale }) => {
  const topDiv = useRef();
  const { isDarkMode } = useTheme();
  const { isDesktop } = useScreen();

  const [forceRerender, setForceRerender] = useState(true);

  useEffect(() => {
    setForceRerender(!forceRerender);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const color = theme.dark4;
  const color = isDarkMode ? 'white' : 'black';

  return (
    <div
      style={{
        transform: `scale(${scale})`,
        transformOrigin: 'left top',
        filter: isDesktop && `drop-shadow(0 0 10px ${isDarkMode ? '#777777' : '#666666'})`,
      }}
    >
      <div ref={topDiv} style={{ position: 'relative', width: '300px', height: '600px', maxWidth: '400px', overflow: 'hidden', border: `2px solid ${color}`, borderRadius: '30px' }}>
        {/* <div style={{ position: 'absolute', left: '100px', right: '100px', top: '0', zIndex: '1', height: '20px', backgroundColor: color, borderRadius: '0 0 8px 8px' }} /> */}
        <div style={{ position: 'absolute', left: '0', right: '0', top: '0', bottom: '0', zIndex: '0', overflowY: 'auto', overflowX: 'hidden' }}>{children}</div>
      </div>
    </div>
  );
};

MobileMockup.propTypes = {
  children: PropTypes.any,
  scale: PropTypes.number,
};

MobileMockup.defaultProps = {
  scale: 1.0,
};

export default MobileMockup;
