import React from 'react';
import PropTypes from 'prop-types';
import useTheme from 'src/hooks/useTheme';
import { PlusOutlined } from '@ant-design/icons';
import { HiCursorClick } from 'react-icons/hi';
import { Space } from 'antd';

const NewInvee = ({ onCreate, showAdditionalInfo }) => {
  const { theme } = useTheme();

  const handleCreate = () => {
    onCreate();
  };

  return (
    <>
      <div
        className={'relative h-48 flex flex-col items-center self-center justify-center cursor-pointer bg-sky-300 dark:bg-sky-800 rounded-[25px] lg:hover:scale-105 transition-all'}
        onClick={() => {
          handleCreate();
        }}
        style={{ color: theme.dark3 }}
      >
        <div>
          <PlusOutlined style={{ fontSize: '28px' }} />
        </div>
        <div className='text-md font-bold mt-2'>Stwórz nowy projekt</div>
        {showAdditionalInfo && (
          <div className='text-md font-light text-sm mt-4 animate-pulse'>
            <Space className='btn'>
              <HiCursorClick className='text-lg' />
              kliknij tutaj aby rozpocząć
            </Space>
          </div>
        )}
      </div>
    </>
  );
};

NewInvee.propTypes = {
  onCreate: PropTypes.func.isRequired,
  showAdditionalInfo: PropTypes.bool,
};

export default NewInvee;
