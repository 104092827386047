import { ENV } from '../misc/Config';
import { handleResponse, handleError, getAccessToken } from './helpers';

export const getStaticFile = async (path) => {
  try {
    return (await fetch(path)).text();
  } catch (error) {
    handleError(error);
  }
};

export const getAuthUserInfo = async () => {
  const access_token = await getAccessToken();
  if (!access_token) throw new Error('No access token');

  try {
    return handleResponse(
      await fetch(`${ENV.inveeApi}/auth/user-info`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          token: access_token,
        },
      })
    );
  } catch (error) {
    handleError(error);
  }
};

export const getInvees = async () => {
  const access_token = await getAccessToken();
  if (!access_token) throw new Error('No access token');

  try {
    return handleResponse(
      await fetch(`${ENV.inveeApi}/invees`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          token: access_token,
        },
      })
    );
  } catch (error) {
    handleError(error);
  }
};

export const getInvee = async (inveeId) => {
  const access_token = await getAccessToken();
  if (!access_token) throw new Error('No access token');

  try {
    const resp = await fetch(`${ENV.inveeApi}/invees/${inveeId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        token: access_token,
      },
    });
    return handleResponse(resp, true, resp?.status !== 404);
  } catch (error) {
    handleError(error);
  }
};

export const postInvee = async (invee) => {
  const access_token = await getAccessToken();
  if (!access_token) throw new Error('No access token');

  try {
    const resp = await fetch(`${ENV.inveeApi}/invees`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        token: access_token,
      },
      body: JSON.stringify(invee),
    });
    return handleResponse(resp, true, resp?.status !== 507);
  } catch (error) {
    handleError(error);
  }
};

export const patchInvee = async (inveeId, changes) => {
  const access_token = await getAccessToken();
  if (!access_token) throw new Error('No access token');

  try {
    const resp = await fetch(`${ENV.inveeApi}/invees/${inveeId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        token: access_token,
      },
      body: JSON.stringify(changes),
    });
    return handleResponse(resp, true);
  } catch (error) {
    handleError(error);
  }
};

export const deleteInvee = async (inveeId) => {
  const access_token = await getAccessToken();
  if (!access_token) throw new Error('No access token');

  try {
    return handleResponse(
      await fetch(`${ENV.inveeApi}/invees/${inveeId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          token: access_token,
        },
      })
    );
  } catch (error) {
    handleError(error);
  }
};

export const postEncodeInveeString = async (inveeId, guest) => {
  const access_token = await getAccessToken();
  if (!access_token) throw new Error('No access token');

  try {
    return handleResponse(
      await fetch(`${ENV.inveeApi}/invees/${inveeId}/strings`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          token: access_token,
        },
        body: JSON.stringify({ guest }),
      })
    );
  } catch (error) {
    handleError(error);
  }
};

export const getDecodeInveeString = async (inveeString) => {
  try {
    const resp = await fetch(`${ENV.inveeApi}/invee/${inveeString}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return handleResponse(resp, false, false);
  } catch (error) {
    handleError(error);
    throw error;
  }
};

export const postTokensGetForFree = async () => {
  const access_token = await getAccessToken();
  if (!access_token) throw new Error('No access token');

  try {
    return handleResponse(
      await fetch(`${ENV.inveeApi}/tokens/get-for-free`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          token: access_token,
        },
      })
    );
  } catch (error) {
    handleError(error);
  }
};

export const getTokens = async (details = false) => {
  const access_token = await getAccessToken();
  if (!access_token) throw new Error('No access token');

  try {
    return handleResponse(
      await fetch(`${ENV.inveeApi}/tokens${details ? '?details=true' : ''}`, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          token: access_token,
        },
      })
    );
  } catch (error) {
    handleError(error);
  }
};

export const postCouponsActivate = async (couponId) => {
  const access_token = await getAccessToken();
  if (!access_token) throw new Error('No access token');

  try {
    return handleResponse(
      await fetch(`${ENV.inveeApi}/coupons/activate`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          token: access_token,
        },
        body: JSON.stringify({ couponId }),
      }),
      true,
      false
    );
  } catch (error) {
    handleError(error);
  }
};

export const deleteAccount = async () => {
  const access_token = await getAccessToken();
  if (!access_token) throw new Error('No access token');

  try {
    return handleResponse(
      await fetch(`${ENV.inveeApi}/account`, {
        method: 'delete',
        headers: {
          'Content-Type': 'application/json',
          token: access_token,
        },
      })
    );
  } catch (error) {
    handleError(error);
  }
};

export const getPayments = async () => {
  const access_token = await getAccessToken();
  if (!access_token) throw new Error('No access token');

  try {
    return handleResponse(
      await fetch(`${ENV.inveeApi}/payments`, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          token: access_token,
        },
      })
    );
  } catch (error) {
    handleError(error);
  }
};

export const getPayment = async (paymentId) => {
  const access_token = await getAccessToken();
  if (!access_token) throw new Error('No access token');

  try {
    return handleResponse(
      await fetch(`${ENV.inveeApi}/payments?paymentId=${paymentId}`, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          token: access_token,
        },
      })
    );
  } catch (error) {
    handleError(error);
  }
};

export const postPaymentTransactionRegister = async (tokenCount, amount) => {
  const access_token = await getAccessToken();
  if (!access_token) throw new Error('No access token');

  try {
    return handleResponse(
      await fetch(`${ENV.inveeApi}/payments/transaction/register`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          token: access_token,
        },
        body: JSON.stringify({ tokenCount, amount }),
      })
    );
  } catch (error) {
    handleError(error);
  }
};

export const postContact = async (name, email, subject, message) => {
  try {
    return handleResponse(
      await fetch(`${ENV.inveeApi}/contact`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, email, subject, message }),
      })
    );
  } catch (error) {
    handleError(error);
  }
};
