import { useContext } from 'react';
import GlobalContext from 'src/context/GlobalContext';
// import { LOCAL_STORAGE_KEYS } from 'src/misc/Config';

const Consents = () => {
  const { cookieConsentsOpen, setCookieConsentsOpen, cookieConsents, setCookieConsents } = useContext(GlobalContext);
  return { cookieConsentsOpen, setCookieConsentsOpen, cookieConsents, setCookieConsents };
};

export default Consents;
