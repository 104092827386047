import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useTheme from 'src/hooks/useTheme';
import GlobalContext from 'src/context/GlobalContext';
import InveesContext from 'src/context/InveesContext';
import UserControl from 'src/components/controls/userControl/UserControl';
import Layout from 'src/components/layout/Layout';
import TitleBar from 'src/components/layout/TitleBar';
import Content from 'src/components/layout/Content';
import Footer from 'src/components/layout/Footer';
import { Statistic, Card, Table, Space, Tabs } from 'antd';
import { GiTwoCoins } from 'react-icons/gi';
import { getTokens, getPayments } from 'src/api/invee';
import { useScreen } from 'src/hooks/useScreen';
import { SCREEN_MODES } from 'src/misc/Config';
import { dateTimeSorter } from 'src/misc/Misc';
import DarkModeControl from 'src/components/controls/darkModeControl/DarkModeControl';
import { MdPayment } from 'react-icons/md';
import { RiCoupon3Line } from 'react-icons/ri';
import { FaGift } from 'react-icons/fa6';
import OptionButton from 'src/components/controls/misc/OptionButton';
import { PAYMENT_STATUSES } from 'src/misc/Config';
import * as Ga4 from 'src/misc/Ga4';

import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import 'dayjs/locale/pl';

dayjs.extend(isoWeek);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
dayjs.locale('pl');

const Tokens = () => {
  const { screenMode } = useScreen();
  const { theme } = useTheme();

  const tokensDetailsColumnsDesktop = [
    {
      title: 'Identyfikator',
      key: 'paymentId',
      align: 'center',
      render: (record) => (
        <>
          <div>{record.tokensId}</div>
          {record.paymentId && (
            <div className='text-xs font-light'>
              <Space className='btn'>
                <MdPayment style={{ color: theme.primary }} />
                {record.paymentId}
              </Space>
            </div>
          )}
          {record.couponId && (
            <div className='text-xs font-light'>
              <Space className='btn'>
                <RiCoupon3Line style={{ color: theme.primary }} />
                {record.couponId}
              </Space>
            </div>
          )}
          {record.free && (
            <div className='text-xs font-light'>
              <Space className='btn'>
                <FaGift style={{ color: theme.primary }} />
                za darmo
              </Space>
            </div>
          )}
        </>
      ),
    },
    {
      title: 'Dodanie',
      key: 'createdAt',
      align: 'center',
      width: '20%',
      render: (record) => (
        <div>
          <div>{dayjs().to(dayjs(record.createdAt))}</div>
          <div className='text-xs font-light'>{dayjs(record.createdAt).format('lll')}</div>
        </div>
      ),
    },
    {
      title: 'Wygaśnięcie',
      key: 'expryDate',
      align: 'center',
      width: '20%',
      render: (record) => (
        <div>
          <div>{dayjs().to(dayjs(record.expiryDate))}</div>
          <div className='text-xs font-light'>{dayjs(record.expiryDate).format('lll')}</div>
        </div>
      ),
    },
    {
      title: 'Pula',
      key: 'Total',
      align: 'center',
      width: '10%',
      render: (record) => <>{record.total}</>,
    },
    {
      title: 'Zużyto',
      key: 'Used',
      align: 'center',
      width: '10%',
      render: (record) => <>{record.used}</>,
    },
    {
      title: 'Dostępne',
      key: 'Outsanding',
      align: 'center',
      width: '10%',
      render: (record) => <>{record.total - record.used}</>,
    },
  ];

  const tokensDetailsColumnsMobile = [
    {
      title: 'Kredyty',
      key: 'rating',
      render: (record) => (
        <div>
          <div>Pozostało: {record.total - record.used}</div>
          <div className='text-xs font-light'>Dodane {dayjs().to(dayjs(record.createdAt))}</div>
          <div className='text-xs font-light'>Wygasną {dayjs().to(dayjs(record.expiryDate))}</div>
        </div>
      ),
    },
  ];

  const paymentsDetailsColumnsDesktop = [
    {
      title: 'Identyfikator',
      key: 'paymentId',
      align: 'center',
      render: (record) => <>{record.paymentId}</>,
    },
    {
      title: 'Zarejestrowano',
      key: 'createdAt',
      align: 'center',
      render: (record) => (
        <div>
          {record.createdAt ? (
            <>
              <div>{dayjs().to(dayjs(record.createdAt))}</div>
              <div className='text-xs font-light'>{dayjs(record.createdAt).format('lll')}</div>
            </>
          ) : (
            <>-</>
          )}
        </div>
      ),
    },
    {
      title: 'Zaktualizowano',
      key: 'updatedAt',
      align: 'center',
      render: (record) => (
        <div>
          {record.updatedAt ? (
            <>
              <div>{dayjs().to(dayjs(record.updatedAt))}</div>
              <div className='text-xs font-light'>{dayjs(record.updatedAt).format('lll')}</div>
            </>
          ) : (
            <>-</>
          )}
        </div>
      ),
    },
    {
      title: 'Status',
      key: 'status',
      align: 'center',
      render: (record) => <>{record.status ? PAYMENT_STATUSES[record.status] ?? record.status : '-'}</>,
    },
    {
      title: 'Kredyty',
      key: 'tokenCount',
      align: 'center',
      render: (record) => <>{record.tokenCount ?? '-'}</>,
    },
    {
      title: 'Kwota',
      key: 'amount',
      align: 'center',
      render: (record) => <>{new Intl.NumberFormat('dpl-PL', { style: 'currency', currency: 'PLN' }).format(record.amount ? record.amount / 100 : 0)}</>,
    },
  ];

  const paymentsDetailsColumnsMobile = [
    {
      title: 'Kredyty',
      key: 'rating',
      render: (record) => (
        <div>
          <div>
            Kredyty: {record.tokenCount ?? '-'} | Kwota: {record.amount ?? '-'}
          </div>
          <div className='text-xs font-light'>Zarejestrowano {record.createdAt ? dayjs().to(dayjs(record.createdAt)) : '-'}</div>
          <div className='text-xs font-light'>Status {record.status ? PAYMENT_STATUSES[record.status] ?? record.status : '-'}</div>
        </div>
      ),
    },
  ];

  const globalContext = useContext(GlobalContext);
  const inveesContext = useContext(InveesContext);

  const navigate = useNavigate();

  const breadcrumbRoutes = [
    {
      title: 'Moje projekty',
      path: '/projects',
    },
    {
      title: 'Kredyty',
    },
  ];

  useEffect(() => {
    Ga4.pageView('Tokens');
    if (inveesContext.tokens === null || inveesContext.tokensDetails === null) {
      loadTokensDetails();
    }
    if (inveesContext.paymentsDetails === null) {
      loadPaymentsDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadTokensDetails = async () => {
    await globalContext.executeBlockingProcess(async () => {
      try {
        const response = await getTokens(true);
        inveesContext.setTokens(response?.tokens ?? 0);
        inveesContext.setTokensDetails(response?.results?.sort((a, b) => dateTimeSorter(b, a, 'createdAt')) ?? []);
      } catch (error) {
        console.error(error);
      }
    });
  };

  const loadPaymentsDetails = async () => {
    await globalContext.executeBlockingProcess(async () => {
      try {
        const response = await getPayments();
        inveesContext.setPaymentsDetails(response?.sort((a, b) => dateTimeSorter(b, a, 'createdAt')) ?? []);
      } catch (error) {
        console.error(error);
      }
    });
  };

  const historyTabItems = [
    {
      key: '1',
      label: (
        <Space>
          <GiTwoCoins />
          Kredyty
        </Space>
      ),
      children: (
        <>
          <Table rowKey={(row) => row.tokensId} size='small' columns={SCREEN_MODES.desktop === screenMode ? tokensDetailsColumnsDesktop : tokensDetailsColumnsMobile} dataSource={inveesContext.tokensDetails} />
        </>
      ),
    },
    {
      key: '2',
      label: (
        <Space>
          <MdPayment />
          Płatności
        </Space>
      ),
      children: (
        <>
          <Table rowKey={(row) => row.paymentId} size='small' columns={SCREEN_MODES.desktop === screenMode ? paymentsDetailsColumnsDesktop : paymentsDetailsColumnsMobile} dataSource={inveesContext.paymentsDetails} />
        </>
      ),
    },
  ];

  return (
    <>
      <Layout
        header={
          <TitleBar
            path='/projects'
            isLoading={globalContext.isBusy}
            rightExtras={
              <Space>
                <DarkModeControl />
                <UserControl />
              </Space>
            }
          ></TitleBar>
        }
        footer={<Footer />}
      >
        <Content breadcrumbRoutes={breadcrumbRoutes} title={inveesContext.tokens !== null ? 'Kredyty' : null}>
          {inveesContext.tokens !== null && (
            <>
              <Card>
                <div className='mt-4 flex flex-row justify-center'>
                  <div className='w-64 text-center'>
                    <Statistic title='Suma dostępnych kredytów' value={inveesContext.tokens} prefix={<GiTwoCoins style={{ color: '#ff8d23' }} />} />
                  </div>
                </div>
                <div className='mt-8 flex flex-col lg:flex-row gap-6 items-center justify-center'>
                  <OptionButton
                    title={
                      <>
                        Chcę
                        <br />
                        <strong>kupić</strong>
                        <br />
                        kredyty
                      </>
                    }
                    icon={<MdPayment style={{ filter: 'drop-shadow(rgba(0, 185, 255, 0.267) 0px 0px 8px)' }} />}
                    disabled
                    onClick={() => {
                      navigate('/account/tokens/buy');
                    }}
                  />

                  <OptionButton
                    title={
                      <>
                        Chcę
                        <br />
                        zrealizować
                        <br />
                        <strong>kupon</strong>
                      </>
                    }
                    icon={<RiCoupon3Line style={{ filter: 'drop-shadow(rgba(0, 185, 255, 0.267) 0px 0px 8px)' }} />}
                    disabled
                    onClick={() => {
                      navigate('/account/tokens/code');
                    }}
                  />

                  <OptionButton
                    title={
                      <>
                        Chcę otrzymać
                        <br />
                        kredyty
                        <br />
                        <strong>ZA DARMO</strong>
                      </>
                    }
                    icon={<FaGift style={{ filter: 'drop-shadow(rgba(0, 185, 255, 0.267) 0px 0px 8px)' }} />}
                    onClick={() => {
                      navigate('/account/tokens/get-for-free');
                    }}
                  />
                </div>
              </Card>

              <Card className='mt-4'>
                <Tabs centered defaultActiveKey='1' type='card' items={historyTabItems} />
              </Card>
            </>
          )}
        </Content>
      </Layout>
    </>
  );
};

Tokens.propTypes = {};

export default Tokens;
