import React, { useContext, useEffect } from 'react';
import GlobalContext from 'src/context/GlobalContext';
import { useLocation, useNavigate } from 'react-router-dom';
//import styled from 'styled-components';
import * as Ga4 from 'src/misc/Ga4';
import Layout from 'src/components/layout/Layout';
import TitleBar from 'src/components/layout/TitleBar';
import Footer from 'src/components/layout/Footer';
import Content from 'src/components/layout/Content';
import UserControl from 'src/components/controls/userControl/UserControl';
import DarkModeControl from 'src/components/controls/darkModeControl/DarkModeControl';
import { Button, Space } from 'antd';
import StyledButton from 'src/components/styled/StyledButton';
import { LeftOutlined } from '@ant-design/icons';
import WhenDesktop from 'src/components/layout/WhenDesktop';
import useTheme from 'src/hooks/useTheme';
import { ReactComponent as Blog } from 'src/components/svg/blog.svg';
import Hr from 'src/components/layout/Hr';
import { blogList } from './misc/config';
import { Helmet } from 'react-helmet';

//import PropTypes from 'prop-types';

// const StylesWrapper = styled.div``;

const BlogHome = () => {
  const globalContext = useContext(GlobalContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { theme } = useTheme();

  const breadcrumbRoutes = globalContext.userInfo
    ? [
        {
          title: 'Moje projekty',
          path: '/projects',
        },
        {
          title: 'Blog',
        },
      ]
    : [
        {
          title: 'Blog',
        },
      ];

  useEffect(() => {
    Ga4.pageView('Blog - index');
  }, []);

  return (
    <Layout
      header={
        <TitleBar
          path='/'
          isLoading={globalContext.isBusy}
          leftExtras={
            <>
              {location.key !== 'default' && (
                <StyledButton color={theme.whiteButton} bgColor='transparent' onClick={() => navigate(-1)}>
                  <Space>
                    <LeftOutlined />
                    <WhenDesktop>Cofnij</WhenDesktop>
                  </Space>
                </StyledButton>
              )}
            </>
          }
          rightExtras={
            <Space>
              <DarkModeControl />
              <UserControl />
            </Space>
          }
        />
      }
      footer={<Footer />}
    >
      <Helmet>
        <title>Blog. Odkrywaj Świat Cyfrowych Zaproszeń.</title>
        <meta name='keywords' content='e-zaproszenia, cyfrowe, zaproszenia, inwitki, blog' />
        <meta name='description' content='Niezwykłe artykuły o cyfrowych inwitkach, ekologii i nowoczesnych sposobach zapraszania na różne okazje' />
      </Helmet>
      <Content breadcrumbRoutes={breadcrumbRoutes}>
        <div className='my-4 lg:my-4 flex flex-col lg:flex-row gap-8 justify-center items-center' style={{ color: theme.dark3 }}>
          <div className='flex flex-row justify-center'>
            <Blog className='h-48 w-64' />
          </div>
          <div className='flex flex-col gap-2 justify-center w-full lg:w-1/3'>
            <div className='text-xl lg:text-3xl text-center lg:text-left'>
              <strong>
                Blog Inwitki<span className='opacity-60'>.net</span>
              </strong>
              <br />
              Odkrywaj Świat Cyfrowych Zaproszeń
            </div>
            <div className='text-sm opacity-60 text-center lg:text-left'>Niezwykłe artykuły o cyfrowych inwitkach, ekologii i nowoczesnych sposobach zapraszania na różne okazje</div>
          </div>
        </div>

        <Hr />
        <div className='flex flex-col items-center my-12 gap-8'>
          {blogList.map((blog) => (
            <>
              <div className='flex flex-col gap-2 justify-center w-full lg:w-[700px]'>
                <div className='text-xl lg:text-xl font-bold text-center lg:text-left'>{blog.title}</div>
                <div className='text-sm opacity-60 text-center lg:text-left'>{blog.date}</div>
                <div className='text-sm text-center lg:text-left'>{blog.description}</div>
                <div className='flex flex-row justify-center lg:justify-start'>
                  <Button
                    color={theme.whiteButton}
                    bgColor={theme.primaryButton}
                    onClick={() => {
                      navigate(`/blog/${blog.id}`);
                    }}
                  >
                    Czytaj więcej
                  </Button>
                </div>
              </div>
              <Hr />
            </>
          ))}
        </div>
      </Content>
    </Layout>
  );
};

//BlogHome.propTypes = {};

export default BlogHome;
