import React from 'react';
import PropTypes from 'prop-types';
import MobileMockup from '../misc/MobileMockup';
import { useScreen } from 'src/hooks/useScreen';
import { SCREEN_MODES } from 'src/misc/Config';
import InveeView from 'src/components/controls/invee/InveeView';
import StyledScrollbars from 'src/components/styled/StyledScrollbars';

const InveeMobileView = ({ invee, guest, scrollToName }) => {
  const { screenMode } = useScreen();

  const mockupWrapperStyle = {
    desktop: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '360px',
      height: '640px',
    },
    mobile: {
      width: '164px',
      height: '308px',
    },
  };

  if (!invee) return null;

  return (
    <StyledScrollbars color={invee?.style?.colorScrollBar ?? invee?.style?.color} bgColor={invee?.style?.bgColorScrollBar ?? invee?.style?.bgColor}>
      <div style={mockupWrapperStyle[screenMode]}>
        <MobileMockup scale={SCREEN_MODES.mobile === screenMode ? 0.5 : 1.0}>
          <InveeView invee={invee} guest={guest} scrollToName={scrollToName} />
        </MobileMockup>
      </div>
    </StyledScrollbars>
  );
};

InveeMobileView.propTypes = {
  invee: PropTypes.object,
  quest: PropTypes.string,
  scrollToName: PropTypes.bool,
};

export default InveeMobileView;
