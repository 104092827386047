import styled from 'styled-components';

const StyledLink = styled.a`
  // color: red !important;
  font-weight: 700;

  &:link {
    color: ${(props) => props.theme.dark3} !important;
  }

  /* visited link */
  &:visited {
    color: ${(props) => props.theme.dark3} !important;
  }

  /* mouse over link */
  &:hover {
    color: ${(props) => props.theme.dark3} !important;
    text-decoration: underline;
  }

  /* selected link */
  &:active {
    color: ${(props) => props.theme.dark3} !important;
  }
`;

export default StyledLink;
