import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ReactComponent as InveeLogo } from 'src/components/svg/invee-logo.svg';
import { ReactComponent as InveeLogoFull } from 'src/components/svg/invee-logo-full-blue.svg';
import WhenDesktop from './WhenDesktop';
import { useTheme } from 'styled-components';
import WhenMobile from './WhenMobile';

const Title = ({ path }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <div
      className={`flex flex-row items-center ${path ? 'cursor-pointer' : null} font-weight-logo select-none`}
      style={{ fontSize: '24px', whiteSpace: 'nowrap' }}
      onClick={() => {
        if (!path) return;
        navigate(path);
      }}
    >
      <WhenMobile>
        <InveeLogo style={{ height: '32px', width: '22px', color: theme.light3 }} />
      </WhenMobile>
      <WhenDesktop>
        <InveeLogoFull style={{ height: '32px', width: 'auto' }} />
      </WhenDesktop>
    </div>
  );
};

Title.propTypes = {
  path: PropTypes.string,
};

export default Title;
